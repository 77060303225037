.gems-community {
  padding-top: 130px;
  padding-bottom: 51px;
  position: relative;
  z-index: 99;
  min-height: calc(100vh - 300px);

  .main-banner-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
    pointer-events: none;
    mix-blend-mode: plus-lighter;
  }

  .bg-shadow {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 0;
    pointer-events: none;
    mix-blend-mode: plus-lighter;
  }

  .parent {
    max-width: 632px;
    width: 100%;
    margin: 0 auto;
    position: relative;

    .main-heading {
      margin-bottom: 30px;

      h5 {
        color: var(--btn-green-bg);
        font-size: 32px;
        font-style: normal;
        line-height: 140%;
        text-align: center;
        margin-bottom: 15px;
      }

      h4 {
        color: var(--V6-Dark-Theme-Primary-Text-Color);
        text-align: center;
        font-size: 50px;
        font-style: normal;
        line-height: 100%;
        text-transform: uppercase;
      }
    }

    .inline-elem {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 30px;
      margin-bottom: 30px;

      .single-elem {
        padding: 10px 0px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        min-width: 191px;

        p {
          color: var(--V6-Dark-Theme-Secondary-Text-Color);
          font-size: 12px;
          font-style: normal;
          font-family: "hregular";
          line-height: normal;
          margin-bottom: 4px;
        }

        h6 {
          color: var(--V6-Dark-Theme-Primary-Text-Color);
          font-family: "f37";
          font-size: 16px;
          font-style: normal;
          line-height: 130%;
        }
      }
    }

    .mid-para {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      max-width: 400px;
      width: 100%;
      margin: 0 auto;
      margin-bottom: 30px;

      h6 {
        color: var(--V6-Dark-Theme-Primary-Text-Color);
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-family: "hbold";
        line-height: 140%;
      }

      p {
        color: var(--V6-Dark-Theme-Primary-Text-Color);
        font-size: 16px;
        text-align: center;
        font-style: normal;
        font-family: "hregular";
        line-height: 140%;
        margin-top: 20px;
      }
    }

    .bottom-agreement {
      margin-bottom: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      label {
        margin-bottom: 16px;
      }
    }

    .btn-green {
      padding: 15px 22px;
      display: flex;
      justify-content: center;
      align-items: center;
      max-width: 250px;
      width: 100%;
      margin: 0 auto;

      &.disabled {
        opacity: 0.5;
        pointer-events: none;
        // cursor: not-allowed;
      }
    }

    .bottom-timer {
      margin-top: 30px;

      .endsin-text {
        color: var(--heading-text);
        font-size: 14px;
        font-style: normal;
        font-family: "hmedium";
        line-height: 100%;
        text-transform: uppercase;
        margin-bottom: 12px;
        text-align: center;
      }

      .timer {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 5px;

        .single {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 5px;
          min-width: 61px;

          h6 {
            color: var(--btn-green-bg);
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: 100%;
            text-transform: uppercase;
          }

          p {
            color: var(--text-color1);
            font-size: 12px;
            font-style: normal;
            font-family: "hregular";
            line-height: 100%;
            text-transform: uppercase;
          }
        }

        span {
          color: var(--text-color1);
          font-size: 22px;
          font-style: normal;
          font-family: "hregular";
          line-height: 100%;
          text-transform: uppercase;
        }
      }
    }
  }

  .btn-green {
    &.disabled {
      opacity: 0.5;
      pointer-events: none;
    }
  }
}

.survey-section {
  padding-top: 121px;
  padding-bottom: 51px;
  min-height: calc(100vh - 300px);

  .steps-survey {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;

    .single-step {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 8px;

      span {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        color: var(--V6-Dark-Theme-Primary-Text-Color);
        text-align: center;
        font-size: 12px;
        font-style: normal;
        font-family: "hmedium";
        line-height: 100%;
      }

      p {
        color: var(--V6-Dark-Theme-Primary-Text-Color);
        font-size: 14px;
        font-style: normal;
        font-family: "hregular";
        line-height: 100%;
      }

      &.active {
        span {
          background: var(--btn-green-bg);
          color: var(--btn-green-text);
        }

        p {
          color: var(--btn-green-bg);
        }
      }
    }

    .line {
      background: var(--Stroke, #2b3e43);
      width: 18px;
      height: 1px;
      display: flex;

      &.active {
        background: var(--btn-green-bg);
      }
    }
  }

  .bottom-content {
    padding: 63px 0;

    .btn-green {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
      width: 100%;
      padding: 18px 22px;
      border: none;

      svg {
        flex-shrink: 0;

        path {
          stroke: var(--btn-green-text);
        }
      }

      &.disabled {
        opacity: 0.3;
        pointer-events: none;
      }
    }

    .common-box {
      border-radius: 8px;
      background: var(--background3);
      backdrop-filter: blur(20px);
      padding: 20px;
      max-width: 614px;
      width: 100%;
      margin: 0 auto;
    }

    .main-heading {
      max-width: 674px;
      width: 100%;
      margin: 0 auto;
      margin-bottom: 63px;

      h4 {
        color: var(--V6-Dark-Theme-Primary-Text-Color);
        text-align: center;
        font-size: 40px;
        font-style: normal;
        line-height: 100%;
        text-transform: uppercase;
        margin-bottom: 20px;
      }

      p {
        color: var(--V6-Dark-Theme-Primary-Text-Color);
        text-align: center;
        font-size: 18px;
        font-style: normal;
        font-family: "hregular";
        line-height: 140%;
      }

      .social-links {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 30px;
        margin-top: 20px;

        a {
          width: 48px;
          height: 48px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 50px;
          border: 1px solid var(--border);
          background: var(--background3);

          svg {
            transition: 0.3s linear;

            path {
              fill: var(--text-color2);
              transition: 0.3s linear;
            }
          }

          &:hover {
            svg {
              path {
                fill: var(--btn-green-bg);
              }
            }
          }
        }
      }
    }
  }

  .connectwallet-section {
    .bottom-wallet {
      border-radius: 4px;
      background: var(--claimcard-bg);
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      max-width: 633px;
      width: 100%;
      margin: 0 auto;
      gap: 10px;
      padding: 20px;

      a {
        border-radius: 4px;
        background: var(--input-bg);
        padding: 44px 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 12px;
        color: var(--d-body-link-text-hov);
        text-align: center;
        font-size: 18px;
        font-style: normal;
        font-family: "hmedium";
        line-height: 100%;
        transition: 0.3s linear;

        &:hover {
          border-radius: 4px;
          background: rgba(48, 241, 182, 0.2);
        }
      }
    }
  }

  .verify-email {
    .verify-box {
      max-width: 674px;
      width: 100%;
      margin: 0 auto;

      h5 {
        color: var(--V6-Dark-Theme-Primary-Text-Color);
        font-size: 16px;
        font-style: normal;
        line-height: 130%;
        margin-bottom: 4px;
      }

      p {
        color: var(--V6-Dark-Theme-Secondary-Text-Color);
        font-size: 12px;
        font-style: normal;
        font-family: "hregular";
        line-height: normal;
        margin-bottom: 25px;
      }

      .option-field {
        margin-bottom: 54px;

        label {
          display: block;
          color: var(--V6-Dark-Theme-Secondary-Text-Color);
          font-size: 16px;
          font-style: normal;
          font-family: "hregular";
          line-height: normal;
          margin-bottom: 12px;
        }

        .inner-option {
          position: relative;

          .btn-send {
            position: absolute;
            top: 50%;
            right: 20px;
            transform: translate(0%, -50%);
            color: var(--btn-green-bg);
            color: var(--btn-green-bg);
            font-size: 16px;
            font-style: normal;
            font-family: "hregular";
            line-height: normal;
            cursor: pointer;

            &.disabled {
              opacity: 0.5;
              pointer-events: none;
            }
          }
        }

        input {
          border-radius: 4px;
          background: var(--input-bg);
          color: var(--heading-text);
          padding: 16px 18px;
          font-size: 16px;
          font-style: normal;
          font-family: "hregular";
          line-height: normal;
          width: 100%;
          border: 1px solid transparent;
          transition: 0.3s linear;

          &:focus {
            border-radius: 4px;
            border: 1px solid var(--btn-green-bg);
            background: var(--input-bg);
          }

          &.error {
            border-radius: 4px;
            border: 1px solid var(--Red, #fc4f52);
            background: var(--input-bg);
          }
        }
      }
    }
  }

  .connecttwitter {
    .twitter-box {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      max-width: 674px;
      width: 100%;
      margin: 0 auto;

      .para {
        color: var(--V6-Dark-Theme-Secondary-Text-Color);
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-family: "hregular";
        line-height: 100%;
        margin-top: 24px;
        margin-bottom: 46px;
      }

      .option-field {
        width: 100%;
        margin-bottom: 48px;

        label {
          display: block;
          color: var(--V6-Dark-Theme-Secondary-Text-Color);
          font-size: 16px;
          font-style: normal;
          font-family: "hregular";
          line-height: normal;
          margin-bottom: 12px;
        }

        .btn-connect {
          border-radius: 4px;
          background: var(--input-bg);
          padding: 16px 18px;
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 12px;
          width: 100%;
          border: none;
          color: var(--btn-green-bg);
          font-size: 14px;
          font-style: normal;
          font-family: "hregular";
          line-height: 100%;
          min-height: 76px;
        }

        .btn-green {
          padding: 15px 22px;
          display: flex;
          justify-content: center;
          align-items: center;
          max-width: 250px;
          width: 100%;
          margin: 0 auto;

          &.disabled {
            opacity: 0.5;
            pointer-events: none;
            // cursor: not-allowed;
          }
        }

        .when-accounthave {
          border-radius: 4px;
          background: var(--input-bg);
          padding: 16px 18px;
          display: flex;
          justify-content: space-between;
          align-items: center;

          .left-detail {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 10px;

            .profile {
              width: 44px;
              height: 44px;
              border-radius: 50%;
              overflow: hidden;
              flex-shrink: 0;

              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                padding: 5px;
              }
            }

            h6 {
              color: var(--heading-text);
              font-size: 16px;
              font-style: normal;
              font-family: "hregular";
              line-height: normal;
            }
          }

          .btn-remove {
            color: var(--Red, #fc4f52);
            font-size: 16px;
            font-style: normal;
            font-family: "hregular";
            line-height: normal;
          }

          &.error {
            border-radius: 4px;
            border: 1px solid var(--Red, #fc4f52);
            background: var(--input-bg);
          }
        }

        .error-text {
          color: var(--Red, #fc4f52);
          font-size: 14px;
          font-style: normal;
          font-family: "hregular";
          line-height: normal;
          margin-top: 8px;
        }
      }
    }
  }

  .survey-questions {
    .bottom-questions {
      max-width: 850px;
      width: 100%;
      margin: 0 auto;

      .head-text {
        margin-bottom: 35px;
        color: var(--V6-Dark-Theme-Primary-Text-Color);
        text-align: center;
        font-family: "f37";
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: 130%;

        span {
          color: var(--V6-Dark-Theme-Secondary-Text-Color);
          font-family: "f37";
          font-size: 24px;
          font-style: normal;
          font-weight: 500;
          line-height: 130%;
        }
      }

      .parent-cards {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        gap: 18px;

        .single-card {
          cursor: pointer;

          .main-img {
            width: 100%;
            height: 198px;
            overflow: hidden;
            border-radius: 8px;
            border: 1px solid var(--Stroke, #2b3e43);
            background: var(--Background-Color, #02171d);
            transition: 0.3s linear;

            &:hover {
              border-radius: 8px;
              border: 1px solid var(--btn-green-bg);
              background: var(--Background-Color, #02171d);
              box-shadow: 0px 14px 30px -20px var(--btn-green-bg);
            }

            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }

          h6 {
            color: var(--V6-Dark-Theme-Primary-Text-Color);
            font-size: 18px;
            font-style: normal;
            font-family: "hmedium";
            line-height: 130%;
            text-align: center;
            margin-top: 12px;
          }

          &.selected {
            .main-img {
              border-radius: 8px;
              border: 1px solid var(--btn-green-bg);
              background: var(--Background-Color, #02171d);
              box-shadow: 0px 14px 30px -20px var(--btn-green-bg);
            }

            h6 {
              color: var(--btn-green-bg);
            }
          }
        }
      }

      .bottom-detail {
        margin-top: 63px;

        h6 {
          color: var(--V6-Dark-Theme-Primary-Text-Color);
          text-align: center;
          font-size: 20px;
          font-style: normal;
          font-family: "hmedium";
          line-height: 130%;
          margin-bottom: 15px;
        }

        .firstbtngreen-btn {
          max-width: 345px;
          width: 100%;
          margin: 0 auto;
        }

        .navigation-buttons {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 20px;

          button {
            max-width: 162px;
            min-width: 162px;
          }

          .btn-back {
            border-radius: 4px;
            background: var(--input-bg);
            border: none;
            padding: 18px 22px;
            color: var(--V6-Dark-Theme-Primary-Text-Color);
            text-align: center;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 100%;
          }

          .btn-green {
            &.disabled {
              opacity: 0.5;
              pointer-events: none;
              // cursor: not-allowed;
            }
          }
        }
      }
    }
  }

  .social-task {
    .bottom-social-detail {
      max-width: 633px;
      width: 100%;
      margin: 0 auto;

      .head-text {
        color: var(--V6-Dark-Theme-Primary-Text-Color);
        text-align: center;
        font-family: "f37";
        font-size: 24px;
        font-style: normal;
        line-height: 130%;
        margin-bottom: 35px;
      }

      .parent {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 10px;

        .single-task {
          border-radius: 8px;
          background: var(--background3);
          backdrop-filter: blur(20px);
          padding: 20px;
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;

          .telegram-login-widget {
            position: relative;

            iframe {
              opacity: 0 !important;
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              width: 100px;
            }
          }

          .left-text {
            h5 {
              color: var(--V6-Dark-Theme-Primary-Text-Color);
              font-size: 16px;
              font-style: normal;
              line-height: 130%;
              margin-bottom: 4px;
            }

            p {
              color: var(--V6-Dark-Theme-Secondary-Text-Color);
              font-size: 12px;
              font-style: normal;
              font-family: "hregular";
              line-height: normal;

              a {
                color: var(--btn-green-bg);
                font-size: 12px;
                font-style: normal;
                font-family: "hregular";
                line-height: normal;
                text-decoration: underline !important;
                text-decoration-style: solid;
                text-decoration-skip-ink: auto;
                text-decoration-thickness: auto;
                text-underline-offset: auto;
                text-underline-position: from-font;
              }
            }
          }

          .twice-btns {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            gap: 10px;

            .btn-green {
              min-width: 86.5px;
            }
          }

          .btn-green {
            max-width: 100px;
          }

          .btn-joined {
            background-color: transparent;
            border-radius: 4px;
            border: 1px solid var(--btn-green-bg);
            padding: 10px 14px;
            color: var(--btn-green-bg);
            font-size: 12px;
            font-style: normal;
            font-family: "hmedium";
            line-height: 120%;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 8px;
            max-width: 100px;
            width: 100%;
            pointer-events: none;

            svg {
              path {
                fill: var(--btn-green-bg);
              }
            }
          }
        }
      }
    }

    .bottom-detail {
      margin-top: 63px;

      h6 {
        color: var(--V6-Dark-Theme-Primary-Text-Color);
        text-align: center;
        font-size: 20px;
        font-style: normal;
        font-family: "hmedium";
        line-height: 130%;
        margin-bottom: 15px;
      }

      .firstbtngreen-btn {
        max-width: 345px !important;
        width: 100%;
        margin: 0 auto;
      }
    }
  }

  .refferal-section {
    .parent-referalnew {
      border-radius: 8px;
      background: var(--background3);
      backdrop-filter: blur(20px);
      max-width: 724px;
      width: 100%;
      margin: 0 auto;
      padding: 20px;

      .para-new {
        color: var(--V6-Dark-Theme-Primary-Text-Color);
        text-align: center;
        font-family: "f37";
        font-size: 16px;
        font-style: normal;
        line-height: 130%;
        margin-bottom: 20px;
      }
    }

    .refferal-parent {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 5px;

      .left-ref {
        max-width: 312px;
        width: 100%;
        border-radius: 10px;
        border: 1px solid var(--border);
        background: var(--background3);
        padding: 16px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .inner-left {
          h6 {
            color: var(--V6-Dark-Theme-Secondary-Text-Color);
            font-size: 12px;
            font-style: normal;
            font-family: "hregular";
            line-height: 120%;
            margin-bottom: 7px;
          }

          p {
            color: var(--V6-Dark-Theme-Secondary-Text-Color);
            font-family: "f37";
            font-size: 14px;
            font-style: normal;
            line-height: 120%;

            span {
              color: var(--V6-Dark-Theme-Primary-Text-Color);
              font-family: "f37";
              font-size: 14px;
              font-style: normal;
              line-height: 120%;
            }
          }
        }
      }

      .right-ref {
        flex: 1;
        width: 100%;
        border-radius: 10px;
        border: 1px solid var(--border);
        background: var(--background3);
        padding: 16px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .inner-left {
          h6 {
            color: var(--V6-Dark-Theme-Secondary-Text-Color);
            font-size: 12px;
            font-style: normal;
            font-family: "hregular";
            line-height: 120%;
            margin-bottom: 7px;
          }

          h5 {
            color: var(--V6-Dark-Theme-Primary-Text-Color);
            font-family: "f37";
            font-size: 14px;
            font-style: normal;
            line-height: 120%;
            white-space: nowrap;
            max-width: 295px;
            overflow-x: auto;

            &::-webkit-scrollbar {
              display: none;
            }
          }
        }
      }

      .reloadicon {
        path {
          stroke: var(--btn-green-bg);
          fill: var(--btn-green-bg);
        }
      }

      svg {
        path {
          stroke: var(--btn-green-bg);
        }
      }
    }

    .btn-green {
      padding: 15px 22px;
      display: flex;
      justify-content: center;
      align-items: center;
      max-width: 250px;
      width: 100%;
      margin: 0 auto;

      &.disabled {
        opacity: 0.5;
        pointer-events: none;

        // cursor: not-allowed;
      }
    }
  }

  .completed-text {
    color: var(--V6-Dark-Theme-Primary-Text-Color);
    text-align: center;
    font-family: "f37";
    font-size: 22px;
    font-style: normal;
    line-height: 100%;
    text-transform: uppercase;
    margin-bottom: 23px;
  }
}

@media (max-width: 600px) {
  .gems-community .parent .bottom-agreement {
    justify-content: flex-start;
    align-items: flex-start;
  }

  .gems-community .parent .main-heading h5 {
    font-size: 24px;
  }

  .gems-community .parent .main-heading h4 {
    font-size: 30px;
  }

  .gems-community .parent .inline-elem .single-elem {
    min-width: 113px;
  }

  .gems-community .parent .inline-elem .single-elem h6 {
    font-size: 14px;
  }

  .gems-community .parent .inline-elem {
    gap: 15px;
  }

  .survey-section .steps-survey {
    flex-wrap: nowrap;
    justify-content: flex-start;
    overflow-x: auto;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .survey-section .steps-survey .single-step p {
    white-space: nowrap;
  }

  .survey-section .bottom-content .main-heading h4 {
    font-size: 24px;
  }

  .survey-section .connecttwitter .twitter-box .option-field .when-accounthave {
    font-size: 15px;
  }

  .survey-section .survey-questions .bottom-questions .parent-cards {
    grid-template-columns: 1fr 1fr;
  }

  .survey-section .survey-questions .bottom-questions .parent-cards .single-card h6 {
    font-size: 16px;
  }

  .survey-section .survey-questions .bottom-questions .parent-cards .single-card .main-img {
    height: 170px;
  }

  .survey-section .social-task .bottom-social-detail .head-text {
    font-size: 20px;
  }

  .survey-section .social-task .bottom-social-detail .parent .single-task {
    flex-direction: column;
    gap: 20px;
  }

  // .survey-section .social-task .bottom-social-detail .parent .single-task .btn-green {
  //     max-width: 100%;
  // }

  .survey-section .refferal-section .refferal-parent {
    flex-direction: column;
  }

  .survey-section .completed-text {
    font-size: 20px;
  }

  .survey-section .bottom-content .main-heading .social-links a {
    width: 40px;
    height: 40px;
    padding: 10px;
  }

  .survey-section .survey-questions .bottom-questions .bottom-detail {
    position: fixed;
    bottom: 0;
    left: 0;
    border-top: 1px solid var(--border);
    background: var(--background7);
    width: 100%;
    padding: 30px 20px;
    z-index: 999999;
  }

  .survey-section .connectwallet-section .bottom-wallet {
    grid-template-columns: 1fr;
  }

  .survey-section .refferal-section .refferal-parent .right-ref .inner-left h5 {
    font-size: 12px;
  }

  .survey-section .steps-survey .line {
    flex-shrink: 0;
  }

  .survey-section .verify-email .verify-box .option-field input {
    font-size: 12px;
    padding-right: 130px;
  }

  .survey-section .verify-email .verify-box .option-field .inner-option .btn-send {
    font-size: 12px;
  }

  .survey-section .refferal-section .refferal-parent .right-ref .inner-left h5 {
    max-width: 200px;
  }

  .showinmobileonly {
    display: block !important;
  }

  // .survey-section .social-task .bottom-social-detail .parent .single-task .btn-joined{
  //     max-width: 100%;
  // }
  .survey-section .refferal-section .refferal-parent .left-ref {
    max-width: 100%;
  }

  .survey-section .social-task .bottom-social-detail .parent .single-task .left-text p br {
    display: none;
  }
}

.light-theme .gems-community .main-banner-video {
  mix-blend-mode: color-dodge;
}

.sign-required {
  p {
    color: var(--V6-Dark-Theme-Primary-Text-Color);
    text-align: center;
    font-family: "f37";
    font-size: 24px;
    font-style: normal;
    line-height: 130%;
  }

  .btn-green {
    max-width: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 25px;
  }
}

.new-text {
  color: var(--V6-Dark-Theme-Primary-Text-Color);
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 130%;
  max-width: 448px;
  width: 100%;
  margin: 0 auto;
  margin-bottom: 15px;
  margin-top: 63px;
}

.btn-telegram {
  position: relative;
  // pointer-events: none;
}

.btn-telegram iframe {
  display: block !important;
  width: 100px !important;
  height: 50px !important;
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  z-index: 1;
  cursor: pointer !important;
  opacity: 0 !important;
}

.single-task-error {
  border-radius: 8px;
  border: 1px solid #fc4f52;
  background: #021c24;
  backdrop-filter: blur(20px);
}

.whendisabled {
  opacity: 0.5;
  pointer-events: none;
}




.community-modal {
  z-index: 99999999999999999;

  .modal-dialog {
    max-width: 480px;

    .modal-content {
      border-radius: 4px;
      background: var(--background3);
      padding: 30px;

      .modal-body {
        padding: 0px;

        .mainbox {
          border-radius: 4px;
          background: var(--background7);
          padding: 30px;
          width: 100%;
          margin-bottom: 12px;

          .mainsocial {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 5px;
            margin-top: 12px;
          }

          .upperheadgreen {
            color: var(--V6-Dark-Theme-Green-Color);
            text-align: center;
            font-family: "f37";
            font-size: 18px;
            font-style: normal;
            line-height: 120%;
          }

          .paramainsec {
            color: var(--V6-Dark-Theme-Primary-Text-Color);
            text-align: center;
            font-family: "hregular";
            font-size: 16px;
            font-style: normal;
            line-height: 120%;
            margin-top: 12px;
            max-width: 360px;
            width: 100%;
          }

          .formargin {
            margin: 0px !important;
          }

          .upperhead {
            color: var(--V6-Dark-Theme-Green-Color);
            text-align: center;
            font-family: "f37";
            font-size: 30px;
            font-style: normal;
            line-height: 120%;
            margin-bottom: 10px;
          }

          .paramain {
            color: var(--V6-Dark-Theme-Primary-Text-Color);
            text-align: center;
            font-family: "hregular";
            font-size: 16px;
            font-style: normal;
            margin-bottom: 10px;
            line-height: 120%;
            max-width: 380px;
          }

          .paramainone {
            color: var(--V6-Dark-Theme-Primary-Text-Color);
            text-align: center;
            font-family: "hregular";
            font-size: 16px;
            font-style: normal;
            margin-bottom: 10px;
            line-height: 120%;
            max-width: 380px;

            span {
              font-family: "hsemibold";
            }
          }

          .connectbtn {
            border-radius: 4px;
            background: var(--V6-Dark-Theme-Green-Color);
            border: none;
            width: 172px;
            padding: 14px 14px 14px 19px;
            color: var(--background7);
            text-align: center;
            font-family: "hmedium";
            font-size: 14px;
            font-style: normal;
            line-height: 120%;
            display: block;
            margin: 0 auto;
            margin-top: 25px;
          }
        }

        .endpara {
          color: var(--V6-Dark-Theme-Primary-Text-Color);
          text-align: center;
          font-family: "hregular";
          font-size: 16px;
          font-style: normal;
          line-height: 120%;
          margin-top: 25px;

          &:last-child {
            margin-top: 0px !important;
          }
        }
      }
    }
  }

  .mainsocial {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;

    a {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      border: 1px solid #2B3E43;
    }
  }

  .user-seeing {
    border-radius: 2px;
    background: rgba(232, 143, 53, 0.10);
    padding: 10px;
    margin-top: 25px;
    color: #E88F35;
    text-align: center;
    font-size: 13px;
    font-style: normal;
    font-family: "hregular";
    line-height: 120%;
    text-align: center;
  }
}