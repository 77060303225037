.main-banner {
  padding-top: 130px;
  padding-bottom: 90px;
  position: relative;
  z-index: 89;
  overflow: hidden;

  .main-banner-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -2;
    pointer-events: none;
  }

  .banner-shadow {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
    pointer-events: none;
    object-position: bottom;
  }

  .main-content {
    position: relative;
    z-index: 50;

    h4 {
      color: var(--heading-text);
      font-size: 88px;
      font-style: normal;
      font-weight: 500;
      line-height: 110%;
      text-transform: capitalize;
      margin-bottom: 40px;
      max-width: 1090px;
      width: 100%;
    }

    p {
      color: var(--heading-text);
      font-size: 14px;
      font-style: normal;
      font-family: "hmedium";
      line-height: 140%;
      border-left: 3px solid var(--heading-text);
      padding-left: 20px;
      max-width: 621px;
      width: 100%;
      margin-bottom: 35px;
    }

    .twice-btns {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 10px;

      a {
        padding: 18px 22px;

      }
    }
  }
}

.user-data {
  padding-top: 110px;

  .parent {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 67px;

    .text {
      p {
        color: var(--V6-Dark-Theme-Secondary-Text-Color, #939FA3);
        font-size: 22px;
        font-style: normal;
        font-family: "hmedium";
        line-height: 100%;
        margin-bottom: 25px;
      }

      h5 {
        color: var(--heading-text);
        font-size: 47.1px;
        font-style: normal;
        line-height: 100%;
      }
    }

  }
}


@media (max-width:600px) {
  .main-banner .main-content h4 {
    font-size: 38px;
  }

  .main-banner .main-content .twice-btns {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .main-banner .main-content .twice-btns a {
    text-align: center;
  }
  .user-data .parent{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 45px;
  }
  .user-data .parent .text p{
    font-size: 14px;
  }
  .user-data .parent .text h5{
    font-size: 36px;
  }
}



@media (max-width:390px){
  .main-banner .main-content .twice-btns a{
    padding: 18px 15px;
  }
}