.wrapper-seeallprojects {
    .main-navbar {
        position: relative;
        transform: unset;
    }
}

.seeallprojects {
    position: relative;
    z-index: 99;
    padding-top: 50px;
    background: var(--body-bg);
    overflow: hidden;
    min-height: 100vh;

    .allprojects-bg {
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        pointer-events: none;
        width: 100%;
        object-fit: cover;
        max-height: 535px;
        height: 100%;
    }

    .main-banner-video {
        mix-blend-mode: lighten;
        position: absolute;
        top: 85px;
        right: 0;

    }


    .upper-content {
        padding-bottom: 47px;
        position: relative;



        .top-title {
            color: var(--btn-green-bg);
            font-family: "bl";
            font-size: 32px;
            font-style: normal;
            line-height: 140%;
            margin-bottom: 10px;
        }

        .head-text {
            color: var(--heading-text);
            font-size: 55px;
            font-style: normal;
            line-height: 100%;
            text-transform: uppercase;
            max-width: 636px;
            width: 100%;
        }

        .twice-btns {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 10px;
            margin-top: 40px;

            .btn-green {
                padding: 16px 22px;

            }

            .btn-transparent {
                border-radius: 4px;
                padding: 16px 22px;
                color: var(--Primary-Green, #329879);
                font-size: 14px;
                font-style: normal;
                font-family: "hregular";
                line-height: 100%;
                border: none;
            }
        }

        .user-data {
            padding-top: 35px;
        }
    }

    .bottom-content {
        background: var(--body-bg);
        position: relative;
        // border-top: 1px solid var(--border2);

        .nav-tabs {
            justify-content: flex-start;
            gap: 30px;
            border: none;
            // padding-top: 20px;
            padding-bottom: 2px;



            .nav-item {

                .nav-link {
                    border: none;
                    border-bottom: 2px solid transparent;
                    padding: 13px 0px;
                    color: var(--tab-text);
                    font-size: 14px;
                    font-style: normal;
                    font-family: "hregular";
                    line-height: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    white-space: nowrap;

                    &:first-child {
                        padding-left: 0;
                    }
                }
            }

            .nav-item.show .nav-link,
            .nav-link.active {
                background-color: transparent;
                border-bottom: 2px solid var(--V6-Dark-Theme-Green-Color, #30F1B6);
                color: var(--V6-Dark-Theme-Green-Color, #30F1B6);

            }
        }
    }

}

.presale-cards {
    .custom-container {
        padding: 0;
    }

    .bottom-cards {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;

        .single-card {
            &:nth-child(3n) {
                border-right: 1px solid var(--border2);
            }

            &:nth-last-child(-n + 3) {
                border-bottom: 1px solid var(--border2);
            }

            &:last-child {
                border-right: 1px solid var(--border2);
            }
        }
    }
}


@media (max-width:600px) {
    .seeallprojects .upper-content .top-title {
        font-size: 26px;
    }

    .seeallprojects .upper-content .head-text {
        font-size: 34px;
    }

    .seeallprojects .upper-content {
        padding-bottom: 460px;
    }

    .presale-cards .bottom-cards {
        grid-template-columns: 1fr;
    }

    .seeallprojects .main-banner-video {
        top: 535px;
    }


    .seeallprojects .allprojects-bg {
        display: none;
    }

    .presale-cards {
        margin-top: 0 !important;
    }

    .seeallprojects .bottom-content .custom-container {
        padding: 0;
    }

    .seeallprojects .bottom-content .nav-tabs {
        padding-top: 20px;
        padding-bottom: 2px;
        padding-left: 15px;
        padding-right: 15px;
    }

    .allprojects-bg-mbl {
        display: block !important;
        height: 100% !important;
        max-height: 985px !important;
    }
}

.light-theme .seeallprojects .main-banner-video {
    mix-blend-mode: difference;
}

.page-item.active {
    background: #329879 !important;
    border-radius: 3px !important;
}

.page-item.active a {
    color: #fff !important;
}

.whencardsnotshowing {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 145px 0;

    p {
        color: var(--Secondary-Text-Color, #77868B);
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 120%;
        text-transform: capitalize;
        margin: 28px 0;
    }

    .btn-green {
        padding: 18px 22px;

    }
}

.seeallprojects {
    .process-projects {
        margin: 0 !important;
        border-bottom: 1px solid var(--border2);

        .bottom-cards {
            position: relative;

            .cardshadow-light {
                position: absolute;
                top: 0;
                right: -2px;
                z-index: 1;
                pointer-events: none;
                height: 100%;
            }

            .cardshadow-lightleft {
                position: absolute;
                top: 0;
                left: -2px;
                z-index: 1;
                pointer-events: none;
                transform: rotate(180deg);
                height: 100%;
            }
        }

        .slick-next {
            right: 0;
            top: -50px;
        }

        .slick-prev {
            right: 50px;
            top: -50px;
            left: unset !important;
        }

        .slick-prev,
        .slick-next {
            z-index: 9999 !important;
        }

        .btn-green {
            padding: 18px 22px;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .single-card .token-info .para {
            min-height: 50px;
        }

    }

    .pro-projects {
        padding-top: 40px;
        padding-bottom: 35px;

        h6 {
            color: var(--heading-text);
            font-family: "f37";
            font-size: 32px;
            font-style: normal;
            line-height: 100%;
            text-transform: uppercase;

            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 10px;

            span {
                border-radius: 50px;
                border: 1px solid var(--btn-green-bg);
                padding: 6px;
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 4px;
                color: var(--btn-green-bg);
                font-size: 12px;
                font-style: normal;
                font-family: "hregular";
                line-height: 100%;

                svg {
                    path {
                        fill: var(--btn-green-bg);
                    }
                }
            }
        }
    }

    .headingpdateflex {
        display: flex;
        justify-content: space-between;
        align-items: center;

        p {
            display: flex;
            align-items: center;
            gap: 10px;
            color: var(--V6-Dark-Theme-Secondary-Text-Color1);
            font-size: 18px;
            font-style: normal;
            font-family: "hmedium";
            line-height: 100%;
            svg{
                path{
                    fill: var(--heading-text);
                }
            }
        }
    }
}

@media (max-width:600px) {
    .seeallprojects .pro-projects h6 {
        font-size: 26px;
    }

    .seeallprojects .process-projects .bottom-cards .cardshadow-light {
        display: none !important;
    }

    .seeallprojects .process-projects .bottom-cards .cardshadow-lightleft {
        display: none !important;
    }
    .seeallprojects .headingpdateflex{
        flex-direction: column;
        align-items: flex-start;
        gap: 15px;
    }
    .seeallprojects .headingpdateflex p{
        font-size: 16px;
    }
}


.light-theme .seeallprojects .process-launchpad .process-projects .bottom-cards .single-card {
    background: linear-gradient(180deg, #D1DADD 0%, #AED6CF 169.03%);
    backdrop-filter: blur(20px);
}

.dark-theme .seeallprojects .process-launchpad .process-projects .bottom-cards .single-card {
    background: linear-gradient(180deg, rgba(8, 21, 25, 0.40) 0%, rgba(6, 104, 74, 0.40) 289.27%);
    backdrop-filter: blur(20px);
}




.process-projects {
    .btn-green {
        padding: 18px 22px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}