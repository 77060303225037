.self-serve {
  position: relative;
  z-index: 99;
  padding-top: 110px;
  padding-bottom: 100px;
  overflow: hidden;

  .selfserve-bg {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    pointer-events: none;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .main-banner-video {
    position: absolute;
    bottom: 0%;
    right: 0%;
    transform: translate(40%, 40%);
    z-index: -1;
    pointer-events: none;
    mix-blend-mode: plus-lighter;
  }

  .parent {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 289px;

    .left-side {
      max-width: 534px;
      width: 100%;

      h4 {
        color: var(--heading-text);
        font-size: 70px;
        font-style: normal;
        font-weight: 500;
        line-height: 100%;
        text-transform: capitalize;
      }

      p {
        color: var(--V6-Dark-Theme-Secondary-Text-Color);
        font-size: 16px;
        font-style: normal;
        font-family: "hregular";
        line-height: 140%;
        margin: 30px 0;
      }

      .btn-create {
        border-radius: 4px;
        background: var(--btn-green-bg);
        padding: 18px 22px;
        color: var(--btn-green-text);
        font-size: 14px;
        font-style: normal;
        font-family: "hregular";
        line-height: 100%;
        text-transform: capitalize;
        transition: 0.3s linear;
        &:hover {
          background: var(--btn-green-bg-hover);
        }

        &:active {
          box-shadow: 0px 0px 10px 8px var(--btn-green-bg-active);
        }
      }
    }

    .right-side {
      flex: 1;

      .points-data {
        position: relative;

        &::before {
          content: "";
          position: absolute;
          top: 0;
          left: 19px;
          height: 95%;
          width: 1px;
          border: 1px solid var(--btn-green-bg);
          opacity: 30%;
          pointer-events: none;
        }

        .single-value {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          gap: 20px;
          margin-bottom: 32px;

          &:last-child {
            margin-bottom: 0;
          }

          span {
            border-radius: 100px;
            border: 1px solid rgba(48, 241, 182, 0.3);
            background: rgba(48, 241, 182, 0.1);
            backdrop-filter: blur(10px);
            width: 38px;
            height: 38px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: var(--heading-text);
            text-align: center;
            font-size: 14px;
            font-style: normal;
            font-family: "hmedium";
            line-height: 100%;
            text-transform: capitalize;
          }

          h6 {
            color: var(--heading-text);
            font-size: 20px;
            font-style: normal;
            font-family: "hregular";
            line-height: 130%;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 15px;

            .coming-soon {
              border-radius: 30px;
              background: rgba(48, 241, 182, 0.1);
              backdrop-filter: blur(10px);
              padding: 10px;
              color: var(--btn-green-bg);
              font-size: 10px;
              font-style: normal;
              font-family: "hmedium";
              line-height: 100%;
              display: inline;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 600px) {
  .self-serve .parent {
    flex-direction: column;
    gap: 70px;
  }
  .self-serve .parent .left-side h4 {
    font-size: 34px;
    br {
      display: none;
    }
  }
  .self-serve .parent .left-side .btn-create {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
  }
  .self-serve .parent .right-side .points-data .single-value h6 .coming-soon {
    white-space: nowrap;
  }
  .self-serve .main-banner-video {
    min-width: 1000px;
    width: 100%;
  }
  .self-serve .parent .right-side .points-data .single-value h6 {
    align-items: flex-start;
  }
}
