.howitworks-miners {
    padding: 60px 0;
    .parent {
        align-items: flex-start;

        .left-side{
            max-width: 383px;
            width: 100%;
            h4{
                font-size: 50px;
            }
        }

        .twice-text {
            p {
                color: var(--V6-Dark-Theme-Secondary-Text-Color);
                font-size: 14px;
                font-style: normal;
                font-family: "hregular";
                line-height: 120%;
                margin-top: 2px;
            }
        }
    }
     .parent .right-side .points-data::before{
        top: 10px;
    }
}

@media (max-width:600px){
    .self-serve .parent .right-side .points-data .single-value span{
        flex-shrink: 0;
    }
}