.gems-purchases {
  padding: 120px 0;

  .main-heading {
    margin-bottom: 30px;

    h5 {
      color: var(--V6-Dark-Theme-Primary-Text-Color);
      font-size: 30px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0.6px;
    }
  }

  .signup-nav {
    padding-bottom: 0;
    border-radius: 4px;
    background: var(--background7);
    gap: 5px;
    border: none;
    display: inline-flex;
    border: none;
    margin-bottom: 20px;
    max-width: 530px;
    width: 100%;
    justify-content: space-between;

    .nav-item {
      flex: 1;

      .nav-link {
        padding: 0;
        border-radius: 4px;
        padding: 8px 20px;
        color: var(--Secondary-Text-Color, #77868b);
        font-family: "f37" !important;
        font-size: 14px;
        font-style: normal;
        line-height: 100%;
        border: none;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
      }
    }

    .nav-item.show .nav-link,
    .nav-link.active {
      border-radius: 4px;
      background: var(--input-bg1);
      color: var(--btn-green-bg);
    }
  }
}

.all-purchases {
  padding: 120px 0;

  .main-head {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;

    .btn-back {
      border-radius: 2px;
      background: var(--d-body-link-bg);
      padding: 2px 10px;
      color: var(--btn-green-bg);
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0.28px;
      text-transform: uppercase;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 10px;
    }

    h6 {
      color: var(--V6-Dark-Theme-Primary-Text-Color);
      font-size: 22px;
      font-style: normal;
      font-family: "f37";
      line-height: 100%;
      letter-spacing: 0.44px;
      text-transform: uppercase;
    }
  }

  .main-heading {
    p {
      color: var(--V6-Dark-Theme-Secondary-Text-Color);
      font-family: "f37";
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 110%;
      text-transform: uppercase;
      margin-bottom: 10px;
    }

    .twice-elem {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 10px;

      img {
        width: 24px;
        height: 24px;
        border-radius: 50%;
      }

      h5 {
        color: var(--V6-Dark-Theme-Primary-Text-Color);
        font-family: "f37";
        font-size: 30px;
        font-style: normal;
        line-height: 24px;
        letter-spacing: 0.6px;
        text-transform: uppercase;
      }
    }
  }

  .claim-box {
    margin: 30px 0;
    border-radius: 4px;
    background: var(--background3);
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .left-side {
      .inside-left {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 15px;
        margin-bottom: 25px;

        .right-text {
          p {
            color: var(--V6-Dark-Theme-Secondary-Text-Color);
            font-size: 14px;
            font-style: normal;
            font-family: "hregular";
            line-height: 120%;
          }

          h5 {
            color: var(--V6-Dark-Theme-Primary-Text-Color);
            font-family: "f37";
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: 120%;
            letter-spacing: -0.18px;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            gap: 10px;

            span {
              color: var(--btn-green-bg);
              font-size: 14px;
              font-style: normal;
              font-family: "hregular";
              line-height: 120%;
              letter-spacing: -0.14px;
            }
          }
        }
      }

      .bottom-inline {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 20px;

        p {
          color: var(--V6-Dark-Theme-Primary-Text-Color);
          font-size: 14px;
          font-style: normal;
          font-family: "hregular";
          line-height: 120%;
          letter-spacing: -0.14px;
          text-transform: uppercase;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          gap: 5px;

          span {
            border-radius: 4px;
            background: var(--V6-Dark-Theme-Primary-Text-Color);
            width: 3px;
            height: 14px;
            display: flex;
          }
        }
      }
    }

    .right-side {
      border-radius: 4px;
      background: var(--claim-bg2);
      display: flex;
      flex-direction: column;
      padding: 10px 15px;
      justify-content: center;
      align-items: center;
      gap: 15px;

      p {
        color: var(--V6-Dark-Theme-Primary-Text-Color);
        font-size: 14px;
        font-style: normal;
        font-family: "hregular";
        line-height: 120%;
      }

      a {
        border-radius: 4px;
        background: var(--btn-green-bg);
        padding: 15px 22px;
        color: var(--btn-green-text);
        font-size: 14px;
        font-style: normal;
        font-family: "hregular";
        line-height: 100%;
      }
    }
  }
}

.custom-table {
  .table-responsive {
    th {
      background: var(--background3);
      color: var(--V6-Dark-Theme-Secondary-Text-Color);
      font-family: "f37";
      font-size: 12px;
      font-style: normal;
      text-transform: uppercase;
      padding: 20px;
      vertical-align: middle;
      border: none;
      white-space: nowrap;
    }

    .btn-claim {
      border-radius: 4px;
      background: var(--btn-green-bg);
      padding: 10px;
      color: var(--btn-green-text);
      font-size: 14px;
      font-style: normal;
      font-family: "hmedium";
      line-height: 100%;
      border: none;
      width: 86px;

      &.disabled {
        opacity: 0.5;
        pointer-events: none;
      }
    }

    .btn-claim-disabled {
      opacity: 0.5;
      pointer-events: none;

      border-radius: 4px;
      background: var(--btn-green-bg);
      padding: 10px;
      color: var(--btn-green-text);
      font-size: 14px;
      font-style: normal;
      font-family: "hmedium";
      line-height: 100%;
      border: none;
      width: 86px;
    }

    td {
      background: var(--background3);
      color: var(--V6-Dark-Theme-Primary-Text-Color);
      font-size: 14px;
      font-style: normal;
      font-family: "hregular";
      letter-spacing: 0.28px;
      vertical-align: middle;
      padding: 20px;
      border: none;
      border-top: 1px solid var(--border);
      white-space: nowrap;

      .twice-items {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 10px;

        img {
          width: 24px;
          height: 24px;
          border-radius: 50%;
          object-fit: cover;
        }

        h6 {
          color: var(--V6-Dark-Theme-Primary-Text-Color);
          font-size: 14px;
          font-style: normal;
          font-family: "hregular";
          line-height: 21px;
          letter-spacing: 0.28px;
        }
      }

      a {
        color: var(--btn-green-bg);
        font-size: 14px;
        font-style: normal;
        font-family: "hregular";
        line-height: 100%;
      }
    }
  }

  .custom-pagination {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 0px 0px 4px 4px;
    background: var(--background3);
    padding: 17px 22px;

    p {
      color: #77868b;
      font-size: 12px;
      font-style: normal;
      font-family: "hmedium";
      line-height: 110%;
      letter-spacing: 0.1px;
    }

    .pagination {
      li {
        a {
          padding: 6px 11px;
          border-radius: 5px;
          background-color: transparent;
          border: none;
          color: var(--V6-Dark-Theme-Green-Color);
          font-family: "f37";
          font-size: 15px;
          font-style: normal;
          line-height: 100%;

          &.active {
            border-radius: 5px;
            background: var(--page-active-bg);
          }

          &:focus {
            box-shadow: none;
          }
        }
      }

      svg {
        path {
          fill: var(--btn-green-bg);
        }
      }
    }
  }
}

.tablewithaccordion {
  .twice-text {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;

    img {
      width: 24px;
      height: 24px;
      border-radius: 50%;
    }
  }

  .btn-claim {
    border-radius: 4px;
    background: var(--btn-green-bg);
    padding: 10px;
    color: var(--btn-green-text);
    font-size: 14px;
    font-style: normal;
    font-family: "hmedium";
    line-height: 100%;
    border: none;
    width: 86px;

    &.disabled {
      opacity: 0.5;
      pointer-events: none;
    }
  }

  .btn-claim-disabled {
    opacity: 0.5;
    pointer-events: none;

    border-radius: 4px;
    background: var(--btn-green-bg);
    padding: 10px;
    color: var(--btn-green-text);
    font-size: 14px;
    font-style: normal;
    font-family: "hmedium";
    line-height: 100%;
    border: none;
    width: 86px;
  }

  td {
    &.available {
      color: var(--btn-green-bg);
    }

    &.unavailable {
      color: var(#fc4f52);
    }
  }

  .inside-tablesection {
    th {
      background: var(--background7);
    }

    td {
      background: var(--background7);
    }
  }
}

@media (max-width: 600px) {
  .custom-table .custom-pagination p {
    display: none;
  }

  .custom-table .custom-pagination {
    justify-content: center;
  }

  .all-purchases .claim-box {
    flex-direction: column;
    gap: 25px;
    align-items: flex-start;
  }

  .all-purchases .claim-box .right-side {
    width: 100%;
  }

  .gems-purchases .signup-nav {
    overflow-x: auto;
    flex-wrap: nowrap;
    justify-content: flex-start;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .gems-purchases .signup-nav .nav-item .nav-link {
    white-space: nowrap;
  }
}

.datatable td .dropdownnodesminers {
  .dropdown-toggle {
    background-color: transparent !important;
    border: none !important;
    box-shadow: none !important;
    outline: none;
    padding: 0;
  }

  .dropdown-menu {
    left: unset !important;
    right: 0 !important;
    border-radius: 4px;
    border: 1px solid var(--Stroke, #c0cbcf);
    background: #e2e9ec;
    box-shadow: 0px 14px 34px 0px rgba(0, 0, 0, 0.04);
    padding: 0;

    .twice {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px 18px;
      border-bottom: 1px solid var(--Stroke, #c0cbcf);
      background: #d1dbdf;

      p {
        color: var(--Secondary-Text-Color, #77868b);
        text-align: center;
        font-size: 14px;
        font-style: normal;
        font-family: "hregular";
        line-height: normal;
      }

      h6 {
        color: var(--Primary-Text-Color, #02171d);
        font-size: 14px;
        font-style: normal;
        font-family: "hmedium";
        line-height: normal;
      }
    }
  }
}

.datatable .inner-textt .dropdownnodesminers {
  .dropdown-toggle {
    background-color: transparent !important;
    border: none !important;
    box-shadow: none !important;
    outline: none;
    padding: 0;
    justify-content: flex-end;
  }

  .dropdown-menu {
    left: unset !important;
    right: 0 !important;
    border-radius: 4px;
    border: 1px solid var(--Stroke, #c0cbcf);
    background: #e2e9ec;
    box-shadow: 0px 14px 34px 0px rgba(0, 0, 0, 0.04);
    padding: 0;

    .twice {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px 18px;
      border-bottom: 1px solid var(--Stroke, #c0cbcf);
      background: #d1dbdf;

      p {
        color: var(--Secondary-Text-Color, #77868b);
        text-align: center;
        font-size: 14px;
        font-style: normal;
        font-family: "hregular";
        line-height: normal;
      }

      h6 {
        color: var(--Primary-Text-Color, #02171d);
        font-size: 14px;
        font-style: normal;
        font-family: "hmedium";
        line-height: normal;
      }
    }
  }
}

.dropdownnodesminers {
  display: inline-block;

  .dropdown-toggle {
    border: none;
    background-color: transparent;

    &::after {
      display: none;
    }

    &:hover {
      background-color: transparent !important;
    }
  }

  .dropdown-menu {
    left: unset !important;
    right: 0 !important;
    border-radius: 4px;
    border: 1px solid var(--Stroke, #c0cbcf);
    background: #e2e9ec;
    box-shadow: 0px 14px 34px 0px rgba(0, 0, 0, 0.04);
    padding: 0;

    .twice {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px 18px;
      border-bottom: 1px solid var(--Stroke, #c0cbcf);
      background: #d1dbdf;

      p {
        color: var(--Secondary-Text-Color, #77868b);
        text-align: center;
        font-size: 14px;
        font-style: normal;
        font-family: "hregular";
        line-height: normal;
      }

      h6 {
        color: var(--Primary-Text-Color, #02171d);
        font-size: 14px;
        font-style: normal;
        font-family: "hmedium";
        line-height: normal;
      }
    }
  }
}

@media (min-width: 1200px) {
  .table-responsive {
    overflow: visible;
  }
}

@media (max-width: 768px) {
  .custom-table .table-responsive {
    min-height: 300px;
    background-color: var(--background3);
  }
}

.getinsurance-modal {
  .getinusrance {
    .main-img {
      margin-bottom: 15px;

      img {
        width: 100%;
      }
    }

    .bottom-content {
      border-radius: 6px;
      border: 1px solid var(--border);
      padding: 20px;
      margin-bottom: 30px;

      .inline-elem {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 14px;

        &:last-child {
          margin-bottom: 0;
        }

        h6 {
          color: var(--btn-green-bg);
          font-size: 16px;
          font-style: normal;
          font-family: "hmedium";
          line-height: normal;
        }

        h4 {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          gap: 10px;
          color: var(--V6-Dark-Theme-Primary-Text-Color);
          text-align: right;
          font-family: "f37";
          font-size: 16px;
          font-style: normal;
          line-height: normal;

          span {
            color: var(--V6-Dark-Theme-Secondary-Text-Color);
            text-align: right;
            font-size: 16px;
            font-style: normal;
            font-family: "hregular";
            line-height: normal;
          }
        }
      }
    }
  }

  .common-twice-btns {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;

    button {
      border: none;
    }

    .cancle-btn {
      border-radius: 4px;
      background: var(--input-bg);
      height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: var(--V6-Dark-Theme-Primary-Text-Color);
      text-align: center;
      font-size: 14px;
      font-style: normal;
      font-family: "hmedium";
      line-height: 120%;
    }

    .confirm-btn {
      border-radius: 4px;
      background: var(--btn-green-bg);
      height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: var(--btn-green-text);
      text-align: center;
      font-size: 14px;
      font-style: normal;
      font-family: "hmedium";
      line-height: 120%;
    }
  }
}

.success-modal {
  .modal-body {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    svg {
      path {
        fill: var(--svg-fill);
      }
    }

    h6 {
      color: var(--V6-Dark-Theme-Primary-Text-Color);
      font-family: "f37";
      font-size: 22px;
      font-style: normal;
      font-weight: 500;
      line-height: 120%;
      margin-top: 20px;
      margin-bottom: 10px;
    }

    p {
      color: var(--V6-Dark-Theme-Secondary-Text-Color);
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-family: "hregular";
      line-height: 100%;
      margin-bottom: 20px;
    }

    .confirm-btn {
      border-radius: 4px;
      background: var(--btn-green-bg);
      height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: var(--btn-green-text);
      text-align: center;
      font-size: 14px;
      font-style: normal;
      font-family: "hmedium";
      line-height: 120%;
      max-width: 90%;
      width: 100%;
      border: none;
      margin: 0 auto;
    }
  }
}

.connectwallet-modal {
  .modal-dialog {
    max-width: 500px;
  }

  .modal-body {
    padding: 30px;
    padding-top: 0;

    .disclaimerdiv {
      padding: 20px;
    }

    .darktext {
      background: var(--card-bg2);
      border-radius: 4px;
      padding: 20px 10px 10px 20px;
      margin-bottom: 30px;

      .scrolltext {
        height: 380px;
        overflow: auto;
        padding-right: 5px;

        &::-webkit-scrollbar {
          width: 4px;
        }

        /* Track */
        &::-webkit-scrollbar-track {
          background: var(--border);
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
          background: var(--btn-green-bg);
        }

        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
          background: #fff;
        }

        .formarginleft {
          margin-left: 30px;
        }

        .darkpara {
          color: var(--heading-text);
          font-family: "hregular";
          font-size: 14px;
          font-style: normal;
          font-family: "hregular";
          line-height: 117%;
          margin-bottom: 14px;

          .bold {
            font-family: "hsemibold";
          }

          .headingtext {
            color: var(--heading-text);
            font-family: "hsemibold";
            font-size: 14px;
            font-style: normal;
            font-family: "hsemibold";
            line-height: 117%;
          }

          a {
            color: var(--heading-text);
            text-decoration: underline !important;
            font-family: "hsemibold";
          }

          .formarginleft {
            margin-left: 30px;
          }
        }

        .darkparaupper {
          color: var(--heading-text);
          font-family: "hsemibold";
          font-size: 14px;
          font-style: normal;
          font-family: "hsemibold";
          line-height: 117%;
          margin: 14px 0px;
        }
      }
    }

    .checkboxmain {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 10px;
      margin-bottom: 15px;

      .clear {
        clear: both;
      }

      .checkBox {
        display: block;
        cursor: pointer;
        width: 20px;
        height: 20px;
        border: 1.5px solid var(--heading-text);
        position: relative;
        overflow: hidden;
        border-radius: 4px;
      }

      .checkBox div {
        width: 36px;
        height: 36px;
        background-color: var(--btn-green-bg);
        top: -52px;
        left: -52px;
        position: absolute;
        background-position: center;
        transform: rotateZ(6deg);
        object-fit: cover;
        background-repeat: no-repeat;
        // background-image: url(../../assets/tick.svg);
      }

      .checkBox input[type="checkbox"]:checked + div {
        left: -8px;
        top: -8px;
      }

      .checkBox:has(input:checked) {
        border: 1.5px solid transparent;
      }

      .checkBox input[type="checkbox"] {
        position: absolute;
        left: 50px;
        visibility: hidden;
      }

      .transition {
        transition: 300ms ease;
      }

      .checkboxpara {
        color: var(--heading-text);
        font-size: 14px;
        font-style: normal;
        font-family: "hregular";
        line-height: 117%;
        /* 16.38px */
      }
    }

    .continuebutton {
      border-radius: 4px;
      background: var(--btn-green-bg);
      display: flex;
      padding: 18px 25px;
      justify-content: center;
      align-items: center;
      color: var(--btn-green-text);
      text-align: center;
      font-size: 14px;
      font-style: normal;
      font-family: "hsemibold";
      line-height: 100%;
      text-transform: capitalize;
      margin-top: 25px;
      border: none;
      width: 100%;
    }

    .continuebutton-disabled {
      border-radius: 4px;
      background: var(--btn-green-bg);
      opacity: 0.5;
      display: flex;
      padding: 18px 25px;
      justify-content: center;
      align-items: center;
      color: var(--btn-green-text);
      text-align: center;
      font-size: 14px;
      font-style: normal;
      font-family: "hsemibold";
      line-height: 100%;
      text-transform: capitalize;
      margin-top: 25px;
      border: none;
      width: 100%;
      pointer-events: none;
    }

    .disablebutton {
      border-radius: 4px;
      background: var(--btn-buy-bg);
      display: flex;
      padding: 18px 25px;
      justify-content: center;
      align-items: center;
      color: var(--btn-buy-text);
      font-size: 14px;
      font-style: normal;
      font-family: "hmedium";
      line-height: 100%;
      /* 14px */
      text-transform: capitalize;
      width: 100%;
      border: none;
      margin-top: 25px;
    }

    .twice-btns {
      display: grid;
      grid-template-columns: 1fr 1fr;
      align-items: center;
      padding: 25px 30px 35px;
      gap: 10px;

      a {
        border-radius: 4px;
        background: var(--card-bg3);
        display: flex;
        padding: 20px 20px;
        border: transparent;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        height: 185px;
        width: 100%;
        gap: 12px;

        h6 {
          color: var(--heading-text);
          font-size: 16px;
          font-style: normal;
          font-family: "hmedium";
          line-height: 100%;
          /* 18px */
          text-transform: capitalize;
        }

        &:hover {
          border: 1px solid var(--border);
          background: var(--card-bg);
        }
      }
    }

    .buy-modal {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      padding-top: 30px;

      p {
        color: var(--text-color);
        font-size: 16px;
        font-style: normal;
        font-family: "hregular";
        line-height: 140%;
        text-align: center;
        margin: 20px 0px 30px;

        span {
          font-family: "hsemibold";
        }
      }

      .nfthead {
        color: var(--heading-text);
        font-family: "f37";
        font-size: 22px;
        font-style: normal;
        line-height: 120%;
        margin-bottom: 10px;
        margin-top: 20px;
      }

      .twicebtns {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        gap: 9px;
        width: 100%;

        .btndull {
          display: flex;
          padding: 16px 14px 16px 19px;
          justify-content: center;
          align-items: center;
          border-radius: 4px;
          background: var(--card-bg2);
          color: var(--heading-text);
          text-align: center;
          font-family: "hmedium";
          font-size: 14px;
          font-style: normal;
          border: none;
          line-height: 120%;
          width: 100%;
          height: 49px;
        }

        .connectbtn {
          height: 49px;
          display: flex;
          padding: 16px 14px 16px 19px;
          justify-content: center;
          align-items: center;
          border-radius: 4px;
          background: var(--btn-green-bg);
          color: var(--btn-green-text);
          font-family: "hmedium";
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 100%;
          width: 100%;
          border: none;
          outline: none;
        }

        .connectbtn:hover {
          background-color: var(--button-hover);
          color: var(--btn-green-text);
        }

        .connectbtn:active {
          box-shadow: 0px 0px 10px 8px var(--button-active);
        }
      }

      .btn-common {
        border-radius: 4px;
        background: var(--V6-Dark-Theme-Green-Color);
        border: none;
        display: flex;
        padding: 18px 25px;
        justify-content: center;
        align-items: center;
        color: var(--btn-green-text);
        text-align: center;
        font-size: 14px;
        font-style: normal;
        font-family: "hmedium";
        line-height: 100%;
        width: 100%;
        border: none;
      }
    }
  }
}

.learnmore-modal {
  z-index: 999999;
  .modal-body .darktext .scrolltext .darkpara .formarginleft {
    margin-left: 15px !important;
  }

  p {
    ul {
      list-style-position: outside;
      margin-left: 50px;

      li {
        list-style-type: disc !important;
        margin: 5px 0;
      }
    }

    ol {
      li {
        margin: 5px 0;
      }
    }
  }
}
