.wrapper-career {
    min-height: 100vh;
    .green-text {
        color: #53FFEA !important;
        font-family: "Chakra Petch" !important;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 120%;
        margin-bottom: 12px;
    }

    .main-bannernew .main-contentss .faqsheads h3 {
        font-size: 54px;
        max-width: 753px;
    }

    .main-careerbanner {
        height: 400px;
        position: relative;
        display: flex;
        align-items: flex-end;
        padding-bottom: 45px;
      
        .leftshade {
          position: absolute;
          bottom: 0;
          left: 0;
          z-index: -1;
        }
      
        .rightshade {
          position: absolute;
          bottom: 0;
          right: 0;
          z-index: -1;
        }
      
        .main-content {
      
          .contentheads {
            .upper-text {
              color: var(--btn-green-bg);
              font-family: "bl";
              font-size: 32px;
              font-style: normal;
              line-height: 140%;
              margin-bottom: 10px;
            }
      
            .lower-text {
              color: var(--heading-text);
              font-family: "f37";
              font-size: 55px;
              font-style: normal;
              line-height: 110%;
              text-transform: uppercase;
      
              span {
                color: var(--heading-text);
                font-family: "bl";
                font-size: 55px;
                font-style: normal;
                line-height: 110%;
                text-transform: uppercase;
              }
            }
      
            .faqspara {
              color: var(--heading-text);
              font-family: 'hregular';
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 120%;
              max-width: 362px;
              width: 100%;
              margin-top: 25px;
            }
          }
        }
      }

    .career-section {

        .parent {
            .single-card {
                border-bottom: 1px solid var(--border2);
             
                .forwidthcard{
                    display: flex;
                    justify-content: space-between;
                    align-items: flex-start;
                    max-width: 1350px;
                    margin: 0 auto;
                    width: 100%;
                    padding: 30px 15px;
                }

                .left-content {
                    max-width: 647px;
                    width: 100%;
                    h6 {
                        color: var(--heading-text);
                        font-family: "hmedium";
                        font-size: 28px;
                        font-style: normal;
                        line-height: 120%;
                        margin-bottom: 20px;
                    }

                    p {
                        color: var(--heading-text);
                        font-family: "hregular";
                        font-size: 16px;
                        font-style: normal;
                        line-height: 130%;
                    }
                }

                .right-btn {
                    a {
                        border-radius: 6px;
                        background: var(--btn-green-bg);
                        height: 42px;
                        padding: 18px 22px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        gap: 11px;
                        color: var(--btn-text-low);
                        font-family: "hregular";
                        font-size: 14px;
                        font-style: normal;
                        line-height: 100%;
                        border: none;
                        transition: 0.3s linear;
                        &:hover {
                            background: var(--btn-green-bg-hover);
                          }
                        
                          &:active {
                            box-shadow: 0px 0px 10px 8px var(--btn-green-bg-active);
                        
                          }
                    }
                }
            }
        }
    }
}

@media (max-width:1024px){
    .wrapper-career .career-section .parent .single-card .forwidthcard{
        flex-direction: column;
        gap: 30px;
    }
    .wrapper-career .career-section .parent .single-card .forwidthcard .right-btn a{
        font-size: 14px;
    }
    .wrapper-career .main-bannernew .main-contentss .faqsheads h3{
        font-size: 30px;
    }
    .wrapper-career .career-section .parent .single-card .forwidthcard .left-content p{
        font-size: 16px;
    }
    .wrapper-career .career-section .parent .single-card .forwidthcard .left-content h6{
        font-size: 20px;
    }
    .wrapper-career .main-careerbanner .leftshadembl {
        display: block !important;
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: -1;
    }
    
    .wrapper-career .main-careerbanner .rightshadembl {
        display: block !important;
        position: absolute;
        bottom: 0;
        right: 0;
        z-index: -1;
    }
    
    .wrapper-career .main-careerbanner .leftshade {
        display: none !important;
    }
    
    .wrapper-career .main-careerbanner .rightshade {
        display: none !important;
    }
    .wrapper-career .main-careerbanner .main-content .contentheads .lower-text{
        font-size: 30px;
        span{
            font-size: 30px;
        }
    }
    .wrapper-career .main-careerbanner .main-content .contentheads .upper-text{
        font-size: 24px;
    }
    .wrapper-career .main-careerbanner{
        padding-bottom: 66px;
    }
}

@media (max-width:400px){
    .wrapper-career .main-careerbanner .main-content .contentheads .lower-text{
        br{
            display: none !important;
        }
    }
}

.location-div{
    svg{
        path{
          fill: var(--btn-text-low);
        }
      }
}