.claim-schedule {
  padding-top: 110px;
  padding-bottom: 120px;

  .main-head {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 16px;
    margin-bottom: 20px;

    a {
      border-radius: 2px;
      background: var(--background3);
      padding: 2px 10px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 6px;
      color: var(--btn-green-bg);
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0.28px;
      text-transform: uppercase;

      svg {
        path {
          fill: var(--btn-green-bg);
        }
      }
    }

    .twice-elem {
      p {
        color: var(--V6-Dark-Theme-Secondary-Text-Color);
        font-family: "f37";
        font-size: 12px;
        font-style: normal;
        line-height: 110%;
        text-transform: uppercase;
        margin-bottom: 10px;
      }

      h6 {
        color: var(--heading-text);
        font-size: 30px;
        font-style: normal;
        font-family: "f37";
        line-height: 24px;
        letter-spacing: 0.52px;
        text-transform: uppercase;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 6px;

        img {
          flex-shrink: 0;
          width: 24px;
          height: 24px;
          border-radius: 50%;
        }
      }
    }
  }

  .inline-elem {
    border-radius: 4px;
    background: var(--d-body-link-bg);
    padding: 20px 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;

    .left-side {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      svg {
        path {
          stroke: var(--svg-fill);
        }
      }
    }

    .right-side {
      .claim-box {
        border-radius: 4px;
        background: var(--claim-bg2);
        padding: 10px 15px;

        p {
          color: var(--V6-Dark-Theme-Primary-Text-Color);
          font-size: 14px;
          font-style: normal;
          font-family: "hregular";
          line-height: 120%;
          margin-bottom: 15px;
          text-align: center;
        }
        .btn-claim {
          border-radius: 4px;
          background: var(--btn-green-bg);
          border: none;
          padding: 15px 35px;
          color: var(--btn-green-text);
          font-size: 14px;
          font-style: normal;
          font-family: "hmedium";
          line-height: 100%;

          &.disabled {
            border-radius: 4px;
            opacity: 0.4;
            background: var(--btn-green-bg);
            color: var(--btn-green-text);
            pointer-events: none;
          }
        }
      }
    }

    .single {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 15px;

      &.single-first {
        border-right: 1px solid var(--border);
        padding-right: 64px;
      }

      &.single-second {
        padding-left: 64px;
      }

      &.single-third {
        padding-left: 64px;
      }

      h6 {
        color: var(--heading-text);
        font-size: 18px;
        font-style: normal;
        font-family: "f37";
        line-height: 120%;
        letter-spacing: -0.26px;
        margin-bottom: 5px;
      }

      p {
        color: var(--heading-text);
        font-size: 14px;
        font-style: normal;
        font-family: "hregular";
        line-height: 120%;
        text-transform: uppercase;
        opacity: 0.6;
      }
    }
  }

  .bottom-content {
    .head-text {
      color: var(--heading-text);
      font-size: 18px;
      font-style: normal;
      font-family: "f37";
      line-height: 24px;
      letter-spacing: 0.44px;
      text-transform: uppercase;
      margin-bottom: 20px;
    }
  }
}

.claiming-schedule-table {
  border-radius: 4px;

  th {
    vertical-align: middle;
    padding: 10px 20px;
    color: #77868b;
    font-size: 14px;
    font-style: normal;
    font-family: "f37";
    letter-spacing: 0.28px;
    text-transform: uppercase;
    white-space: nowrap;
    border: none;

    background: var(--d-body-link-bg);
  }

  td {
    vertical-align: middle;
    padding: 10px 20px;
    color: var(--heading-text);
    font-size: 14px;
    font-style: normal;
    font-family: "hregular";
    letter-spacing: 0.32px;
    white-space: nowrap;
    background-color: transparent;
    border: none;
    border-top: 1px solid var(--border);
    background: var(--d-body-link-bg);
  }

  .twice-text {
    background-color: transparent;

    h6 {
      color: var(--heading-text);
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 21px;
      letter-spacing: 0.32px;
      margin-bottom: 5px;
    }

    p {
      color: var(--heading-text);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 21px;
      letter-spacing: 0.32px;
    }
  }

  .available {
    color: #22c154 !important;
  }

  .timer {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 18px;
    background-color: transparent;

    .single {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 5px;

      h6 {
        border-radius: 4px;
        background: var(--token-card-bg);
        width: 40px;
        height: 40px;
        color: var(--heading-text);
        font-size: 12px;
        font-style: normal;
        font-family: "hregular";
        line-height: 110%;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      p {
        color: var(--heading-text);
        text-align: center;
        font-size: 10px;
        font-style: normal;
        font-family: "hregular";
        line-height: 110%;
      }
    }
  }

  .btn-claim {
    border-radius: 4px;
    background: var(--btn-green-bg);
    padding: 15px;
    color: var(--btn-green-text);
    font-size: 14px;
    font-style: normal;
    font-family: "hmedium";
    line-height: 100%;
    max-width: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    display: block;
    margin: 0 auto;
    text-align: center;
    &.disabled {
      border-radius: 4px;
      opacity: 0.4;
      background: var(--btn-green-bg);
      color: var(--btn-green-text);
      pointer-events: none;
    }
  }
}

@media (max-width: 600px) {
  .claiming-schedule-table {
    display: block !important;
  }

  .claim-schedule .inline-elem {
    flex-direction: column;
  }

  .claim-schedule .inline-elem .single {
    padding: 0;
    border: none;
  }

  .claim-schedule .inline-elem .single h6 {
    text-align: center;
  }

  .claim-schedule .inline-elem .single p {
    text-align: center;
  }
  .claim-schedule .inline-elem .left-side {
    flex-direction: column;
    gap: 25px;
  }
  .claim-schedule .inline-elem .single.single-first {
    padding: 0;
    border: none;
  }
  .claim-schedule .inline-elem .single.single-second {
    padding: 0;
  }
  .claim-schedule .inline-elem .single.single-third {
    padding: 0;
  }
  .claim-schedule .inline-elem {
    gap: 25px;
  }
  .claim-schedule .inline-elem .right-side {
    width: 100%;
  }
  .claim-schedule .inline-elem .right-side .claim-box {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
}
