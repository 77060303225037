.crypto-prices {
    .main-heading {
        background: var(--crypto-heading-bg);
        backdrop-filter: blur(15px);
        padding: 30px 0px;
        border-top: 1px solid var(--border2);
        border-bottom: 1px solid var(--border2);

        .parent-head {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        h5 {
            color: var(--heading-text);
            font-size: 34px;
            font-style: normal;
            line-height: 120%;
        }

        a {
            border-bottom: 1px solid transparent;
            
            color: var(--btn-seeall-text);
            -webkit-text-stroke-width: 0.09073624014854431;
            -webkit-text-stroke-color: var(--btn-seeall-text);
            font-size: 14px;
            font-style: normal;
            font-family: "hregular";
            line-height: 10.948px;
            max-width: 115px;
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            svg{
                path{
                    fill: var(--svg-fill);
                }
            }
            transition: 0.3s linear;
            &:hover{
                border-bottom: 1px solid var(--btn-seeall-border-hover);
                padding-bottom: 13px;
            }
            &:active{
                border-bottom: 1px solid var(--btn-seeall-border-active);
                padding-bottom: 13px;
            }
        }
    }

    .bottom-content {
        border-bottom: 1px solid var(--border2);
        background: rgba(8, 21, 25, 0.03);
        backdrop-filter: blur(5px);

        .parent-card {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;

            .single-crypto {
                background: var(--card-bg);
                backdrop-filter: blur(15px);
                padding: 20px 65px;
                border-right: 1px solid var(--border2);

                &:first-child{
                    padding-left: 0;
                }

                &:last-child {
                    border-right: 1px solid transparent;
                    padding-right: 0;
                }

                .head-text {
                    color: var(--heading-text);
                    font-size: 20px;
                    font-style: normal;
                    font-family: "hmedium";
                    line-height: 120%;
                    margin-bottom: 15px;
                }

                .inline-single-token {
                    display: grid;
                    grid-template-columns: 2fr 1fr 1fr;
                    border-bottom: 1px solid var(--border2);
                    padding: 17px 0;
                    &:last-child{
                        border-bottom: 1px solid transparent;
                    }
                    .left-parent {
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        gap: 10px;

                        .token-img {
                            width: 31px;
                            height: 31px;
                            overflow: hidden;
                            border-radius: 50%;

                            img {
                                width: 100%;
                                height: 100%;
                                object-fit: cover;
                            }
                        }

                        .text {
                            h6 {
                                color: var(--heading-text);
                                font-size: 14px;
                                font-style: normal;
                                font-family: "hmedium";
                                line-height: 120%;
                                margin-bottom: 2px;
                            }

                            p {
                                color: var(--V6-Dark-Theme-Secondary-Text-Color, #939FA3);
                                font-size: 12px;
                                font-style: normal;
                                font-family: "hregular";
                                line-height: 120%;
                            }
                        }
                    }
                    .mid{
                        h6{
                            color: var(--heading-text);
                            font-size: 14px;
                            font-style: normal;
                            font-family: "f37";
                            line-height: 120%; 
                            text-transform: uppercase;
                        }
                    }
                    .right{
                        h6{
                            color: var(--V6-Dark-Theme-Green-Color, #30F1B6);
                            font-size: 14px;
                            font-style: normal;
                            font-family: "hmedium";
                            line-height: 120%; 
                            text-transform: uppercase;
                            text-align: end;
                            &.red-text{
                                color: #F13030;
                            }
                        }
                    }
                }
            }
        }
    }
}

.mobile-tabs-crypto{
    .nav-tabs{
        justify-content: space-between;
        border: none;
        padding-bottom: 2px;
        .nav-item{
            flex: 1;
            .nav-link{
                border: none;
                border-bottom: 1px solid var(--border2);
                padding: 13px 22px;
                color: var(--tab-text);
                font-size: 14px;
                font-style: normal;
                font-family: "hregular";
                line-height: 100%; 
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                white-space: nowrap;
            }
        }
     .nav-item.show .nav-link, .nav-link.active{
            background-color: transparent;
            border-bottom: 1px solid var(--V6-Dark-Theme-Green-Color, #30F1B6);
            color: var(--V6-Dark-Theme-Green-Color, #30F1B6);

        }
    }
    .single-crypto {
        background: var(--body-bg);
        backdrop-filter: blur(15px);
        padding: 20px 65px;
        border-right: 1px solid var(--border2);

        &:first-child{
            padding-left: 0;
        }

        &:last-child {
            border-right: 1px solid transparent;
            padding-right: 0;
        }

        .head-text {
            color: var(--heading-text);
            font-size: 20px;
            font-style: normal;
            font-family: "hmedium";
            line-height: 120%;
            margin-bottom: 15px;
        }

        .inline-single-token {
            display: grid;
            grid-template-columns: 2fr 1fr 1fr;
            border-bottom: 1px solid var(--border2);
            padding: 17px 0;
            &:last-child{
                border-bottom: 1px solid transparent;
            }
            .left-parent {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                gap: 10px;

                .token-img {
                    width: 31px;
                    height: 31px;
                    overflow: hidden;
                    border-radius: 50%;

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }

                .text {
                    h6 {
                        color: var(--heading-text);
                        font-size: 14px;
                        font-style: normal;
                        font-family: "hmedium";
                        line-height: 120%;
                        margin-bottom: 2px;
                    }

                    p {
                        color: var(--V6-Dark-Theme-Secondary-Text-Color, #939FA3);
                        font-size: 12px;
                        font-style: normal;
                        font-family: "hregular";
                        line-height: 120%;
                    }
                }
            }
            .mid{
                h6{
                    color: var(--heading-text);
                    font-size: 14px;
                    font-style: normal;
                    font-family: "f37";
                    line-height: 120%; 
                    text-transform: uppercase;
                }
            }
            .right{
                h6{
                    color: var(--V6-Dark-Theme-Green-Color, #30F1B6);
                    font-size: 14px;
                    font-style: normal;
                    font-family: "hmedium";
                    line-height: 120%; 
                    text-transform: uppercase;
                    text-align: end;
                    &.red-text{
                        color: #F13030;
                    }
                }
            }
        }
    }
}

@media (max-width: 600px){
    .crypto-prices .main-heading h5{
        font-size: 22px;
    }
    .crypto-prices .main-heading a{
        max-width: 71px;
    }
    .crypto-prices .bottom-content .parent-card{
        display: none;
    }
    .mobile-tabs-crypto{
        display: block !important;
    }
}

.light-theme .crypto-prices .bottom-content{
    background: transparent;
}

@media (max-width:390px){
    .crypto-prices .main-heading h5{
        max-width: 200px;
    }
}