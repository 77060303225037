.staking-section {
    padding-bottom: 30px;
    position: relative;
    z-index: 99;

    .shadow-top-right {
        position: absolute;
        top: 0;
        right: 0;
        z-index: -1;
        pointer-events: none;
    }

    .shadow-bottom-left {
        position: absolute;
        bottom: -50%;
        left: 0;
        z-index: -1;
        pointer-events: none;
    }

    .main-heading {
        padding: 60px 0;
        max-width: 524px;
        width: 100%;
        margin: 0 auto;

        h4 {
            color: var(--heading-text);
            text-align: center;
            font-size: 70px;
            font-style: normal;
            line-height: 100%;
            text-transform: capitalize;
            margin-bottom: 20px;
        }

        p {
            color: var(--V6-Dark-Theme-Secondary-Text-Color, #939FA3);
            text-align: center;
            font-size: 14px;
            font-style: normal;
            font-family: "hregular";
            line-height: 140%;
        }
    }

    .parent-card {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;

        .single-card {
            border-top: 1px solid var(--border2);
            border-left: 1px solid var(--border2);
            background: var(--token-card-bg);
            backdrop-filter: blur(20px);
            padding: 25px;
            transition: 0.3s linear;

            &:hover {
                background: rgba(10, 28, 34, 0.97);
                backdrop-filter: blur(20px);
            }

            &:nth-child(4n) {
                border-right: 1px solid var(--border2);
            }

            &:nth-last-child(-n + 4) {
                border-bottom: 1px solid var(--border2);
            }

            &:last-child{
                border-right: 1px solid var(--border2);
            }

            .main-img {
                display: block;
                margin: 0 auto;
                width: 100%;
                margin-bottom: 15px;

                img {
                    display: block;
                    margin: 0 auto;
                }
            }

            .token-info {
                padding-bottom: 20px;
                border-bottom: 1px solid var(--border2);

                h6 {
                    color: var(--heading-text);
                    text-align: center;
                    font-size: 20px;
                    font-style: normal;
                    font-family: "hmedium";
                    line-height: 120%;
                    margin-bottom: 5px;
                }

                p {
                    color: var(--V6-Dark-Theme-Secondary-Text-Color, #939FA3);
                    text-align: center;
                    font-size: 12px;
                    font-style: normal;
                    font-family: "hregular";
                    line-height: 120%;
                }
            }

            .apy-info {
                padding: 20px 0;
                display: grid;
                grid-template-columns: 1fr 1fr;

                .text {
                    p {
                        color: var(--V6-Dark-Theme-Secondary-Text-Color, #939FA3);
                        font-size: 12px;
                        font-style: normal;
                        font-family: "hregular";
                        line-height: 120%;
                        margin-bottom: 10px;
                    }

                    h6 {
                        color: var(--heading-text);
                        font-size: 14px;
                        font-style: normal;
                        font-family: "hmedium";
                        line-height: 120%;
                    }
                }
            }

            .btn-apy {
                border-radius: 6px;
                border: 1px solid var(--btn-buy-border);
                background: var(--btn-buy-bg);
                padding: 18px 22px;
                color: var(--btn-buy-text);
                font-size: 14px;
                font-style: normal;
                font-family: "hregular";
                line-height: 100%;
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                transition: 0.3s linear;
                &:hover{
                    background: var(--btn-buy-bg-hover);
                    border: 1px solid transparent;
                }
                &:active{
                    background: var(--btn-buy-bg-hover);
                    border: 1px solid var(--btn-green-bg);
                }
            }

        }
    }

    .parent-card-mobile {

        .single-card {
            border-top: 1px solid var(--border2);
            border-left: 1px solid var(--border2);
            background: var(--token-card-bg);
            backdrop-filter: blur(20px);
            padding: 25px;
            transition: 0.3s linear;

            &:hover {
                background: rgba(10, 28, 34, 0.97);
                backdrop-filter: blur(20px);
            }

            &:nth-child(4n) {
                border-right: 1px solid var(--border2);
            }

            &:nth-last-child(-n + 4) {
                border-bottom: 1px solid var(--border2);
            }

            .main-img {
                display: block;
                margin: 0 auto;
                width: 100%;
                margin-bottom: 15px;

                img {
                    display: block;
                    margin: 0 auto;
                }
            }

            .token-info {
                padding-bottom: 20px;
                border-bottom: 1px solid var(--border2);

                h6 {
                    color: var(--heading-text);
                    text-align: center;
                    font-size: 20px;
                    font-style: normal;
                    font-family: "hmedium";
                    line-height: 120%;
                    margin-bottom: 5px;
                }

                p {
                    color: var(--V6-Dark-Theme-Secondary-Text-Color, #939FA3);
                    text-align: center;
                    font-size: 12px;
                    font-style: normal;
                    font-family: "hregular";
                    line-height: 120%;
                }
            }

            .apy-info {
                padding: 20px 0;
                display: grid;
                grid-template-columns: 1fr 1fr;

                .text {
                    p {
                        color: var(--V6-Dark-Theme-Secondary-Text-Color, #939FA3);
                        font-size: 12px;
                        font-style: normal;
                        font-family: "hregular";
                        line-height: 120%;
                        margin-bottom: 10px;
                    }

                    h6 {
                        color: var(--heading-text);
                        font-size: 14px;
                        font-style: normal;
                        font-family: "hmedium";
                        line-height: 120%;
                    }
                }
            }

            .btn-apy {
                border-radius: 6px;
                border: 1px solid var(--btn-buy-border);
                background: var(--btn-buy-bg);
                padding: 18px 22px;
                color: var(--btn-buy-text);
                font-size: 14px;
                font-style: normal;
                font-family: "hregular";
                line-height: 100%;
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
            }

        }
    }

    .btn-seeall {
        border-bottom: 1px solid transparent;
        max-width: 116px;
        width: 100%;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 13px;
        color: var(--btn-seeall-text);
        -webkit-text-stroke-width: 0.09073624014854431;
        -webkit-text-stroke-color: var(--btn-seeall-text);
        font-size: 14px;
        font-style: normal;
        font-family: "hregular";
        line-height: 10.948px;
        margin-top: 30px;

        svg {
            path {
                fill: var(--svg-fill);
            }
        }
        transition: 0.3s linear;
        &:hover{
            border-bottom: 1px solid var(--btn-seeall-border-hover);
        }
        &:active{
            border-bottom: 1px solid var(--btn-seeall-border-active);
        }
    }
}

@media (max-width:600px) {
    .staking-section .main-heading h4 {
        font-size: 34px;

        br {
            display: none;
        }
    }

    .staking-section .parent-card {
        display: none;
    }

    .parent-card-mobile {
        display: block !important;
    }
}

.light-theme .staking-section .parent-card .single-card:hover {
    background: transparent;
}

.light-theme .staking-section .parent-card-mobile .single-card:hover{
    background-color: transparent !important;
}