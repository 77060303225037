.wrapper-footer{
    position: relative;
    z-index: 99;
    .footer-bg{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        z-index: 5;
        pointer-events: none;
    }
}

.main-footer {
    position: relative;
    border-bottom: 1px solid var(--border2);
    padding-top: 42px;
    padding-bottom: 30px;
    z-index: 10;

   

    .parent {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;

        .main-logo {
            .contact-info {
                color: var(--V6-Dark-Theme-Secondary-Text-Color, #939FA3);
                font-size: 14px;
                font-style: normal;
                font-family: "hregular";
                line-height: 140%;
                max-width: 360px;
                margin-top: 18px;
            }
            .social-links{
                display: flex;
                align-items: center;
                justify-content: flex-start;
                gap: 10px;
                margin-top: 20px;
                svg {
                    transition: 0.3s linear;
                    path {
                        transition: 0.3s linear;
                    }
                    &:hover{
                        path {
                            fill: var(--btn-green-bg);
                        }
                    }
                }

               
            }
        }

        .right-navs {
            display: flex;
            justify-content: flex-end;
            align-items: flex-start;
            gap: 75px;

            .main-nav {
                h6 {
                    color: var(--heading-text);
                    font-size: 16px;
                    font-style: normal;
                    line-height: 100%;
                    text-transform: capitalize;
                    margin-bottom: 20px;
                }

                a {
                    color: var(--V6-Dark-Theme-Secondary-Text-Color1);
                    font-size: 14px;
                    font-style: normal;
                    font-family: "hmedium";
                    line-height: 100%;
                    margin-top: 12px;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    gap: 8px;
                    transition: 0.3s linear;
                    text-align: start;

                    svg {
                        transition: 0.3s linear;

                        path {
                            transition: 0.3s linear;
                        }
                    }

                    &:hover {
                        color: var(--heading-text);

                        svg {
                            path {
                                fill: #53FFEA;
                            }
                        }
                    }

                }

                .social-links {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    gap: 16px;
                    // margin-top: 10px;
                }
            }
        }
    }

    .comingsoon {
        border-radius: 30px;
        border: 1px solid #53FFEA;
        padding: 4px 6px;
        color: #FFF;
        font-size: 9px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
        text-transform: capitalize;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.second-footer {
    border-top: 1px solid var(--border2);
    padding: 33px 0;
    position: relative;
    z-index: 99;

    .parent {
        display: flex;
        justify-content: space-between;
        align-items: center;

        h6 {
            color: var(--V6-Dark-Theme-Secondary-Text-Color);
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 120%;

            span {
                color: #fff;
            }
        }



        .twice-items {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 15px;

            .inside-links {
                display: flex;
                align-items: center;
                gap: 15px;
            }
        }

        a {
            color: var(--V6-Dark-Theme-Secondary-Text-Color, #939FA3) !important;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 120%;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            gap: 5px;
        }
    }
}




@media (max-width:1024px) {
    .main-footer .parent {
        flex-direction: column;
        align-items: center;
        gap: 40px;
    }

    .main-footer .parent .right-navs {
        justify-content: flex-start;
        align-items: flex-start;
        gap: 37px 0;
        flex-wrap: wrap;
    }

    .main-footer .parent .right-navs .main-nav{
        width: 48%;
    }


    .d-noneonmobile {
        display: none !important;
    }

    .second-footer .parent {
        justify-content: center;
        flex-direction: column-reverse;
        gap: 15px;
    }
    .wrapper-footer .footer-bg{
        object-position: left;
    }
    .main-footer .parent{
        align-items: flex-start;
    }
    .main-footer .parent .main-logo{
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
    }
    .main-footer .parent .main-logo .contact-info{
        text-align: start;
    }
    .main-footer .parent .right-navs{
        width: 100%;
    }
    .main-footer .parent .right-navs .main-nav h6{
        text-align: start;
    }
    .main-footer .parent .right-navs .main-nav{
        &:first-child{
            order: 2;
        }
    }
}

.light-theme .main-footer .parent .main-logo .social-links.show-in-light-theme{
    display: flex !important;
}
