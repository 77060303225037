.main-signupbanner {
  height: 400px;
  position: relative;
  display: flex;
  align-items: center;

  .leftshade {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: -1;
  }

  .rightshade {
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: -1;
  }

  .main-content {

    .contentheads {
      .upper-text {
        color: var(--btn-green-bg);
        font-family: "bl";
        font-size: 32px;
        font-style: normal;
        line-height: 140%;
        margin-bottom: 10px;
      }

      .lower-text {
        color: var(--heading-text);
        font-family: "f37";
        font-size: 55px;
        font-style: normal;
        line-height: 110%;
        text-transform: uppercase;

        span {
          color: var(--heading-text);
          font-family: "bl";
          font-size: 55px;
          font-style: normal;
          line-height: 110%;
          text-transform: uppercase;
        }
      }

      .faqspara {
        color: var(--heading-text);
        font-family: 'hregular';
        font-size: 14px;
        font-style: normal;
        line-height: 120%;
        max-width: 362px;
        width: 100%;
        margin-top: 25px;
      }
    }
  }
}

.wrapper-banner {
  position: relative;

  .left-nav {
    position: fixed;
    top: 0;
    left: 0;
    max-width: 76px;
    width: 100%;
    height: 100%;
    border-right: 1px solid rgba(255, 255, 255, 0.05);
    background: rgba(11, 11, 11, 1);
    backdrop-filter: blur(40px);
    z-index: 999;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    overflow: hidden;

    a {
      width: 77px;
      height: 76px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .logo-mini {
      border-bottom: 1px solid rgba(255, 255, 255, 0.05);
    }

    .arrow-down {
      border-top: 1px solid rgba(255, 255, 255, 0.05);
    }
  }

  .right-nav {
    position: fixed;
    top: 0;
    right: 0;
    max-width: 76px;
    width: 100%;
    height: 100%;
    border-left: 1px solid rgba(255, 255, 255, 0.05);
    background: rgba(11, 11, 11, 1);
    backdrop-filter: blur(40px);
    z-index: 999;
    overflow: hidden;
    transition: opacity 0.3s ease;
  }

  // .onscrollbehaviour{
  //     position: fixed;
  //     top: 50%;
  //     right: 0;
  //     transform: translate(0%, -50%);
  //     max-width: 76px;
  //     width: 100%;
  //     height: auto;
  //     border-left: 1px solid rgba(255, 255, 255, 0.05);
  //     background: rgba(11, 11, 11, 0.30);
  //     backdrop-filter: blur(40px);
  //     z-index: 999;
  //     overflow: hidden;
  //     transition: opacity 0.3s ease;
  // }
  .hidden-nav {
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.3s ease;
  }
}

@media (max-width: 1024px) {
  .wrapper-banner .left-nav {
    display: none;
  }

  .wrapper-banner .right-nav {
    display: none;
  }

  .main-bannernew .main-content .main-logo {
    display: none;
  }

  .main-bannernew .main-content h4 {
    font-size: 38px;
  }

  .main-bannernew .main-content p {
    font-size: 16px;
  }

  .main-bannernew .main-content .twice-btns {
    flex-direction: column;
    width: 100%;
  }

  .main-bannernew .main-content .twice-btns a {
    max-width: 100%;
  }
}

@media (max-width: 991px) {
  .main-signupbanner .leftshadembl {
    display: block !important;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: -1;
  }

  .main-signupbanner .rightshadembl {
    display: block !important;
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: -1;
  }

  .main-signupbanner .leftshade {
    display: none !important;
  }

  .main-signupbanner .rightshade {
    display: none !important;
  }

  .main-signupbanner .main-content .contentheads .lower-text{
    font-size: 50px;
    span{
      font-size: 50px;
    }
  }
}

@media (max-width: 600px) {
  .main-bannernew .main-contentss .faqsheads p {
    font-size: 16px;
  }

  .main-bannernew .main-contentss .faqsheads h3 {
    font-size: 30px;
  }

  .mainfaqs .innerfaq {
    padding-top: 20px;
  }
}