.wrapper-blog {
  .main-navbar {
    position: static !important;
    transform: unset !important;
    width: 100%;
  }
}

.blog-banner {
  position: relative;
  z-index: 99;
  padding: 60px 0;
  overflow: hidden;

  .banner-bg {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    pointer-events: none;
    width: 100%;
    height: 100%;
    object-fit: cover;

  }

  .latest-article {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    gap: 106px;

    .article-content {
      max-width: 465px;
      width: 100%;

      p {
        color: var(--btn-green-bg);
        font-size: 12px;
        font-style: normal;
        font-family: "hmedium";
        line-height: 120%;
        text-transform: uppercase;
        margin-bottom: 15px;
      }

      h6 {
        color: var(--heading-text);
        font-size: 30px;
        font-style: normal;
        font-family: "hregular";
        line-height: 130%;
      }
    }

    .main-artcile-img {
      border-radius: 3.471px;
      width: 100%;
      // height: 420px;
      height: 366px;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

}

.blog-detail-banner {
  min-height: 297px;
  display: flex;
  justify-content: center;
  align-items: center;
  h6 {
    color: var(--heading-text);
    font-size: 50px;
    font-style: normal;
    font-family: "hregular";
    line-height: 130%;
  }
}

.blog-section {
  padding-bottom: 80px;

  .parent {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;



    .single-head {
      color: #0b0b0b;
      font-family: "Chakra Petch";
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 120%;
    }

    a{
      &:nth-child(3n){
         border-right: 1px solid var(--border2);
      }

    }

    .single-card {
      border-left: 1px solid var(--border2);
      border-top: 1px solid var(--border2);
      backdrop-filter: blur(20px);
      padding: 25px 60px;

      // &:last-child {
      //   border-right: 1px solid var(--border2);
      // }

      

      &:nth-last-child(-n + 3) {
        border-bottom: 1px solid var(--border2);
      }

      &:nth-child(3n) {
        border-right: 1px solid var(--border2);
      }

      
      .main-img {
        border-radius: 5.177px;
        border: 1.294px solid var(--border2);
        background: #D5DFE2;
        // height: 204px;
        height: 158px;
        width: 100%;
        overflow: hidden;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .bottom-text {
        p {
          color: var(--V6-Dark-Theme-Green-Color, #30F1B6);
          font-size: 12px;
          font-style: normal;
          font-family: "hmedium";
          line-height: 120%;
          text-transform: uppercase;
          margin: 12px 0;
        }

        h6 {
          color: var(--heading-text);
          font-size: 18px;
          font-style: normal;
          font-family: "hregular";
          line-height: 130%;

          display: -webkit-box;
          -webkit-line-clamp: 2; 
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          max-height: calc(1.5em * 2);
          line-height: 1.5em; 
        }
      }
    }
  }
}

.blog-detail-wrapper {
  .faqsheads {
    a {
      color: #fff;
      font-family: "Chakra Petch";
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 100%;
      text-transform: uppercase;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 5px;
      margin-bottom: 30px;
    }
  }
}

.blog-detail {
  padding-top: 41px;
  padding-bottom: 80px;

  .parent {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 91px;

    .left-side {
      max-width: 480px;
      width: 100%;
      position: sticky;
      top: 15px;

      .main-heading {
        h6 {
          color: var(--V6-Dark-Theme-Secondary-Text-Color, #939FA3);
          font-size: 12px;
          font-style: normal;
          font-family: "hmedium";
          line-height: 120%; 
          text-transform: uppercase;
          margin-bottom: 20px;
        }
      }

      .social-links {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 10px;
        svg{
          path{
            fill: var(--text-color1);
            transition: 0.3s linear;
          }
          &:hover{
            path{
              fill: var(--btn-green-bg);
            }
          }
        }

      }
    }

    .right-side {
      flex: 1;

      .main-content {

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
          color: var(--heading-text);
          font-style: normal;
          line-height: 120%;
          margin: 15px 0;
          font-family: "f37" !important;
          font-size: 26px;
        }

        h6 {
          color: var(--heading-text);
          font-style: normal;
          font-family: "f37" !important;
          line-height: 120%;
          margin: 15px 0;
        }

        p {
          color: var(--V6-Dark-Theme-Secondary-Text-Color, #939FA3);
          font-size: 18px;
          font-style: normal;
          font-family: "hregular";
          line-height: 120%;
          margin: 15px 0;
        }

        a{
          color: var(--btn-green-bg) !important;
        }


        img {
          max-width: 100%;
          object-fit: contain;
          height: auto;
          padding: 15px 0;
          margin: 0 auto;
          display: block;
        }

        iframe {
          width: 100% !important;
          height: 500px !important;
          padding: 40px 0;
          object-fit: contain;
        }

        ul li {
          margin: 15px 0;
          list-style-type: disc !important;
          margin-left: 25px;
          color: var(--V6-Dark-Theme-Secondary-Text-Color, #939FA3);
          font-size: 18px;
          font-style: normal;
          font-family: "hregular";
          line-height: 120%;
        }

        ol li {
          color: var(--V6-Dark-Theme-Secondary-Text-Color, #939FA3);
          font-size: 18px;
          font-style: normal;
          font-family: "hregular";
          line-height: 120%;
          margin: 15px 0;
        }

        div {
          color: #939FA3;
        }
      }
    }

   
  }
}

@media (max-width: 600px) {
  .blog-section .parent {
    grid-template-columns: 1fr;
  }

  .blog-detail .parent {
    flex-direction: column;
    gap: 50px;
  }

  .blog-detail .parent .right-side {
    position: static;
  }
}


// new style media query.........................

@media (max-width:600px){
  .blog-banner .latest-article{
    flex-direction: column-reverse;
    gap: 30px;
  }
  .blog-banner .latest-article .main-artcile-img{
    height: 243px;
  }
  .blog-banner .banner-bg{
    display: none;
  }
  .blog-banner .banner-bg-mbl{
    display: block !important;
  }
  .blog-banner .latest-article .article-content h6{
    font-size: 26px;
  }
  .blog-section .custom-container{
    padding: 0 !important;
  }
  .blog-section .parent .single-card{
    padding: 30px 20px;
  }
  .blog-detail .parent .left-side{
    position: static;
  }
}


.light-theme .blog-section .parent .single-card{
  transition: 0.3s linear;
  &:hover{
    background: rgba(195, 207, 210, 0.97);
  }
}