.media-section {
    .first-para {
        h2 {
            color: var(--heading-text);
            font-size: 20px;
            font-style: normal;
            line-height: 100%;
            text-transform: uppercase;
            margin-bottom: 20px;
        }
    }

    .bottom-gallery {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 10px;

        .single-gallery {
            border-radius: 4px;
            border: 1px solid var(--border2);
            background: var(--background5);
            position: relative;
            overflow: hidden;
            height: 170px;

            img {
                &:first-child {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }

        .play-btn {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }

    }
}


@media (max-width:600px){
    .media-section .bottom-gallery{
        grid-template-columns: 1fr;
    }
}