.main-navbar {
  position: absolute;
  top: 0px;
  left: 0;
  width: 100%;
  z-index: 99999;
  height: 70px;
  border-bottom: 1px solid var(--border);
  background: var(--nav-bg);
  backdrop-filter: blur(15px);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  .twice-onmobile {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 5px;

    .lang-drop {
      padding: 0;

      .dropdown-toggle {
        background-color: transparent;
        border: none;
        display: flex;
        align-items: center;
        gap: 6px;
        color: var(--V6-Dark-Theme-Primary-Text-Color);
        font-size: 14px;
        font-style: normal;
        font-family: "hmedium";
        line-height: 100%;
        padding: 6px;
        border-radius: 4px;

        &[aria-expanded="true"] {
          border-radius: 4px;
          background: var(--d-toggle-bg);
          padding: 6px;
        }

        &::after {
          display: none;
        }
      }

      .dropdown-menu {
        border-radius: 4px;
        border: 1px solid var(--border);
        padding: 0;
        left: unset !important;
        right: 0 !important;
        overflow: hidden;
        margin-top: 10px;

        a {
          border-bottom: 1px solid var(--border1);
          background: var(--d-body-link-bg);
          padding: 12px;
          color: var(--d-body-link-text);
          font-size: 14px;
          font-style: normal;
          font-family: "hregular";
          line-height: 100%;
          transition: 0.3s linear;

          &:hover {
            background: var(--d-body-link-bg-hov);
            color: var(--d-body-link-text-hov);
          }
        }
      }
    }
  }

  .navbar-toggler {
    box-shadow: none;

    &[type="button"] {
      svg {
        path {
          fill: var(--svg-fill);
        }
      }
    }
  }

  .navbar-brand {
    margin-right: 73px;
  }

  .navbar-nav {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 32px;
    height: 100%;

    .nav-item {
      position: relative;
      height: 100%;
      display: flex;
      align-items: center;

      .nav-link {
        color: var(--nav-link);
        font-size: 14px;
        font-style: normal;
        font-family: "hregular";
        line-height: 100%;
        padding: 0;
        border-bottom: 2px solid transparent;

        &.active {
          color: var(--nav-active);

          span {
            color: var(--nav-active);
            font-family: "hmedium";
            border-bottom: 2px solid var(--nav-active);
            height: 2px;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            bottom: 0;
          }
        }
      }
    }
  }

  .right-side {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 15px;

    .lang-drop {
      .dropdown-toggle {
        background-color: transparent;
        border: none;
        display: flex;
        align-items: center;
        gap: 6px;
        color: var(--V6-Dark-Theme-Primary-Text-Color);
        font-size: 14px;
        font-style: normal;
        font-family: "hmedium";
        line-height: 100%;
        padding: 6px;
        border-radius: 4px;

        &[aria-expanded="true"] {
          border-radius: 4px;
          background: var(--d-toggle-bg);
          padding: 6px;
        }

        &::after {
          display: none;
        }
      }

      .dropdown-menu {
        border-radius: 4px;
        border: 1px solid var(--border);
        padding: 0;
        left: unset !important;
        right: 0 !important;
        overflow: hidden;
        margin-top: 10px;

        a {
          border-bottom: 1px solid var(--border1);
          background: var(--d-body-link-bg);
          padding: 12px;
          color: var(--d-body-link-text);
          font-size: 14px;
          font-style: normal;
          font-family: "hregular";
          line-height: 100%;
          transition: 0.3s linear;

          &:hover {
            background: var(--d-body-link-bg-hov);
            color: var(--d-body-link-text-hov);
          }
        }
      }
    }

    .connect-btn {
      border-radius: 4px;
      background: var(--btn-green-bg);
      padding: 15px 22px;
      color: var(--btn-green-text);
      font-size: 14px;
      font-style: normal;
      font-family: "hregular";
      line-height: 100%;
    }

    .nav-dropmenu {
      .dropdown-toggle {
        border-radius: 6px;
        border: 1px solid var(--btn-green-bg);
        background: var(--menu-bg-color);
        padding: 15px 16px;
        color: var(--btn-green-bg);
        font-size: 14px;
        font-style: normal;
        font-family: "hmedium";
        line-height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 12px;

        &::after {
          display: none;
        }

        &[aria-expanded="true"] {
          svg {
            transform: rotate(180deg);
          }
        }

        svg {
          path {
            fill: var(--btn-green-bg);
          }
        }
      }

      .dropdown-menu {
        padding: 0;
        border-radius: 4px;
        border: none;
        border: 1px solid var(--border1);
        overflow: hidden;

        a {
          border: none;
          border-bottom: 1px solid var(--border1);
          background: var(--background3);
          padding: 14px 16px;
          color: var(--text-color);
          font-size: 14px;
          font-style: normal;
          font-family: "hregular";
          line-height: 100%;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          gap: 10px;

          .icon-color-both {
            path {
              fill: var(--btn-green-bg);
            }
          }

          .icon-color-both1 {
            path {
              stroke: var(--btn-green-bg);
            }
          }
        }
      }
    }
  }
}

.nav-offcanvas-mobile {
  height: 100% !important;
  background: var(--body-bg);
  z-index: 999999 !important;

  .main-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid var(--border);
    background: var(--nav-bg);
    backdrop-filter: blur(15px);
    padding: 19px 20px;
  }

  .offcanvas-body {
    padding: 0;
  }

  .bottom-body {
    .upper-links {
      a {
        display: block;
        border-bottom: 1px solid var(--border);
        padding: 22px 0px;
        color: var(--nav-link);
        font-size: 18px;
        font-style: normal;
        font-family: "hregular";
        line-height: 100%;
        padding: 19px 20px;
        transition: 0.3s linear;

        &:hover {
          background: rgba(255, 255, 255, 0.02);
        }
      }
    }

    .twice-items {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid var(--border);
      padding: 19px 20px;

      p {
        color: var(--nav-link);
        font-size: 18px;
        font-style: normal;
        font-family: "hregular";
        line-height: 100%;
      }

      // .connect-btn {
      //   color: #8A989D;
      //   font-size: 18px;
      //   font-style: normal;
      //   font-family: "hregular";
      //   line-height: 100%;
      // }
      // .nav-dropmenu {
      //   width: 100%;
      //   .dropdown-toggle {
      //     // border-radius: 6px;
      //     // border: 1px solid var(--btn-green-bg);
      //     // background: var(--menu-bg-color);
      //     // padding: 15px 16px;
      //     color: var(--btn-green-bg);
      //     font-size: 18px;
      //     font-style: normal;
      //     font-family: "hmedium";
      //     line-height: 100%;
      //     display: flex;
      //     justify-content: space-between;
      //     align-items: center;
      //     gap: 12px;
      //     background-color: transparent;
      //     border: none;
      //     width: 100%;

      //     &:focus, &:hover{
      //       background-color: transparent !important;
      //     }

      //     &::after {
      //       display: none;
      //     }

      //     &[aria-expanded="true"] {
      //       svg {
      //         transform: rotate(180deg);
      //       }
      //     }

      //     svg {
      //       path {
      //         fill: var(--btn-green-bg);
      //       }
      //     }
      //   }

      //   .dropdown-menu {
      //     padding: 0;
      //     border-radius: 4px;
      //     border: none;
      //     border: 1px solid var(--border1);
      //     overflow: hidden;
      //     position: relative;
      //     width: 100%;
      //     transform: unset !important;
      //     margin-top: 20px !important;

      //     a {
      //       border: none;
      //       border-bottom: 1px solid var(--border1);
      //       background: var(--background3);
      //       padding: 14px 16px;
      //       color: var(--text-color);
      //       font-size: 16px;
      //       font-style: normal;
      //       font-family: "hregular";
      //       line-height: 100%;
      //       display: flex;
      //       justify-content: flex-start;
      //       align-items: center;
      //       gap: 10px;

      //       .icon-color-both {
      //         path {
      //           fill: var(--btn-green-bg);
      //         }
      //       }
      //     }
      //   }
      // }
    }
  }

  .connect-btn {
    border-radius: 0px;
    background: var(--btn-green-bg);
    padding: 15px 22px;
    color: var(--btn-green-text);
    font-size: 14px;
    font-style: normal;
    font-family: "hregular";
    line-height: 100%;
    width: 100%;
    margin: 0 auto;
    position: fixed;
    bottom: 0px;
    left: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .disconnect-btn {
    border-radius: 0px;
    background: rgba(232, 53, 53, 0.2);
    padding: 15px 22px;
    color: #fc4f52;
    font-size: 14px;
    font-style: normal;
    font-family: "hregular";
    line-height: 100%;
    width: 100%;
    margin: 0 auto;
    position: fixed;
    bottom: 0px;
    left: 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid #fc4f52;
  }

  .mypurchases {
    .icon-color-both {
      path {
        fill: var(--btn-green-bg);
      }
    }
  }
}

.nav-offcanvas-mobile .bottom-body .upper-links a {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
}

.toggle-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border-radius: 100px;
  background: #d1dbdf;
  padding: 3px;
  max-width: 55px;
}

.theme-toggle {
  display: none;
}

.toggle-label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  cursor: pointer;
  position: relative;
}

.icon {
  font-size: 24px;
  transition: transform 0.3s, color 0.3s;
}

.sun,
.moon {
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  transition: background-color 0.3s;
}

.theme-toggle:not(:checked)+.toggle-label .sun {
  border-radius: 25.778px;
  background: #02171d;
  box-shadow: 0px 0px 10px 0px rgba(2, 23, 29, 0.6);
}

.theme-toggle:checked+.toggle-label .moon {
  border-radius: 25.778px;
  background: #02171d;
  box-shadow: 0px 0px 10px 0px rgba(2, 23, 29, 0.6);
}

.theme-toggle:checked~body {
  background-color: #333333;
  color: #ffffff;
}

.light-theme .main-navbar {
  background-color: transparent;
}

.light-theme .nav-offcanvas-mobile .main-header {
  background: transparent;
}

.wallet-modal {
  .parent {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;

    a {
      background: var(--input-bg);
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      gap: 12px;
      color: var(--text-color);
      text-align: center;
      font-size: 18px;
      font-style: normal;
      font-family: "hmedium";
      line-height: 100%;
      padding: 50px 20px;
      transition: 0.3s linear;

      &:hover {
        background: var(--wallet-bg-hover);
        color: var(--text-color);
      }
    }
  }
}

@media (max-width: 600px) {
  .wallet-modal .parent {
    grid-template-columns: 1fr;
  }

  .metamask-hideonmobile {
    display: none !important;
  }
}


.withlabelnav{
  position: relative;
  margin-right: 40px;
  .new-label {
    border-radius: 50px;
    border: 1px solid var(--btn-green-bg);
    background: rgba(48, 241, 182, 0.12);
    padding: 4px 6px;
    color: var(--btn-green-bg);
    font-size: 10px;
    font-style: normal;
    font-family: "hregular";
    line-height: 100%;
    display: inline-block;
    margin-left: 6px;
    position: absolute;
    top: 50%;
    right: -40px;
    transform: translate(0%, -50%);
  }
}


.new-labelmbl{
  border-radius: 50px;
    border: 1px solid var(--btn-green-bg);
    background: rgba(48, 241, 182, 0.12);
    padding: 4px 6px;
    color: var(--btn-green-bg);
    font-size: 10px;
    font-style: normal;
    font-family: "hregular";
    line-height: 100%;
    display: inline-block;
    margin-left: 6px;
}