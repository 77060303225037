.news-section {
    border-top: 1px solid var(--border2);
    padding-bottom: 50px;

    .main-heading {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 60px 0;

        h4 {
            color: var(--heading-text);
            font-size: 70px;
            font-style: normal;
            line-height: 100%;
            text-transform: capitalize;
        }

        a {
            border-bottom: 1px solid transparent;
           
            color: var(--btn-seeall-text);
            -webkit-text-stroke-width: 0.09073624014854431;
            -webkit-text-stroke-color: var(--btn-seeall-text);
            font-size: 14px;
            font-style: normal;
            font-family: "hregular";
            line-height: 10.948px;
            max-width: 115px;
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;

            svg {
                path {
                    fill: var(--svg-fill);
                }
            }
            transition: 0.3s linear;
            &:hover{
                border-bottom: 1px solid var(--btn-seeall-border-hover);
                padding-bottom: 13px;
            }
            &:active{
                border-bottom: 1px solid var(--btn-seeall-border-active);
                padding-bottom: 13px;
            }
        }
    }

    .bottom-cards {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;

        .single-card {
            border-top: 1px solid var(--border2);
            border-bottom: 1px solid var(--border2);
            border-left: 1px solid var(--border2);
            background: var(--card-bg2);
            backdrop-filter: blur(20px);
            padding: 25px;
            transition: 0.3s linear;

            &:hover {
                background: rgba(10, 28, 34, 0.95);
                backdrop-filter: blur(20px);
            }

            &:last-child {
                border-right: 1px solid var(--border2);
            }

            .main-img {
                width: 100%;
                border: 1px solid #24353A;
                background: #CFCFCF;
                // height: 158px;
                height: 140px;
                overflow: hidden;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }

            .blog-title {
                color: var(--V6-Dark-Theme-Green-Color, #30F1B6);
                font-size: 10px;
                font-style: normal;
                font-family: "hregular";
                line-height: 120%;
                margin: 15px 0;
            }

            h6 {
                color: var(--heading-text);
                font-size: 16px;
                font-style: normal;
                font-family: "hregular";
                line-height: 120%;
                display: -webkit-box;
                -webkit-line-clamp: 2; 
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
                max-height: calc(1.5em * 2);
                line-height: 1.5em; 
                min-height: 50px;
                margin-bottom: 20px;
            }

            .twice-text {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding-top: 20px;
                padding-top: 20px;
                border-top: 1px solid var(--border2);

                p {
                    color: var(--heading-text);
                    font-size: 12px;
                    font-style: normal;
                    font-family: "hregular";
                    line-height: 120%;
                }

                a {
                    color: var(--V6-Dark-Theme-Green-Color, #30F1B6);
                    font-size: 12px;
                    font-style: normal;
                    font-family: "hregular";
                    line-height: 120%;
                }
            }
        }
    }

    .bottom-cards-mobile {
        .single-card {
            border-top: 1px solid var(--border2);
            border-bottom: 1px solid var(--border2);
            border-left: 1px solid var(--border2);
            background: var(--card-bg2);
            backdrop-filter: blur(20px);
            padding: 25px;
            transition: 0.3s linear;

            &:hover {
                background: rgba(10, 28, 34, 0.95);
                backdrop-filter: blur(20px);
            }

            &:last-child {
                border-right: 1px solid var(--border2);
            }

            .main-img {
                width: 100%;
                border: 1px solid #24353A;
                background: #CFCFCF;
                height: 158px;
                overflow: hidden;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }

            .blog-title {
                color: var(--V6-Dark-Theme-Green-Color, #30F1B6);
                font-size: 10px;
                font-style: normal;
                font-family: "hregular";
                line-height: 120%;
                margin: 15px 0;
            }

            h6 {
                color: var(--heading-text);
                font-size: 16px;
                font-style: normal;
                font-family: "hregular";
                line-height: 120%;
                padding-bottom: 20px;
                border-bottom: 1px solid var(--border2);
            }

            .twice-text {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding-top: 20px;

                p {
                    color: var(--heading-text);
                    font-size: 12px;
                    font-style: normal;
                    font-family: "hregular";
                    line-height: 120%;
                }

                a {
                    color: var(--V6-Dark-Theme-Green-Color, #30F1B6);
                    font-size: 12px;
                    font-style: normal;
                    font-family: "hregular";
                    line-height: 120%;
                }
            }
        }
    }
}

@media (max-width:600px) {
    .news-section .main-heading h4 {
        font-size: 34px;
    }

    .news-section .bottom-cards {
        display: none;
    }

    .bottom-cards-mobile {
        display: block !important;
    }
    .news-section{
        padding-bottom: 0;
    }
}


.light-theme .news-section .bottom-cards .single-card:hover{
    background: transparent;
}

.light-theme .news-section .bottom-cards-mobile .single-card:hover{
    background-color: transparent !important;
}

.descriptionblog{
      display: -webkit-box;
    -webkit-line-clamp: 2;
    /* Restrict to 3 lines */
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    max-height: calc(1.2em * 2);
    /* Adjust for line height */
    line-height: 1.2em;
    padding: 0;
    /* Line height */
}