.nodesminers {
    padding: 60px 0;
    position: relative;


    .main-banner-video {
        position: absolute;
        mix-blend-mode: screen;
        top: 50%;
        right: 0;
        transform: translate(35%, -42%);
        pointer-events: none;
        width: 100%;
        padding: 15.25vw;

    }

    .main-content {
        max-width: 565px;
        width: 100%;

        h5 {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 15px;
            color: var(--btn-green-bg);
            font-family: "f37";
            font-size: 30px;
            font-style: normal;
            font-weight: 500;
            line-height: 100%;
            text-transform: capitalize;
            margin-bottom: 30px;
        }

        h4 {
            color: var(--heading-text);
            font-size: 60px;
            font-style: normal;
            font-weight: 400;
            line-height: 100%;
            text-transform: capitalize;
        }

        p {
            margin: 40px 0;
            color: var(--V6-Dark-Theme-Secondary-Text-Color);
            font-size: 20px;
            font-style: normal;
            font-family: "hregular";
            line-height: 140%;
        }

        .twice-btns {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 15px;

            a {
                padding: 18px 22px;
                min-width: 150px;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
    }
}

.bottom-cards-nodes {
    padding-bottom: 50px;
    border-bottom: 1px solid var(--border2);
    position: relative;

    .parent {
        display: grid;
        grid-template-columns: repeat(4, auto);
        border: 1px solid var(--border2);

        .single-card {
            border-right: 1px solid var(--border2);
            background: var(--token-card-bg);
            backdrop-filter: blur(20px);
            padding: 25px;
            transition: 0.3s linear;

            svg {
                path {
                    fill: var(--btn-green-bg);
                }
            }

            &:hover {
                background: var(--btn-buy-bg-hover);
                backdrop-filter: blur(20px);
            }

            &:first-child {
                border-left: 1px solid var(--border2);
            }

            &:last-child {
                border-right: 1px solid transparent;
            }

            h6 {
                color: var(--heading-text);
                font-size: 20px;
                font-style: normal;
                font-family: "hmedium";
                line-height: 120%;
                margin-top: 30px;
                margin-bottom: 15px;
            }

            p {
                color: var(--V6-Dark-Theme-Secondary-Text-Color);
                font-size: 16px;
                font-style: normal;
                font-family: "hlight";
                line-height: 120%;
            }
        }
    }
}

.valuespermonth {
    border-radius: 4px;
    border: 1px solid var(--border2);
    background: var(--btn-buy-bg);
    overflow: hidden;
    margin-top: 20px;

    .firstrow {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid var(--border2);

        &::-webkit-scrollbar {
            display: none;
        }

        &:last-child {
            border-bottom: 1px solid transparent;
        }

        h6 {
            border-right: 1px solid var(--border2);
            background: var(--btn-buy-bg);
            padding: 15px 20px;
            color: var(--V6-Dark-Theme-Primary-Text-Color);
            font-size: 16px;
            font-style: normal;
            font-family: "hbold";
            line-height: 21px;
            letter-spacing: 0.32px;
            min-width: 200px;
            height: 78px;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
        }

        p {
            border-right: 1px solid var(--border2);
            background: var(--btn-buy-bg);
            padding: 10px 20px;
            color: var(--V6-Dark-Theme-Primary-Text-Color);
            font-size: 16px;
            font-style: normal;
            font-family: "hregular";
            line-height: 21px;
            letter-spacing: 0.32px;
            min-width: 62px;
            height: 78px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

}


@media (max-width:1300px){
    .valuespermonth .firstrow{
        overflow-x: hidden;
    }
    .valuespermonth .firstrow h6{
        padding: 10px 5px;
        font-size: 14px;
        min-width: 152px;

    }
    .valuespermonth .firstrow p{
        padding: 10px 5px;
        font-size: 14px;
        min-width: 58px;
    }
}


@media (max-width:600px) {
    .nodesminers .main-content h5 {
        font-size: 28px;

        img {
            width: 44px;
            height: 44px;
            flex-shrink: 0;
        }
    }

    .nodesminers .main-content h4 {
        font-size: 38px;
    }

    .nodesminers .main-content .twice-btns {
        display: grid;
        grid-template-columns: 1fr;

        a {
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    .nodesminers .main-content p {
        font-size: 16px;
    }

    .bottom-cards-nodes .parent {
        grid-template-columns: 1fr;
        // margin-top: 350px;
    }

    .nodesminers .main-banner-video {
        width: 800px;
        padding: 0;
        top: -600%;
        transform: translate(35%, 0%);
        z-index: -1;

    }

    .bottom-cards-nodes .parent .single-card {
        border-bottom: 1px solid var(--border2);
    }

    .overflow-hiddenmobile {
        overflow: visible !important;
    }

    .valuespermonth .firstrow {
        overflow: unset;
        flex-direction: column;
    }

    .valuespermonth {
        display: grid;
        grid-template-columns: 1fr 1fr;
    }

    .valuespermonth .firstrow h6 {
        min-width: 100% !important;
        font-size: 14px !important;
        height: 50px !important;
    }

    .valuespermonth .firstrow p {
        min-width: 100% !important;
        font-size: 14px !important;
        height: 50px !important;
        border-top: 1px solid var(--border2);
    }
}

@media screen and (min-device-width: 320px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) {

    @supports (-webkit-overflow-scrolling: touch) {
        .nodesminers .main-banner-video {
            top: -580% !important;
        }
    }
}

.btn-showmorenodes {
    background: var(--btn-buy-bg);
    padding: 18px 20px;
    color: var(--btn-green-bg);
    font-size: 14px;
    font-style: normal;
    font-family: "hregular";
    line-height: 21px;
    letter-spacing: 0.28px;
    text-decoration: underline !important;
    text-decoration-style: solid;
    text-decoration-skip-ink: none;
    text-decoration-thickness: auto;
    text-underline-offset: auto;
    text-underline-position: from-font;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid var(--border2);
    border-top: 1px solid transparent;
    width: 100%;
}