.project-miners {
  padding: 80px 0;

  .parent {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 40px;

    .left-side {
      max-width: 640px;
      width: 100%;

      h5 {
        color: var(--btn-green-bg);
        font-size: 30px;
        font-style: normal;
        font-weight: 500;
        line-height: 100%;
        text-transform: capitalize;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 15px;
        margin-bottom: 30px;
      }

      h4 {
        color: var(--heading-text);
        font-size: 60px;
        font-style: normal;
        font-weight: 400;
        line-height: 100%;
        text-transform: capitalize;
      }

      p {
        color: var(--V6-Dark-Theme-Secondary-Text-Color);
        font-size: 20px;
        font-style: normal;
        font-family: "hregular";
        line-height: 140%;
        margin: 40px 0;
      }

      .twice-btns {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 15px;

        a {
          padding: 18px 22px;
        }
      }

      .miner-box {
        border-radius: 4px;
        border: 1px solid var(--border2);
        background: var(--background3);
        padding: 25px;
        position: relative;
        z-index: 9;

        .shadow-box {
          position: absolute;
          bottom: 0;
          left: 0;
          z-index: -1;
          pointer-events: none;
        }

        h6 {
          color: var(--V6-Dark-Theme-Primary-Text-Color);
          font-size: 20px;
          font-style: normal;
          font-family: "hmedium";
          line-height: 120%;
        }

        p {
          margin-top: 10px;
          margin-bottom: 35px;
          font-size: 16px;
        }

        .hardcap-ifexist {
          margin-bottom: 40px;

          .progress {
            background: rgba(79, 92, 97, 0.1);
            height: 11px;
            border-radius: 0;

            .progress-bar {
              background: var(--V6-Dark-Theme-Green-Color, #30f1b6);
            }
          }
        }

        .twice-btns {
          a {
            min-width: 150px;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
      }
    }

    .right-side {
      flex: 1;
      width: 100%;

      .upper-text {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin-bottom: 40px;

        h5 {
          color: var(--btn-green-bg);
          text-align: center;
          font-family: "f37";
          font-size: 24px;
          font-style: normal;
          line-height: 100%;
          text-transform: capitalize;
          margin-bottom: 8px;
        }

        p {
          color: var(--heading-text);
          text-align: center;
          font-size: 20px;
          font-style: normal;
          font-family: "hmedium";
          line-height: 120%;
        }
      }

      .slider {
        max-width: 292px;
        width: 100%;
        margin: 0 auto;
      }
    }
  }

  .slick-dots li button:before {
    color: #294e59;
    font-size: 12px;
  }

  .slick-dots li.slick-active button:before {
    color: var(--btn-green-bg);
  }

  .slick-slide {
    padding: 0 10px;
  }

  .slick-list {
    margin: 0 -10px;
  }
}

.mineproject-cards {
  .main-heading {
    margin-bottom: 30px;
    max-width: 596px;
    width: 100%;

    h5 {
      color: var(--V6-Dark-Theme-Primary-Text-Color);
      font-family: "f37";
      font-size: 32px;
      font-style: normal;
      font-weight: 500;
      line-height: 100%;
      margin-bottom: 10px;
    }

    p {
      color: var(--V6-Dark-Theme-Secondary-Text-Color);
      font-size: 20px;
      font-style: normal;
      font-family: "hregular";
      line-height: 140%;
    }
  }

  .single-card {
    background: linear-gradient(180deg,
        rgba(8, 21, 25, 0.4) 0%,
        rgba(6, 104, 74, 0.4) 289.27%) !important;
    -webkit-backdrop-filter: blur(20px) !important;
    backdrop-filter: blur(20px) !important;

    .tokenmined {
      padding-top: 16px;
      padding-bottom: 16px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid var(--border2);

      &:last-child {
        border-bottom: 1px solid transparent;
      }

      p {
        color: var(--V6-Dark-Theme-Secondary-Text-Color);
        font-size: 12px;
        font-style: normal;
        font-family: "hregular";
        line-height: 120%;
      }

      h6 {
        color: var(--V6-Dark-Theme-Primary-Text-Color);
        font-size: 14px;
        font-style: normal;
        font-family: "hmedium";
        line-height: 120%;
      }
    }

    .btn-buy {
      border-radius: 4px !important;
      border: 1px solid var(--btn-green-bg) !important;
      background-color: transparent !important;
    }

    .listing-data {
      position: relative;
      height: 50px;
      padding: 18px 22px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 5px;
      border-radius: 4px;
      // border: 1px solid #05282D;
      background: var(--background3);

      .tokencardbg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 0;
        pointer-events: none;
      }

      h5 {
        color: var(--V6-Dark-Theme-Primary-Text-Color);
        font-size: 14px;
        font-style: normal;
        font-family: "hbold";
        line-height: 100%;
      }

      h6 {
        color: var(--btn-green-bg);
        font-size: 16px;
        font-style: normal;
        font-family: "hbold";
        line-height: 100%;
      }
    }

    .bottom-mini-para {
      color: var(--V6-Dark-Theme-Secondary-Text-Color);
      text-align: center;
      font-size: 10px;
      font-style: normal;
      font-family: "hregular";
      line-height: 120%;
      text-align: center;
      margin-top: 20px;
    }
  }
}

.main-headinginsideminer {
  color: var(--V6-Dark-Theme-Primary-Text-Color);
  font-family: "f37";
  font-size: 32px;
  font-style: normal;
  line-height: 100%;
  margin-bottom: 30px;
}

@media (max-width: 600px) {
  .project-miners .parent .left-side h5 {
    font-size: 30px;
  }

  .project-miners .parent .left-side h4 {
    font-size: 40px;
  }

  .project-miners .parent {
    flex-direction: column;
  }

  .project-miners .parent .left-side .twice-btns {
    display: grid;
    grid-template-columns: 1fr;

    a {
      display: flex;
      justify-content: center;
      align-items: center;
      min-width: auto !important;
    }
  }

  .project-miners .parent .left-side p {
    font-size: 16px;
  }

  .project-miners .parent .right-side .slider {
    width: 100%;
  }

  .project-miners .parent .right-side {
    width: 100%;
  }

  .project-miners .parent .left-side .miner-box {
    padding: 15px;
  }

  .mineproject-cards {
    padding-top: 80px;
  }

  .mineproject-cards .main-heading h5 {
    font-size: 26px;
  }

  .mineproject-cards .main-heading p {
    font-size: 16px;
  }

  .main-headinginsideminer {
    font-size: 26px;
  }

  .mineproject-cards .single-card .tokenmined {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }

  .mineproject-cards .single-card .listing-data {
    padding: 15px 10px;
  }

  .mineproject-cards .single-card .listing-data h6 {
    font-size: 12px;
  }

  .mineproject-cards .single-card .listing-data h6 {
    font-size: 14px;
  }

  .onmobiletext {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 1.5em;
    min-height: unset !important;
    max-height: 60px;
  }
}

.light-theme .mineproject-cards .single-card {
  background: linear-gradient(180deg, #d1dadd 0%, #aed6cf 169.03%) !important;
  -webkit-backdrop-filter: blur(20px) !important;
  backdrop-filter: blur(20px) !important;
}