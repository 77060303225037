.faqs-miners {
    padding: 60px 0;
    min-height: auto;

    .innerfaq {
        max-width: 910px;
        width: 100%;
        margin: 0 auto;
    }

    .innerfaq .head {
        text-align: center;
        font-size: 50px;

        span {
            font-family: "bl";
        }
    }

    .innerfaq .accordion .accordion-item {
        border-bottom: 1px solid transparent;
        border: 1px solid var(--border2);
        background: var(--card-bg);
        backdrop-filter: blur(20px);
        margin-bottom: 5px;

        &.active {
            border: 1px solid var(--border2);
            background: var(--btn-buy-bg) !important;
            backdrop-filter: blur(20px);
        }
    }

    .btn-loadmore {
        padding: 18px 22px;
        display: block;
        max-width: 150px;
        width: 100%;
        margin: 0 auto;
        margin-top: 50px;
        border: none;
    }

    h6 {
        color: var(--heading-text);
        font-size: 16px;
        font-style: normal;
        font-family: "hsemibold";
        line-height: 120%;
        margin: 15px 0;
    }

    .para{
        span{
            font-family: "hbold";
        }
    }

    ul li, ol li {
        span{
            font-family: "hbold";
        }
    }
}

@media (max-width:600px) {
    .faqs-miners .innerfaq .accordion .accordion-item .accordion-header .accordion-button p {
        font-size: 16px;
    }

    .faqs-miners .innerfaq .accordion .accordion-item .accordion-header .accordion-button {
        padding: 15px;
    }

    .faqs-miners .innerfaq .accordion .accordion-item .accordion-header .accordion-button p span {
        font-size: 16px;
    }

    .faqs-miners .innerfaq .accordion .accordion-body {
        padding: 30px 15px;
    }

    .faqs-miners .innerfaq .head {
        font-size: 30px;

        br {
            display: none;
        }
    }

    .faqs-miners .innerfaq .accordion .accordion-item .accordion-header .accordion-button {
        gap: 15px;
    }
}