.information-section {
  .first-para {
    h2 {
      color: var(--heading-text);
      font-size: 20px;
      font-style: normal;
      line-height: 100%;
      text-transform: uppercase;
      margin: 20px 0;
    }

    h6 {
      color: var(--heading-text);
      font-size: 16px;
      font-style: normal;
      line-height: 100%;
      text-transform: uppercase;
      margin: 20px 0;
    }

    p {
      color: var(--V6-Dark-Theme-Secondary-Text-Color, #939fa3);
      font-size: 16px;
      font-style: normal;
      font-family: "hregular";
      line-height: 130%;
      margin: 15px 0;

      a {
        color: var(--V6-Dark-Theme-Secondary-Text-Color, #939fa3);
        font-size: 16px;
        font-style: normal;
        font-family: "hmedium";
        line-height: 130%;
        text-decoration: underline !important;
      }
    }

    ul {
      li {
        color: var(--V6-Dark-Theme-Secondary-Text-Color, #939fa3);
        font-size: 16px;
        font-style: normal;
        font-family: "hregular";
        line-height: 130%;
        margin: 5px 0;
        list-style-type: disc;
        margin-left: 25px;

        span {
          font-family: "hsemibold";
        }
      }
    }

    ol {
      li {
        color: var(--V6-Dark-Theme-Secondary-Text-Color, #939fa3);
        font-size: 16px;
        font-style: normal;
        font-family: "hregular";
        line-height: 130%;
        margin: 5px 0;

        span {
          font-family: "hsemibold";
        }
      }
    }

    .team-gems {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 10px;

      .single-team {
        border-radius: 4px;
        border: 1px solid var(--border2);
        background: var(--background5);
        padding: 20px;

        img {
          width: 65px;
          height: 65px;
          border-radius: 50%;
          object-fit: cover;
          object-position: top;
        }

        h6 {
          color: var(--heading-text);
          font-size: 16px;
          font-style: normal;
          font-family: "hmedium";
          line-height: 110%;
          text-transform: capitalize;
          margin-top: 12px;
          margin-bottom: 20px;
        }

        h5 {
          color: var(--text-color1);
          font-size: 14px;
          font-style: normal;
          font-family: "hregular" !important;
          line-height: 110%;
          text-transform: capitalize;
        }

        p {
          color: var(--V6-Dark-Theme-Secondary-Text-Color, #939fa3);
          font-size: 14px;
          font-style: normal;
          font-family: "hregular";
          line-height: 130%;
        }
      }
    }

    .partners-content {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin: 40px 0;
    }

    .twice-tokenomics {
      .tokenomic-img {
        border-radius: 4px;
        border: 1px solid var(--border2);
        background: var(--background5);
        padding: 28px 58px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }

  .img-wrapper {
    margin: 40px 0;

    img {
      width: 100%;
      object-fit: cover;
    }
  }

  .detailcomingthorugheditor {

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      color: var(--heading-text);
      font-size: 20px;
      font-style: normal;
      line-height: 100%;
      text-transform: uppercase;
      margin: 20px 0;
    }

    p {
      color: var(--V6-Dark-Theme-Secondary-Text-Color, #939fa3);
      font-size: 16px;
      font-style: normal;
      font-family: "hregular";
      line-height: 130%;
      margin: 15px 0;
      word-wrap: break-word;
      overflow-wrap: break-word;
      word-break: break-word;
      white-space: normal;

      a {
        color: var(--V6-Dark-Theme-Secondary-Text-Color, #939fa3);
        font-size: 16px;
        font-style: normal;
        font-family: "hmedium";
        line-height: 130%;
        text-decoration: underline !important;
      }
    }

    a {
      color: var(--V6-Dark-Theme-Secondary-Text-Color, #939fa3);
      font-size: 16px;
      font-style: normal;
      font-family: "hmedium";
      line-height: 130%;
      text-decoration: underline !important;
    }

    ul {
      li {
        color: var(--V6-Dark-Theme-Secondary-Text-Color, #939fa3);
        font-size: 16px;
        font-style: normal;
        font-family: "hregular";
        line-height: 130%;
        margin: 5px 0;
        list-style-type: disc;
        margin-left: 25px;
      }
    }

    ol {
      li {
        color: var(--V6-Dark-Theme-Secondary-Text-Color, #939fa3);
        font-size: 16px;
        font-style: normal;
        font-family: "hregular";
        line-height: 130%;
        margin: 5px 0;
      }
    }

    img {
      max-width: 100%;
      width: 100%;
      display: block;
      margin: 0 auto;
    }
  }
}

.rain-information-section {
  .tokenomic-img {
    margin-bottom: 20px;
    overflow: hidden;
  }

  h2 {
    color: var(--heading-text);
    font-family: "f37" !important;
    font-size: 20px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: 100% !important;
    text-transform: uppercase !important;
  }

  .smallhead {
    color: var(--heading-text);
    font-family: "f37" !important;
    font-size: 18px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: 100% !important;
    text-transform: uppercase !important;
  }

  .lowerhead {
    color: var(--heading-text);
    font-family: "hsemibold";
    font-size: 16px;
    font-style: normal;
    line-height: 100%;
    text-transform: capitalize;
    margin: 20px 0;
  }

  .first-para {
    margin-bottom: 40px !important;
  }

  .rainhead {
    color: var(--heading-text) !important;
    font-family: "hsemibold" !important;
    font-size: 16px !important;
    font-style: normal !important;
    line-height: 130% !important;
    margin-bottom: 10px !important;
    text-transform: capitalize !important;
  }

  .listset {
    li {
      position: relative;
      margin: 10px 0px !important;
      padding-left: 13px;

      &::before {
        content: "";
        position: absolute;
        top: 0.6em; // adjust vertically if needed
        left: 0;
        width: 3px !important; // square size
        height: 3px !important;
        background-color: currentColor; // inherits text color
        font-family: 'hlight' !important;
      }
    }
  }

  .marginlist {
    margin-bottom: 10px !important;

    li {
      position: relative;
      margin: 10px 0px !important;
      padding-left: 13px;

      &::before {
        content: "";
        position: absolute;
        top: 0.6em; // adjust vertically if needed
        left: 0;
        width: 3px !important; // square size
        height: 3px !important;
        background-color: currentColor; // inherits text color
        font-family: 'hlight' !important;
      }
    }
  }

  .rain-list {
    list-style: none !important;
    padding-left: 10px;
    margin-bottom: 40px;

    li {
      position: relative;
      margin: 10px 0px !important;
      padding-left: 13px;

      &::before {
        content: "";
        position: absolute;
        top: 0.6em; // adjust vertically if needed
        left: 0;
        width: 4px; // square size
        height: 4px;
        background-color: currentColor; // inherits text color
        font-family: 'hlight' !important;
      }
    }
  }
}

@media (max-width: 600px) {
  .information-section .first-para .partners-content {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .information-section .first-para .team-gems {
    grid-template-columns: 1fr;
  }

  .information-section .first-para .twice-tokenomics .tokenomic-img {
    padding: 15px;
  }
}


.valuespermonthdetailpage {
  max-width: 920px;
  overflow-x: auto !important;

  &::-webkit-scrollbar {
    display: none;
  }
}

.valuespermonthdetailpage .firstrow {
  overflow: hidden;
  overflow-x: hidden !important;

  h6 {
    padding: 10px 5px !important;
    font-size: 12px !important;
    min-width: 110px !important;
    height: 60px !important;
  }

  p {
    padding: 10px 5px !important;
    font-size: 12px !important;
    letter-spacing: 0 !important;
    min-width: 45px !important;
    height: 60px !important;
  }
}

.valuespermonthdetailpage .scroller-div {
  overflow-x: auto;
  width: 1320px;
}

@media (max-width:600px) {
  .valuespermonthdetailpage {
    max-width: 100%;
    overflow: unset !important;
  }

  .valuespermonthdetailpage .scroller-div {
    width: 100%;
    overflow: unset;
  }

  .rain-information-section .d-noneformbl {
    display: none !important;
  }

  .rain-information-section .d-blockformobile{
    display: block !important;
  }

  .rain-information-section .rainacc {
    display: block !important;
    background-color: transparent !important;
    box-shadow: none !important;
    border-radius: 0 !important;
    margin: 0 !important;
    padding: 0 !important;
    border-radius: 4px;
    border: 1px solid var(--border2);
    overflow: hidden;
    margin-bottom: 20px !important;

    .tophead {
      display: flex;
      align-items: center;
      border-bottom: 1px solid var(--border2);
      background: var(--accbackgroundd);

      .firsthead {
        color: var(--acccolor);
        font-family: "f37";
        font-size: 12px;
        font-style: normal;
        line-height: 24px;
        letter-spacing: 0.24px;
        text-transform: uppercase;
        padding: 20px;
        width: 60%;
        margin: 0;
      }

      .lasthead {
        color: var(--acccolor);
        font-family: "f37";
        font-size: 12px;
        font-style: normal;
        line-height: 24px;
        letter-spacing: 0.24px;
        text-transform: uppercase;
        padding: 20px;
        width: 40%;
        margin: 0;
      }
    }

    .accordion-item {
      background-color: transparent !important;
      border: none !important;
      box-shadow: none !important;
      border-radius: 0 !important;
      margin: 0 !important;
      padding: 0 !important;

      .accordion-header {
        background-color: transparent !important;
        border: none !important;
        box-shadow: none !important;
        border-radius: 0 !important;
        margin: 0 !important;
        padding: 0 !important;

        .accordion-button {
          box-shadow: none !important;
          border-radius: 0 !important;
          margin: 0 !important;
          padding: 0 !important;
          border: none;
          width: 100%;
          border-bottom: 1px solid var(--border2);
          background: var(--accbackgroundd);

          .btnhead {
            display: flex;
            align-items: center;
            width: 100%;

            .firsthead {
              color: var(--acccolor);
              font-family: "f37";
              font-size: 12px;
              font-style: normal;
              line-height: 24px;
              letter-spacing: 0.24px;
              text-transform: uppercase;
              padding: 14px 20px;
              width: 60%;
              margin: 0;
            }

            .lasthead {
              color: var(--acccolor);
              font-family: "f37";
              font-size: 12px;
              font-style: normal;
              line-height: 24px;
              letter-spacing: 0.24px;
              text-transform: uppercase;
              padding: 14px 20px;
              width: 40%;
              margin: 0;
            }
          }

          &::after {
            position: absolute;
            right: 20px;
            width: 12.398px;
            height: 6px;
            background: url('../../../../assets/accarrow.svg');
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
            border: none;
            outline: none;
            margin: 0;
            padding: 0;
            box-shadow: none;
          }
        }

        .accordion-button:not(.collapsed)::after {
          position: absolute;
          right: 20px;
          width: 12.398px;
          height: 6px;
          background: url('../../../../assets/openaccarrow.svg');
          background-repeat: no-repeat;
          background-position: center;
          background-size: cover;
          border: none;
          outline: none;
          margin: 0;
          padding: 0;
          box-shadow: none;
          transform: rotate(360deg);
        }
      }

      .bordernone {
        button {
          border: none !important;
          border-bottom: none !important;
        }
      }

      .accordion-body {
        padding: 16px 20px;
        border-bottom: 1px solid var(--border2);
        background: var(--accbody);

        .bodyhead {
          color: var(--acccolor);
          font-family: "f37";
          font-size: 12px;
          font-style: normal;
          line-height: 100%;
          letter-spacing: 0.24px;
          margin-bottom: 8px !important;
          text-transform: uppercase;
          margin: 0;
          padding: 0;
        }

        .bodypara {
          color: var(--acccolor);
          font-family: "hregular";
          font-size: 14px;
          font-style: normal;
          line-height: 21px;
          letter-spacing: 0.28px;
          margin: 0;
          padding: 0;
        }
      }

      .bordertop {
        border-top: 1px solid var(--border2);
      }
    }
  }
}

.dark-theme .rain-information-section .rainacc .accordion-item .accordion-header .accordion-button:not(.collapsed)::after{
  background: url('../../../../assets/darkaacarrow.svg');
}