.signupproject {
  min-height: 100vh;
  padding-top: 55px;
  padding-bottom: 50px;

  .parent {
    border-radius: 15px;
    border: 1px solid #F2F2F2;
    background: #FFF;
    max-width: 611px;
    width: 100%;
    margin: 0 auto;
    padding: 30px;

    .main-heading {
      margin-bottom: 22px;

      h6 {
        color: #0B0B0B;
        font-family: "Chakra Petch";
        font-size: 28px;
        font-style: normal;
        font-weight: 600;
        line-height: 110%;
        text-transform: uppercase;
        margin-bottom: 12px;
      }

      p {
        color: #727272;
        font-size: 14px;
        font-style: normal;
        font-weight: 300;
        line-height: 120%;
      }
    }

    .bottom-content {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      gap: 18px;
      width: 100%;

      .option-field {
        width: 100%;

        .error-message {
          color: red;
          font-size: 14px;
          font-weight: 500;
          margin-top: 10px;
        }

        input {
          border-radius: 10px;
          border: 1px solid #EEE;
          background: #FFF;
          padding: 16px 12px;
          color: #000;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 100%;
          width: 100%;

          &::placeholder {
            color: #ABABAB;
          }
        }

        .dropdown {
          .dropdown-toggle {
            border-radius: 10px;
            border: 1px solid #EEE;
            background: #FFF;
            padding: 16px 12px;
            color: #ABABAB;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 100%;
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            transition: 0.3s linear;

            &::after {
              display: none;
            }

            &[aria-expanded="true"] {
              color: #000;

              svg {
                transform: rotate(180deg);
              }
            }
          }

          .dropdown-menu {
            width: 100%;
            border-radius: 10px;
            border: 1px solid #EEE;
            background: #FFF;
            padding: 0;
            max-height: 300px;
            overflow-y: auto;

            &::-webkit-scrollbar {
              display: none;
            }

            a {
              padding: 16px 12px;
              color: #000;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 100%;
            }
          }
        }
      }

      .twice-fields {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        .option-field {
          flex: 0 0 49%;


        }
      }
    }

    .btn-sign {
      a {
        width: 100%;
        margin-top: 22px;
        border-radius: 6px;
        background: #53FFEA;
        padding: 20px;
        // width: 100%;
        color: #000;
        text-align: center;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 100%;
        text-transform: uppercase;
        display: flex;
        justify-content: center;
        align-items: center;
        border: none;
      }
    }
  }
}

@media (max-width:1024px) {
  .signupproject {
    padding-bottom: 50px;
  }
}


.addteammembermodal {
  .modal-dialog {
    max-width: 500px;

    .modal-header {
      margin-bottom: 15px;
    }

    .modal-body {
      .addingmain {
        display: flex;
        align-items: center;
        gap: 15px;

        .addingcard {
          border-radius: 17px;
          border: 1px solid #f7f7f7;
          background: #fff;
          display: flex;
          padding: 15px;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          max-width: 450px;
          width: 100%;
          margin: 0 auto;

          .addinghead {
            color: #000;
            margin-top: 20px;
            margin-bottom: 20px;
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: 120%;
            text-transform: uppercase;
          }

          .addingpara {
            color: #84848e;
            margin-bottom: 22px;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            text-align: center;
          }
        }
      }

      .addedmodal {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        .addedhead {

          margin-top: 20px;
          margin-bottom: 30px;
          color: var(--heading-text);
          font-family: "f37";
          font-size: 20px;
          font-style: normal;
          line-height: 120%;
        }

        svg{
          path{
            fill: var(--svg-fill);
          }
        }
      }

      .dropdown {
        width: 100%;
        margin-bottom: 18px;

        .btn {
          box-shadow: none !important;
          padding: 0;
          margin: 0;
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          border-radius: 10px;
          border: 1px solid #eee;
          background: #fff;
          padding: 16px 18px;
          width: 100%;
          box-shadow: none !important;
          color: #ababab;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 100%;

          &::after {
            color: #000;
          }
        }
      }

      .selectdropdown {
        width: 100%;

        .btn {
          background: transparent;
          box-shadow: none !important;
          border: none !important;
          padding: 0;
          margin: 0;
          width: 100%;
          color: #000;

          &::after {
            display: none !important;
          }

          .material-textfield {
            position: relative;

            .arrowimg {
              position: absolute;
              top: 23px;
              right: 12px;
            }

            label {
              text-align: start;
            }
          }
        }

        .dropdown-menu {
          border-radius: 10px;
          border: 1px solid #eee;
          padding: 0px;
          background: #fff;
          width: 100%;
          max-height: 300px;
          overflow-y: auto;

          &::-webkit-scrollbar {
            display: none;
          }

          .dropitem {
            border-bottom: 1px solid #eee;
            padding: 5px 10px;
          }
        }
      }

      .modalbtns {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 8px;

        .whitebtn {
          padding: 18px 25px;
        }

        button {
          width: 100%;
        }
      }
    }
  }
}


.loading {
  animation: rotate 5s linear infinite;
  width: 17px;
  height: 17px;
  margin-right: 10px;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}


@media (max-width:600px) {
  .signupproject .parent .bottom-content .twice-fields {
    flex-direction: column;
    gap: 18px;
  }

  .signupproject .parent {
    padding: 15px;
  }
}















.apply-section {
  .apply-box {
    .bottom-content {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      gap: 18px;
      width: 100%;

      .option-field {
        width: 100%;

        .error-message {
          color: red;
          font-size: 14px;
          font-weight: 500;
          margin-top: 10px;
        }

        input {
          border-radius: 4px;
          background: var(--input-bg);
          border: none;
          height: 54px;
          padding: 20px 18px;
          color: var(--heading-text);
          font-family: "hregular";
          font-size: 14px;
          font-style: normal;
          line-height: normal;
          width: 100%;

          &::placeholder {
            color: var(--placeholder-text);
            font-family: "hregular";
            font-size: 14px;
            font-style: normal;
            line-height: normal;
          }
        }

        .dropdown {
          .dropdown-toggle {
            border-radius: 4px;
            background: var(--input-bg);
            height: 54px;
            padding: 20px 18px;
            border: none;
            color: var(--placeholder-text);
            font-family: "hregular";
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            transition: 0.3s linear;

            h6 {
              font-family: "hregular";
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
            }

            svg {
              path {
                fill: var(--svg-fill);
              }
            }

            &::after {
              display: none;
            }

            &[aria-expanded="true"] {
              color: var(--heading-text);

              svg {
                transform: rotate(180deg);

                path {
                  fill: var(--svg-fill);
                }
              }
            }
          }

          .dropdown-menu {
            width: 100%;
            border-radius: 4px;
            background: var(--input-bg);
            border: 1px solid var(--border2);
            padding: 0;
            max-height: 300px;
            overflow-y: auto;

            &::-webkit-scrollbar {
              display: none;
            }

            a {
              padding: 16px 12px;
              color: var(--heading-text);
              font-family: "hregular";
              font-size: 14px;
              font-style: normal;
              line-height: normal;
              border-bottom: 1px solid var(--border2);

              &:hover {
                background-color: transparent;
              }
            }
          }
        }
      }

      .twice-fields {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        .option-field {
          flex: 0 0 49%;


        }
      }
    }

    .btn-sign {
      a {
        width: 100%;
        margin-top: 22px;
        border-radius: 4px;
        background: var(--btn-green-bg);
        padding: 18px;
        // width: 100%;
        color: var(--btn-text-low);
        font-family: "hregular";
        font-size: 14px;
        font-style: normal;
        line-height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        border: none;
        transition: 0.3s linear;

        &:hover {
          background: var(--btn-green-bg-hover);
        }

        &:active {
          box-shadow: 0px 0px 10px 8px var(--btn-green-bg-active);

        }
      }
    }
  }
}


.sectionforsignupproject {
  .inner-twice {
    margin-top: 32px;

    .twice-data {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 20px;
      margin-bottom: 30px;

    
      .text {
        h5 {
          color: var(--heading-text);
          font-family: "hmedium";
          font-size: 14px;
          font-style: normal;
          line-height: 130%;
          margin-bottom: 8px;
        }

        p {
          color: var(--V6-Dark-Theme-Secondary-Text-Color);
          font-family: "hregular";
          font-size: 14px;
          font-style: normal;
          line-height: 120%;
        }

        ul {
          li {
            color: var(--V6-Dark-Theme-Secondary-Text-Color) !important;
            font-family: "hregular" !important;
            font-size: 14px !important;
            font-style: normal !important;
            line-height: 120% !important;
          }
        }
      }
    }
  }

  .parent .left-side {
    max-width: 588px !important;
  }

  .parent .right-side .apply-box .main-head {
    margin-bottom: 12px !important;
  }

  .parent .right-side .apply-box p {
    margin-bottom: 40px !important;
    color: var(--V6-Dark-Theme-Secondary-Text-Color);
    font-family: "hregular";
    font-size: 18px;
    font-style: normal;
    line-height: 130%;
  }

  .parent .left-side .main-content h4 {
    font-size: 24px !important;
  }
}

@media (max-width:1024px) {
  .sectionforsignupproject .parent .left-side .main-content h4 {
    font-size: 24px !important;

    br {
      display: none;
    }

    span {
      font-size: 24px;
    }
  }
}