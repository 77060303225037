.document-section {
    .first-para {
        h2 {
            color: var(--heading-text);
            font-size: 20px;
            font-style: normal;
            line-height: 100%;
            text-transform: uppercase;
            margin-bottom: 20px;
        }
    }

    .bottom-documents {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 10px;
        .single-document {
            border-radius: 4px;
            border: 1px solid var(--border2);
            background: var(--background5);
            padding: 25px;


            .wrap-img {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                gap: 12px;
                margin-bottom: 25px;

                svg{
                    path{
                        fill: var(--text-color1);
                    }
                }

                h6 {
                    color: var(--heading-text);
                    font-size: 18px;
                    font-style: normal;
                    font-family: "hmedium";
                    line-height: 110%;
                    text-transform: capitalize;
                }
            }

            a {
                border-radius: 6px;
                border: 1px solid var(--V6-Dark-Theme-Green-Color, #30F1B6);
                padding: 18px 22px;
                color: var(--V6-Dark-Theme-Green-Color, #30F1B6);
                font-size: 14px;
                font-style: normal;
                font-family: "hregular";
                line-height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
            }
        }
    }
}


@media (max-width:600px){
    .document-section .bottom-documents{
        grid-template-columns: 1fr;
    }
}