.wrapper-detailpage {
    .main-navbar {
        position: relative;
        transform: unset;
    }
}

.banner-detailpage {
    position: relative;
    z-index: 99;
    height: 330px;
    display: flex;
    justify-content: center;
    align-items: center;

    .banner-bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        pointer-events: none;
        object-fit: cover;
    }

    .main-logo {
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.bottom-part-section {
    padding-top: 20px;
    padding-bottom: 90px;
    position: relative;
    z-index: 99;

    .parent {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        gap: 60px;

        .left-side {
            max-width: 336px;
            width: 100%;
            position: sticky;
            top: 20px;
            margin-top: -60px;

            .token-info-box {
                border-radius: 4px;
                border: 1px solid var(--border2);
                background: var(--background3);
                padding: 20px;
                margin-bottom: 6px;

                .token-img {
                    width: 65px;
                    height: 65px;
                    border-radius: 50%;
                    overflow: hidden;

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }

                .token-title {
                    margin-top: 12px;
                    margin-bottom: 25px;

                    h6 {
                        color: var(--heading-text);
                        font-size: 22px;
                        font-style: normal;
                        font-family: "hmedium";
                        line-height: 110%;
                        text-transform: capitalize;
                        margin-bottom: 5px;
                        word-wrap: break-word;
                        overflow-wrap: break-word;
                        white-space: normal;
                    }

                    p {
                        color: var(--text-color1);
                        font-size: 14px;
                        font-style: normal;
                        font-family: "hmedium";
                        line-height: 110%;
                        text-transform: capitalize;
                        word-wrap: break-word;
                        overflow-wrap: break-word;
                        white-space: normal;
                    }
                }

                .social-icons {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    gap: 5px;

                    a {
                        border-radius: 100px;
                        background: rgba(79, 92, 97, 0.10);
                        width: 24px;
                        height: 24px;
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        svg {
                            path {
                                transition: 0.3s linear;

                            }
                        }

                        &:hover {
                            svg {
                                path {
                                    fill: var(--btn-green-bg);
                                }
                            }
                        }


                    }
                }
            }

            .presale-box {
                .timer-parent {
                    border-radius: 4px 4px 0px 0px;
                    border-top: 1px solid var(--border2);
                    border-right: 1px solid var(--border2);
                    border-left: 1px solid var(--border2);
                    background: var(--background4);
                    padding: 20px;


                    .main-head {
                        color: var(--heading-text);
                        font-size: 14px;
                        font-style: normal;
                        font-family: "hmedium";
                        line-height: 100%;
                        text-transform: uppercase;
                        margin-bottom: 12px;
                        text-align: center;
                    }

                    .timer {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;

                        .text {
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            align-items: center;
                            gap: 2px;

                            h6 {
                                color: var(--V6-Dark-Theme-Green-Color, #30F1B6);
                                font-size: 20px;
                                font-style: normal;
                                font-family: "hbold";
                                line-height: 100%;
                                text-transform: uppercase;
                            }

                            p {
                                color: var(--text-color1);
                                font-size: 12px;
                                font-style: normal;
                                font-family: "hregular";
                                line-height: 100%;
                                text-transform: uppercase;
                            }
                        }
                    }

                }

                .presale-detail {
                    border-radius: 0px 0px 4px 4px;
                    border: 1px solid var(--border2);
                    background: var(--background3);
                    padding: 20px;

                    .main-head {
                        color: var(--heading-text);
                        font-size: 14px;
                        font-style: normal;
                        font-family: "hmedium";
                        line-height: 100%;
                        text-transform: uppercase;
                        margin-bottom: 20px;
                    }

                    .bottom-detail {
                        .twice-text {
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            padding-bottom: 7px;

                            p {
                                color: var(--V6-Dark-Theme-Secondary-Text-Color, #939FA3);
                                font-size: 14px;
                                font-style: normal;
                                font-family: "hregular";
                                line-height: 120%;
                            }

                            h6 {
                                color: var(--heading-text);
                                font-size: 14px;
                                font-style: normal;
                                font-family: "hmedium";
                                line-height: 120%;
                            }
                        }
                    }

                    .hardcap-ifexist {
                        padding-top: 10px;
                        border-top: 1px solid var(--border2);


                        .upper-detail {
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            margin-bottom: 10px;

                            p {
                                color: var(--V6-Dark-Theme-Secondary-Text-Color, #939FA3);
                                font-size: 14px;
                                font-style: normal;
                                font-family: "hregular";
                                line-height: 120%;
                            }

                            h6 {

                                color: var(--V6-Dark-Theme-Secondary-Text-Color, #939FA3);
                                font-size: 14px;
                                font-style: normal;
                                font-family: "hmedium";
                                line-height: 120%;

                                span {
                                    color: var(--heading-text);
                                }
                            }
                        }

                        .progress {
                            background: rgba(79, 92, 97, 0.10);
                            height: 11px;
                            border-radius: 0;

                            .progress-bar {
                                background: var(--V6-Dark-Theme-Green-Color, #30F1B6);
                            }
                        }
                    }
                }

                .detailminers-price {
                    .twice-text {
                        padding: 11px 0;
                        border-bottom: 1px solid var(--border2);

                        .inline-text {
                            p {
                                color: var(--V6-Dark-Theme-Secondary-Text-Color);
                                font-size: 10px;
                                font-style: normal;
                                font-family: "hregular";
                                line-height: 120%;
                                text-align: right;
                                margin-bottom: 2px;
                            }
                        }
                    }
                }

                .tokenlive-detail {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    width: 100%;

                    h6 {
                        color: var(--heading-text);
                        text-align: center;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 100%;
                        text-transform: uppercase;
                        margin-bottom: 20px;
                    }

                    .btn-uniswap {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        gap: 10px;

                        svg {
                            path {
                                fill: var(--btn-green-text);
                            }
                        }

                    }

                    .btn-explorer {
                        border-radius: 4px;
                        padding: 18px 22px;
                        color: var(--btn-explorer-text);
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 100%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        gap: 10px;

                        svg {
                            path {
                                fill: var(--btn-explorer-text);
                            }
                        }
                    }
                }
            }

            .btn-buy {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                padding: 18px 22px;
                margin-top: 20px;

                &.disabled {
                    pointer-events: none;
                    opacity: 0.5;
                }

            }
        }

        .right-side {
            flex: 1;

            .nav {
                border: none;
                height: 34px;
                width: 100%;
                border-bottom: 2px solid var(--border2);
                gap: 20px;
                margin-bottom: 30px;

                .nav-item {
                    .nav-link {
                        color: var(--V6-Dark-Theme-Secondary-Text-Color, #939FA3);
                        font-size: 14px;
                        font-style: normal;
                        font-family: "hregular";
                        line-height: 100%;
                        padding: 0;
                        border: none;
                        border-bottom: 2px solid transparent;
                        height: 100%;

                    }
                }

                .nav-item.show .nav-link,
                .nav-link.active {
                    background-color: transparent;
                    border: none;
                    border-bottom: 2px solid var(--heading-text);
                }
            }
        }
    }
}



@media (max-width:600px) {

    .bottom-part-section .parent {
        flex-direction: column;
    }

    .bottom-part-section .parent .left-side {
        position: static;
        margin-top: -40px;
    }

    .bottom-part-section .parent .right-side {
        flex: auto;
        width: 100%;
    }

    .banner-detailpage .main-logo {
        width: 200px;
        margin: 0 auto;
        // margin-top: 70px;
    }

    .bottom-part-section .parent .left-side {
        max-width: 100%;
    }

    .banner-detailpage .banner-bg {
        object-position: top center;
    }

    .banner-mbl-bg {
        display: block !important;
        position: absolute;
        bottom: 0;
        right: 0;
        pointer-events: none;
        z-index: -1;
    }
}