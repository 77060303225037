
.wrapper-terms{
    .main-navbar{
        position: relative;
    }
}

.main-termbanner {
    height: 400px;
    position: relative;
    display: flex;
    align-items: center;

    .leftshade {
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: -1;
        height: 100%;
    }

    .rightshade {
        position: absolute;
        bottom: 0;
        right: 0;
        z-index: -1;
    }

    .main-content {

        .contentheads {
            .upper-text {
                color: var(--btn-green-bg);
                font-family: "bl";
                font-size: 32px;
                font-style: normal;
                line-height: 140%;
                margin-bottom: 10px;
            }

            .lower-text {
                color: var(--heading-text);
                font-family: "f37";
                font-size: 55px;
                font-style: normal;
                line-height: 110%;
                text-transform: uppercase;

                span {
                    color: var(--heading-text);
                    font-family: "bl";
                    font-size: 55px;
                    font-style: normal;
                    line-height: 110%;
                    text-transform: uppercase;
                }
            }
        }
    }
}

.terms-section {
    padding-top: 50px;
    padding-bottom: 100px;

    .insuranceinner {
        max-width: 760px;
        margin: 0 auto;
        width: 100%;

        .toppara {
            color: #000;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 140%;
            letter-spacing: -0.16px;
            margin-bottom: 30px;
        }

        .insurancehead {
            color: var(--heading-text);
            font-family: "f37";
            font-size: 26px;
            font-style: normal;
            line-height: 140%;
            letter-spacing: -0.18px;
            margin-bottom: 5px;
        }

        .marginsetttt{
            margin-bottom: 10px !important;
        }

        ul {
            margin-bottom: 30px;
            list-style-type: disc;
            list-style: outside;
            padding-left: 25px;

            li {
                color: var(--text-an);
                font-family: "Space Grotesk";
                font-size: 18px;
                font-style: normal;
                font-family: "hregular";
                line-height: 140%;
                letter-spacing: -0.16px;
                margin: 10px 0;
            }
        }

        .remove-dots{
           list-style-type: none;
        }

        ol {
            margin-bottom: 30px;
            list-style-position: outside;
            padding-left: 25px;
            margin-left: 0px;

            li {
                color: var(--text-an);
                font-family: "Space Grotesk";
                font-size: 18px;
                font-style: normal;
                font-family: "hregular";
                line-height: 140%;
                letter-spacing: -0.16px;
                margin: 10px 0;
            }
        }

        .midpara {
            color: var(--V6-Dark-Theme-Secondary-Text-Color, #939FA3);
            font-size: 18px;
            font-style: normal;
            font-family: "hregular";
            line-height: 140%;
            letter-spacing: -0.16px;
            margin: 10px 0;

            .midparabold {
                font-weight: 700;
            }

            a{
                color: var(--btn-green-bg) !important;
                font-family: "hmedium";
            }
        }

        .lastpara {
            color: #000;
            font-family: "Space Grotesk";
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 140%;
            letter-spacing: -0.16px;
        }
    }
}

@media(max-width:600px){
    .wrapper-apply .main-bannernew .insuranceheads{
        padding-top: 150px;
        padding-bottom: 30px;
    }
    .main-bannernew .main-contentss .insuranceheads span{
        font-size: 25px;
        svg{
            width: 28px;
        }
    }
    .main-bannernew .main-contentss .insuranceheads h3{
        font-size: 43px;
    }

     .main-termbanner .main-content .contentheads .lower-text {
        font-size: 34px;

        span {
            font-size: 34px;
        }
    }

     .main-termbanner .main-content .contentheads .upper-text {
        font-size: 26px;
    }
     .main-termbanner .leftshadembl {
        display: block !important;
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: -1;
    }

     .main-termbanner .rightshadembl {
        display: block !important;
        position: absolute;
        bottom: 0;
        right: 0;
        z-index: -1;
    }

     .main-termbanner .leftshade {
        display: none !important;
    }

     .main-termbanner .rightshade {
        display: none !important;
    }
    .main-termbanner{
        height: 330px;
    }
    .wrapper-terms .main-navbar{
        position: absolute;
    }
    .main-termbanner .main-content .contentheads .lower-text{
        margin-top: 70px;
    }
}