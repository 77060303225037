.visionary-section {
    border-bottom: 1px solid var(--border2);
    background: var(--background2);
    padding: 60px 0;
    .parent{
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        gap: 149px;
        .left-side{
            max-width: 432px;
            width: 100%;
            .main-content{
                h6{
                    color: var(--heading-text);
                    font-size: 18px;
                    font-style: normal;
                    font-family: "hmedium";
                    line-height: 120%; 
                    margin-bottom: 20px;
                }
                p{
                    color: var(--V6-Dark-Theme-Secondary-Text-Color, #939FA3);
                    font-size: 14px;
                    font-style: normal;
                    font-family: "hregular";
                    line-height: 140%; 
                    margin-bottom: 40px;
                }
                .btn-green{
                    padding: 18px 22px;
                    max-width: 123px;
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }
        }
        .right-side{
            flex: 1;
            h4{
                color: var(--heading-text);
                font-size: 70px;
                font-style: normal;
                line-height: 100%; 
                text-transform: capitalize;
            }
        }
    }
}

@media (max-width:600px){
    .visionary-section .parent{
        flex-direction: column-reverse;
        gap: 40px;
    }
    .visionary-section .parent .right-side h4{
        font-size: 34px;
    }
    .visionary-section .parent .left-side .main-content .btn-green{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        max-width: 100%;
    }
}