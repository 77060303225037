.claimallmain {
  padding: 123px 0px 50px;
  position: relative;

  .main-head {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;

    .btn-back {
      border-radius: 2px;
      background: var(--d-body-link-bg);
      padding: 2px 10px;
      color: var(--btn-green-bg);
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0.28px;
      text-transform: uppercase;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 10px;
    }

    h6 {
      color: var(--V6-Dark-Theme-Primary-Text-Color);
      font-size: 22px;
      font-style: normal;
      font-family: "f37";
      line-height: 100%;
      letter-spacing: 0.44px;
      text-transform: uppercase;
    }
  }

  .main-heading {
    margin-bottom: 20px;

    p {
      color: var(--V6-Dark-Theme-Secondary-Text-Color);
      font-family: "f37";
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 110%;
      text-transform: uppercase;
      margin-bottom: 10px;
    }

    .twice-elem {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 10px;

      img {
        width: 24px;
        height: 24px;
        border-radius: 50%;
      }

      h5 {
        color: var(--heading-text);
        font-family: "f37";
        font-size: 30px;
        font-style: normal;
        line-height: 24px;
        letter-spacing: 0.6px;
        text-transform: uppercase;
      }
    }
  }

  .claimallinnerhead {
    margin-bottom: 30px;

    color: var(--heading-text);
    font-family: "f37";
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
    /* 22px */
    letter-spacing: 0.44px;
    text-transform: uppercase;
  }

  .claimallinner {
    display: flex;
    align-items: flex-start;
    gap: 8px;

    .claimallleft {
      max-width: 687px;
      width: 100%;

      .claimallleftinner {
        // border-radius: 12px;
        border-radius: 4px;
        background: var(--claimcard-bg);
        // box-shadow: 0px -7px 0px 0px rgba(0, 0, 0, 0.04) inset;
        padding: 15px;
        margin-bottom: 8px;

        .checkboxmain {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          gap: 15px;
          margin-top: 20px;

          .clear {
            clear: both;
          }

          .checked {
            box-shadow: 0px 0px 0px 2px #fff !important;
            transition: 300ms ease;
          }

          .checkBox {
            display: block;
            cursor: pointer;
            width: 22px;
            height: 22px;
            position: relative;
            overflow: hidden;
            transition: 300ms ease;
            border-radius: 5px;
            border: 1.5px solid #77868b;
            background: var(--claimcard-bg);
          }

          .checkBox div {
            width: 15px;
            height: 15px;
            border-radius: 2px;
            background: var(--nav-active);
            top: -52px;
            left: -52px;
            position: absolute;
            z-index: 100;
            border-radius: 2px;
          }

          .checkBox input[type="checkbox"]:checked + div {
            left: 2.6px;
            top: 2.6px;
          }

          .checkBox:has(input:checked) {
            border: 1.5px solid #329879;
          }

          .checkBox input[type="checkbox"] {
            position: absolute;
            left: 50px;
            visibility: hidden;
          }

          .transition {
            transition: 300ms ease;
          }

          .checkboxtexts {
            .checkboxhead {
              color: var(--heading-text);

              font-size: 16px;
              font-style: normal;
              font-weight: 500;
              line-height: 100%;
              /* 16px */
              text-transform: uppercase;
              text-transform: uppercase;
              display: flex;
              align-items: center;
              gap: 12px;
              margin-bottom: 9px;

              .greenspan {
                color: var(--btn-green-bg);
                font-size: 10px;
                font-style: normal;
                font-weight: 400;
                line-height: 120%;
                letter-spacing: -0.1px;
                border-radius: 4px;
                border: 1px solid var(--btn-green-bg);
                // background: rgba(40, 230, 100, 0.1);
                display: flex;
                padding: 4px 6px;
                justify-content: center;
                align-items: center;
                max-width: fit-content;
              }

              .redspan {
                border: 1px solid #fc4f52;
                // background: rgba(255, 131, 0, 0.1);
                color: var(--Red, #fc4f52);
                font-size: 10px;
                font-style: normal;
                font-weight: 400;
                line-height: 120%;
                display: flex;
                padding: 4px 6px;
                justify-content: center;
                align-items: center;
                letter-spacing: -0.1px;
                border-radius: 4px;
              }
            }

            .checkboxpara {
              color: var(--V6-Dark-Theme-Secondary-Text-Color);

              font-size: 11px;
              font-style: normal;
              font-weight: 500;
              line-height: 100%;
              /* 11px */
              text-transform: uppercase;
            }
          }
        }

        .claimalllefthead {
          margin-bottom: 20px;
          color: var(--heading-text);
          font-family: "f37";
          font-size: 20px;
          font-style: normal;
          font-weight: 500;
          line-height: 140%;
          /* 28px */
        }

        .claimtoken {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 15px;
          cursor: pointer;

          &:last-child {
            margin-bottom: 0px;
          }

          .claimtokenleft {
            display: flex;
            align-items: center;
            gap: 20px;
            justify-content: flex-start;

            .claimtokenimg {
              display: flex;
              width: 66px;
              height: 66px;
              justify-content: center;
              align-items: center;
              background: transparent;
              object-fit: cover;
              object-position: center;
              border-radius: 5px;

              .claiminnerimg {
                width: 100%;
                height: 100%;
                background: transparent;
                object-fit: cover;
                object-position: center;
              }
            }

            .tokenlefthead {
              color: var(--heading-text);
              font-family: "hregular";
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: 140%;
              /* 22.4px */
            }
          }

          .claimtokenrighthead {
            color: var(--heading-text);
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 140%;
            text-align: end;

            .xspan {
              font-weight: 400 !important;
            }
          }
        }
      }
    }

    .claimallright {
      //   border-radius: 12px;
      border-radius: 4px;
      background: var(--claimcard-bg);
      //   box-shadow: 0px -7px 0px 0px rgba(0, 0, 0, 0.04) inset;
      padding: 15px;
      width: 100%;
      // max-width: 543px;

      .claimallrighthead {
        color: var(--heading-text);
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 140%;
        margin-bottom: 20px;
        font-family: "f37";
      }

      .checkboxmain {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 15px;
        margin-bottom: 40px;

        .clear {
          clear: both;
        }

        .checked {
          box-shadow: 0px 0px 0px 2px #fff !important;
          transition: 300ms ease;
        }

        .checkBox {
          display: block;
          cursor: pointer;
          width: 24px;
          height: 23px;
          position: relative;
          overflow: hidden;
          transition: 300ms ease;
          border-radius: 5px;
          border: 1.5px solid #77868b;
          background: var(--claimcard-bg);
        }

        .checkBox div {
          width: 16px;
          height: 16px;
          border-radius: 2px;
          border-radius: 2px;
          background: var(--nav-active);
          top: -52px;
          left: -52px;
          position: absolute;
          z-index: 100;
        }

        .checkBox input[type="checkbox"]:checked + div {
          left: 2.8px;
          top: 3px;
        }

        .checkBox:has(input:checked) {
          border: 1.5px solid #329879;
        }

        .checkBox input[type="checkbox"] {
          position: absolute;
          left: 50px;
          visibility: hidden;
        }

        .transition {
          transition: 300ms ease;
        }

        /* Add this for the border color change on checked */
        .checkBox input[type="checkbox"]:checked ~ .checkBox {
          border-color: #329879;
        }

        .maincheckboxpara {
          color: var(--V6-Dark-Theme-Secondary-Text-Color);
          font-size: 13px;
          font-style: normal;
          font-weight: 400;
          line-height: 100%;
          margin-top: -4px;
        }
      }

      .summarymain {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 14px;

        .summarypara {
          color: var(--V6-Dark-Theme-Secondary-Text-Color);
          // font-family: "Helvetica Now Display";
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 140%;
          /* 22.4px */
        }

        .summaryhead {
          color: var(--heading-text);
          font-family: "f37";
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 140%;
          /* 22.4px */
        }
      }

      .totalmain {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        padding: 20px 0px 40px;
        border-top: 1px solid rgba(61, 61, 61, 0.1);
        margin-top: 24px;

        .totalpara {
          color: var(--heading-text);
          font-family: "hregular";
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 140%;
          /* 22.4px */
        }

        .totalhead {
          color: var(--heading-text);
          font-family: "f37";
          font-size: 18px;
          font-style: normal;
          font-weight: 500;
          line-height: 140%;
          /* 25.2px */
          text-align: end;
        }
      }

      .claimbtn {
        border-radius: 6px;
        background: var(--btn-green-bg);
        display: flex;
        padding: 17px 25px;
        justify-content: center;
        align-items: center;
        color: var(--btn-green-text);
        /* font-family: "Helvetica Now Display"; */
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 100%;
        /* 14px */
        text-transform: uppercase;
        width: 100%;
        border: none;
        transition: 0.3s linear;

        &.disable {
          opacity: 0.2;
          pointer-events: none;
        }

        &:hover {
          background: var(--btn-green-bg-hover);
        }

        &:active {
          box-shadow: 0px 0px 10px 8px var(--btn-green-bg-active);
        }
      }
    }
  }
}

.tablemodal {
  .modal-body {
    padding: 0;
  }
  .modal-dialog {
    max-width: 749px;

    .modal-header {
      margin-bottom: 30px;
      background: var(--claimcard-bg);
    }

    .mainrewardsdiv {
      border: 1px solid #fff;

      .rewardmain {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .rewardinnerpara {
          display: flex;
          padding: 28px 30px;
          align-items: center;
          justify-content: flex-start;
          width: 100%;
          background: #000;
          color: #fff;
          font-size: 14px;
          font-style: normal;
          font-weight: 700;
          line-height: 24px;
          letter-spacing: 0.28px;
          text-transform: uppercase;
          backdrop-filter: blur(3px);
        }
      }
    }

    .datatable {
      .main-heading {
        margin: 30px 0;

        h6 {
          color: #fff;
          font-size: 22px;
          font-style: normal;
          font-weight: 700;
          line-height: 24px;
          letter-spacing: 0.44px;
        }
      }

      .table-responsive {
        // border-radius: 12px;
        // border: 1px solid ;

        background: var(--claimcard-bg);

        &::-webkit-scrollbar {
          display: none !important;
        }

        .table {
          margin: 0;
        }

        th {
          font-family: "f37";
          color: var(--V6-Dark-Theme-Secondary-Text-Color);

          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 100%;
          /* 14px */
          letter-spacing: 0.28px;
          text-transform: uppercase;

          vertical-align: middle !important;

          background: var(--claimcard-bg);
          padding: 28px 30px;
          backdrop-filter: blur(3px);
          white-space: nowrap;
          border: none;
        }

        td {
          color: var(--heading-text);
          font-size: 14px;
          font-style: normal;
          font-family: "hmedium";
          line-height: 24px;
          letter-spacing: 0.28px;
          text-transform: uppercase;
          vertical-align: middle !important;

          background: var(--claimcard-bg);
          padding: 28px 30px;
          backdrop-filter: blur(3px);
          white-space: nowrap;
          border: none;
          border-top: 1px solid var(--border);

          .twice-text {
            h6 {
              color: #000;
              font-size: 12px;
              font-style: normal;
              font-weight: 700;
              line-height: 21px;
              /* 175% */
              letter-spacing: 0.24px;
            }

            p {
              color: #000;
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: 21px;
              /* 175% */
              letter-spacing: 0.24px;
              margin-top: 2px;
            }
          }

          .forend {
            display: flex;
            justify-content: flex-end;
            align-items: center;

            .common-btn {
              padding: 13px 25px;
              font-weight: 600;
              font-size: 16px;
              line-height: 100%;
            }
          }
        }

        .footer-content {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 14px 20px 13px 20px;

          background: var(--claimcard-bg);
          //   box-shadow: 0px -7px 0px 0px rgba(0, 0, 0, 0.04) inset;
          backdrop-filter: blur(3px);

          .left-f {
            h6 {
              color: #77868b;
              font-size: 14px;
              font-style: normal;
              font-family: "hmedium";
              line-height: 100%;
            }
          }

          .right-f {
            .page-link:focus {
              box-shadow: none;
              background-color: unset;
            }

            .page-link:hover {
              box-shadow: none;
              background-color: unset;
            }

            .page-link {
              border: none;
              color: #a2a4a6;
              font-size: 15px;
              font-style: normal;
              font-weight: 700;
              line-height: 100%;
              background-color: transparent;

              &.active {
                background: #fff;
                color: #000;
              }
            }

            .page-item:first-child .page-link {
              color: #a2a4a6;
              font-size: 15px;
              font-style: normal;
              font-weight: 700;
              line-height: 100%;
            }
          }
        }
      }

      .dropdown {
        .dropdown-toggle {
          background-color: transparent;
          border: none;

          &::after {
            display: none;
          }
        }

        .dropdown-menu {
          border: 1px solid #fff;
          background: #000;
          box-shadow: 0px 3px 30px 0px rgba(0, 0, 0, 0.05);
          padding: 0;

          a {
            color: #fff;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            gap: 10px;
            padding: 14px 15px;
          }
        }
      }
    }

    .filter-btn {
      display: flex;
      flex-direction: column;
      width: 11px;
      margin-left: 10px;
    }

    .parent-tag {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      color: var(--V6-Dark-Theme-Secondary-Text-Color);
      font-family: "f37";
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 100%;
      /* 14px */
      letter-spacing: 0.28px;
      text-transform: uppercase;
    }

    .page-item.active {
      .page-link {
        border-radius: 5px;
        background: var(--Disable-Button-BG, #073133) !important;
      }
    }
  }
}

@media (max-width: 992px) {
  .claimallmain .claimallinner {
    flex-direction: column;
  }

  .claimallmain .claimallinner .claimallleft {
    max-width: 100%;
  }

  .claimallmain .claimallinner .claimallright {
    max-width: 100%;
    position: unset;
  }
}

@media (max-width: 650px) {
  .tablemodal .modal-dialog .mainrewardsdiv .rewardmain .rewardinnerpara {
    padding: 0px;
  }

  .tablemodal .modal-dialog .mainrewardsdiv .rewardmain {
    width: 100%;
  }

  .tablemodal .modal-dialog .mainrewardsdiv {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    gap: 10px;
    padding: 28px 18px;
  }

  .tablemodal .table-responsive {
    display: none;
  }

  .accmblview {
    display: block !important;
    border: 1px solid var(--border);
    background: var(--claimcard-bg);
    backdrop-filter: blur(3px);

    .main-twice-text {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 28px 25px;

      .accmblviewhead {
        color: var(--V6-Dark-Theme-Primary-Text-Color);
        font-size: 14px;
        font-style: normal;
        font-family: "f37";
        line-height: 24px;
        letter-spacing: 0.28px;
        text-transform: uppercase;
      }
    }

    .accordion {
      border: none !important;
      background: transparent !important;
      box-shadow: none !important;
      outline: none !important;
      border-radius: none !important;

      .accordion-button::after {
        background: url("../../assets/arrow.svg");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        width: 8.571px;
        height: 5.143px;
        position: absolute;
        top: 28px;
        right: 25px;
      }

      .accordion-item {
        border: none !important;
        background: transparent !important;
        box-shadow: none !important;
        outline: none !important;
        border-radius: none !important;

        .accordion-header {
          border: none !important;
          background: transparent !important;
          box-shadow: none !important;
          outline: none !important;
          border-radius: none !important;

          .accordion-button {
            border-top: 1px solid var(--border);
            border-bottom: 1px solid var(--border);
            background: var(--claimcard-bg);
            padding: 26px 25px;

            &:focus {
              box-shadow: none;
            }

            .accheadermain {
              display: flex;
              justify-content: flex-start;
              align-items: center;
              width: 100%;

              .acctext {
                color: var(--heading-text);
                font-size: 14px;
                font-style: normal;
                font-family: "hmedium";
                line-height: 100%;
              }
            }
          }
        }

        .accordion-body {
          border: none !important;
          background: transparent !important;
          box-shadow: none !important;
          outline: none !important;
          border-radius: none !important;
          padding: 0px 25px;

          .acctexts {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 20px 0px;
            border-bottom: 1px solid var(--border);

            &:last-child {
              margin-bottom: 0px;
            }

            .textleft {
              color: var(--heading-text);
              font-size: 14px;
              font-style: normal;
              font-family: "hmedium";
              line-height: 100%;
              letter-spacing: 0.28px;
              text-transform: uppercase;
            }

            .textright {
              color: var(--heading-text);
              font-size: 14px;
              font-style: normal;
              font-family: "hmedium";
              line-height: 21px;
              letter-spacing: 0.28px;
            }
          }
        }
      }
    }

    .twice-text {
      h6 {
        color: var(--heading-text);
        font-size: 12px;
        font-style: normal;
        font-family: "hmedium";
        line-height: 21px;
        /* 175% */
        letter-spacing: 0.24px;
      }

      p {
        color: var(--heading-text);
        font-size: 12px;
        font-style: normal;
        font-family: "hregular";
        line-height: 21px;
        /* 175% */
        letter-spacing: 0.24px;
        margin-top: 2px;
      }
    }

    .view-btn {
      border: 1px solid #fff;
      background-color: transparent;
      padding: 13px;
      color: #fff;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 100%;
      /* 14px */
      width: 100%;
    }
  }

  .accmblview .accordion .accordion-item .accordion-header .accordion-button {
    box-shadow: none !important;
  }

  .footer-content {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    // border-top: 1px solid #F2F2F2;
    background-color: transparent;

    .left-f {
      display: none;
      h6 {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 100%;
        color: #a0a1a1;
      }
    }

    .right-f {
      .page-link:focus {
        box-shadow: none;
        background-color: unset;
      }

      .page-link:hover {
        box-shadow: none;
        background-color: unset;
      }

      .page-link {
        border: none;
        color: #a2a4a6;
        font-size: 15px;
        font-style: normal;
        font-weight: 700;
        line-height: 100%;
        /* 15px */
        background-color: transparent;

        &.active {
          background: #fff;
          color: #000;
        }
      }

      .page-item:first-child .page-link {
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        line-height: 100%;
        color: #a0a1a1;
      }
    }
  }

  .dashboardetail .auctiondetailhead {
    font-size: 20px;
  }
}

@media (max-width: 600px) {
  .claimallmain
    .claimallinner
    .claimallleft
    .claimallleftinner
    .claimalllefthead {
    font-size: 16px;
  }

  .claimallmain
    .claimallinner
    .claimallleft
    .claimallleftinner
    .claimtoken
    .claimtokenleft
    .tokenlefthead {
    font-size: 12px;
  }

  .claimallmain
    .claimallinner
    .claimallleft
    .claimallleftinner
    .claimtoken
    .claimtokenleft {
    gap: 15px;
  }

  .claimallmain
    .claimallinner
    .claimallleft
    .claimallleftinner
    .claimtoken
    .claimtokenleft
    .claimtokenimg {
    height: 50px;
    width: 50px;
  }

  .claimallmain
    .claimallinner
    .claimallleft
    .claimallleftinner
    .claimtoken
    .claimtokenrighthead {
    font-size: 12px;
  }

  .claimallmain
    .claimallinner
    .claimallleft
    .claimallleftinner
    .checkboxmain
    .checkboxtexts
    .checkboxhead {
    font-size: 12px;
  }

  .claimallmain .claimallinner .claimallright .claimallrighthead {
    font-size: 16px;
  }

  .claimallmain .claimallinner .claimallright .summarymain .summarypara {
    font-size: 12px;
  }

  .claimallmain .claimallinner .claimallright .summarymain .summaryhead {
    font-size: 12px;
  }

  .claimallmain .claimallinner .claimallright .totalmain .totalpara {
    font-size: 12px;
  }

  .claimallmain .claimallinner .claimallright .totalmain .totalhead {
    font-size: 14px;
  }

  .claimallmain .claimallinner .claimallright .checkboxmain .maincheckboxpara {
    font-size: 12px;
  }
}

.bottom-twice-elem {
  position: sticky;
  top: 80px;
  width: 100%;

  .important-div {
    border-radius: 4px;
    background: rgba(232, 143, 53, 0.15);
    padding: 20px;
    margin-top: 8px;
    padding-right: 100px;

    h6 {
      color: #e88f35;
      font-size: 16px;
      font-style: normal;
      font-family: "f37";
      line-height: 140%;
      margin-bottom: 10px;
    }

    p {
      color: #e88f35;
      font-size: 14px;
      font-style: normal;
      font-family: "hregular";
      line-height: 140%;
    }
  }
}

.success-modal {
  .buy-modal {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    p {
      color: var(--heading-text);
      text-align: center;
      font-family: "f37";
      font-size: 22px;
      font-style: normal;
      line-height: 120%;
      margin-top: 20px;
      margin-bottom: 40px;
    }

    .btn-common {
      border-radius: 4px;
      background: var(--btn-green-bg);
      border: none;
      padding: 16px 14px 16px 19px;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      color: var(--btn-green-text);
      text-align: center;
      font-size: 14px;
      font-style: normal;
      font-family: "hmedium";
      line-height: 120%;
      transition: 0.3s linear;

      &:hover {
        background: var(--btn-green-bg-hover);
      }

      &:active {
        box-shadow: 0px 0px 10px 8px var(--btn-green-bg-active);
      }
    }
  }
}

.claimable-thistime {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 20px;
  margin-bottom: 20px;
  border-top: 1px solid var(--border);

  .left-text {
    h6 {
      color: var(--V6-Dark-Theme-Primary-Text-Color);
      font-size: 16px;
      font-style: normal;
      font-family: "hbold";
      line-height: 140%;
      margin-bottom: 8px;
    }

    p {
      color: var(--V6-Dark-Theme-Secondary-Text-Color);
      font-size: 14px;
      font-style: normal;
      font-family: "hregular";
      line-height: 140%;
    }
  }

  .right-text {
    h5 {
      color: var(--heading-text);
      font-family: "f37";
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 140%;
    }
  }
}
