.wrapper-education {
    overflow: hidden;

    .main-eductionbanner {
        height: 400px;
        position: relative;
        display: flex;
        align-items: center;

        .leftshade {
            position: absolute;
            bottom: 0;
            left: 0;
            z-index: -1;
        }

        .rightshade {
            position: absolute;
            bottom: 0;
            right: 0;
            z-index: -1;
        }

        .main-content {

            .contentheads {
                .upper-text {
                    color: var(--btn-green-bg);
                    font-family: "bl";
                    font-size: 32px;
                    font-style: normal;
                    line-height: 140%;
                    margin-bottom: 10px;
                }

                .lower-text {
                    color: var(--heading-text);
                    font-family: "f37";
                    font-size: 55px;
                    font-style: normal;
                    line-height: 110%;
                    text-transform: uppercase;

                    span {
                        color: var(--heading-text);
                        font-family: "bl";
                        font-size: 55px;
                        font-style: normal;
                        line-height: 110%;
                        text-transform: uppercase;
                    }
                }
            }
        }
    }

    .maineductionheading {
        border-bottom: 1px solid var(--border2);
        padding: 30px 0px;

        .innerheading {
            color: var(--text-color2);
            font-family: "f37";
            font-size: 26px;
            font-style: normal;
            line-height: 100%;

            span {
                color: var(--text-color2);
                font-family: "bl";
                font-size: 26px;
                font-style: normal;
                line-height: 100%;
            }
        }
    }

    .education-section {
        padding-top: 50px;
        padding-bottom: 120px;

        .bottom-content {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            gap: 15px;

            .single-card {
                cursor: pointer;

                .main-img {
                    border-radius: 5.714px;
                    border: 1.429px solid var(--border2);
                    background: var(--body-bg);
                    position: relative;
                    height: 242px;
                    width: 100%;
                    object-fit: cover;
                    object-position: center;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-bottom: 11px;
                    overflow: hidden;

                    .playbtn {
                        color: #01141A;
                        font-size: 14px;
                        font-style: normal;
                        font-family: 'hmedium';
                        line-height: 100%;
                        display: flex;
                        padding: 10px;
                        align-items: center;
                        justify-content: center;
                        gap: 7px;
                        border-radius: 4px;
                        background: #30F1B6;
                        border: none;
                        position: absolute;
                        left: 10px;
                        bottom: 10px;
                    }

                    .poster-video {
                        height: 100%;
                        width: 100%;
                        object-fit: cover;
                        object-position: center;
                    }
                }

                h6 {
                    color: var(--heading-text);
                    font-family: 'hmedium';
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 120%;
                }
            }
        }
    }
}

@media (max-width:992px) {
    .wrapper-education .education-section .bottom-content {
        grid-template-columns: 1fr 1fr;
    }
}

@media (max-width:768px) {
    .wrapper-education .education-section .bottom-content {
        grid-template-columns: 1fr;
    }
}

@media (max-width:600px) {
    .wrapper-education .main-eductionbanner .main-content .contentheads .lower-text {
        font-size: 34px;

        span {
            font-size: 34px;
        }
    }

    .wrapper-education .main-eductionbanner .main-content .contentheads .upper-text {
        font-size: 26px;
    }

    .wrapper-education .maineductionheading .innerheading {
        font-size: 22px;
    }

    .wrapper-education .education-section {
        padding-top: 30px;
    }

    .wrapper-education .education-section .bottom-content {
        gap: 30px;
    }

    .wrapper-education .main-eductionbanner .leftshadembl {
        display: block !important;
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: -1;
    }

    .wrapper-education .main-eductionbanner .rightshadembl {
        display: block !important;
        position: absolute;
        bottom: 0;
        right: 0;
        z-index: -1;
    }

    .wrapper-education .main-eductionbanner .leftshade {
        display: none !important;
    }

    .wrapper-education .main-eductionbanner .rightshade {
        display: none !important;
    }
}