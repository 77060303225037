.miners-join {
    border-top: 1px solid var(--border2);
    border-bottom: 1px solid var(--border2);
    // background: rgba(8, 21, 25, 0.50);
    padding: 60px 0;
    position: relative;
    .join-bg{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        z-index: -1;
        pointer-events: none;
    }

    .main-content {
        max-width: 654px;
        width: 100%;
        margin: 0 auto;

        h4 {
            color: var(--heading-text);
            font-size: 50px;
            font-style: normal;
            font-weight: 500;
            line-height: 119%;
            text-align: center;
        }

        p {
            color: var(--heading-text);
            text-align: center;
            font-size: 20px;
            font-style: normal;
            font-family: "hmedium";
            line-height: 120%;
            margin-top: 30px;
            margin-bottom: 60px;
        }

        .twice-btns {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 15px;

            a {
                padding: 18px 22px;
                min-width: 180px;
                display: flex;
                justify-content: center;
                align-items: center;

            }
        }
    }
}

@media (max-width:600px){
    .miners-join .main-content h4{
        font-size: 32px;
    }
    .miners-join .main-content p{
        font-size: 16px;
    }
    .miners-join .main-content .twice-btns{
        display: grid;
        grid-template-columns: 1fr;
    }
}

.light-theme .nodesminers .main-banner-video{
    mix-blend-mode: difference;
}