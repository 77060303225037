.trade-section {
    padding: 60px 0;
    position: relative;
    z-index: 98;
    border-top: 1px solid var(--border2);
    border-bottom: 1px solid var(--border2);
    // background: #09181D;

    .tarde-bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        z-index: -1;
        pointer-events: none;
    }

    .parent {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 100%;
        gap: 108px;

        .left-side {
            max-width: 570px;
            width: 100%;

            .main-content {
                .comingsoon-text {
                    border-radius: 54px;
                    background: var(--coming-bg);
                    padding: 12px 16px;
                    display: inline;
                    color: var(--btn-green-bg);
                    font-size: 14px;
                    font-style: normal;
                    font-family: "hmedium";
                    line-height: 100%;
                }

                h4 {
                    color: var(--heading-text);
                    font-size: 53px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 100%;
                    margin-top: 20px;
                    margin-bottom: 37px;
                }

                p {
                    color: var(--V6-Dark-Theme-Secondary-Text-Color, #939FA3);
                    font-size: 16px;
                    font-style: normal;
                    font-family: "hregular";
                    line-height: 140%;
                    margin-bottom: 60px;
                }

                .multi-options {
                    display: grid;
                    grid-template-columns: 1fr 1fr;
                    // padding-bottom: 60px;
                    gap: 15px;

                    .in-flex {
                        display: flex;
                        align-items: center;
                        gap: 10px;

                        svg {
                            flex-shrink: 0;

                            path {
                                fill: var(--heading-text);
                            }
                        }

                        h6 {
                            color: var(--heading-text);
                            font-size: 14px;
                            font-style: normal;
                            font-family: "hmedium";
                            line-height: 130%;
                        }
                    }
                }

                .twice-btn {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    gap: 10px;

                    a {
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        gap: 8px;
                        border-radius: 6px;
                        border: 1px solid var(--border2);
                        background: var(--background);
                        padding: 8px;

                        .text {
                            p {
                                margin: 0;
                                font-size: 12px;
                                color: var(--heading-text);
                            }

                            h5 {
                                margin: 0;
                                font-size: 18px;
                                color: var(--heading-text);
                            }
                        }
                    }
                }
            }
        }

        .right-side {
            flex: 1;
            height: 100%;

            .wrapper-img {
                height: 100%;
            }
        }

    }
}

@media (max-width:600px) {
    .trade-section {
        padding: 60px 0;
    }

    .trade-section .parent .left-side {
        padding: 0;
    }

    .trade-section .parent {
        flex-direction: column;
        gap: 60px;
    }

    .trade-section .parent .left-side .main-content h4 {
        font-size: 34px;
    }

    .trade-section .parent .left-side {
        max-width: 100%;
    }

}