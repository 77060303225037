.mainfaqs {
  min-height: 100vh;
  overflow: hidden;

  .innerfaq {

    .head {
      max-width: 1066px;
      width: 100%;
      margin: 30px auto;
      color: var(--heading-text);
      font-family: "f37";
      font-size: 30px;
      font-style: normal;
      line-height: 100%;
      padding: 0 15px;
    }

    .accordion {
      border: none;
      background-color: transparent;
      border-radius: 0;
      outline: none;
      box-shadow: none;
      --bs-accordion-border-color: transparent;
      --bs-accordion-border-width: 0;

      .active {
        background: transparent !important;
      }

      .accordion-item {
        border-radius: 0px;
        border-bottom: 1px solid var(--border2);
        background: transparent;
        margin-bottom: 0px;

        .accordion-header {
          border-bottom: none !important;
          padding: 0px;
          border: none;
          background-color: transparent;
          border-radius: 0;
          outline: none;
          box-shadow: none;

          // .accordion-button:not(.collapsed)::after {
          //   background: url("../../../src/assets/minus.svg");
          //   background-repeat: no-repeat;
          //   background-position: unset;
          //   background-size: cover;
          //   margin: 0;
          //   padding: 0;
          //   width: 24px;
          //   height: 24px;

          // }

          .accordion-button::after {
            background: url("../../../src/assets/arrow.svg");
            background-repeat: no-repeat;
            background-position: unset;
            background-size: cover;
            margin: 0;
            padding: 0;
            width: 14.465px;
            height: 7px;
            padding: 0;
            margin: 0;
          }

          .accordion-button {
            border-radius: 0px;
            box-shadow: none !important;
            padding: 30px;
            display: flex;
            align-items: center;
            background: transparent;
            border: none;
            border-radius: 0px;
            outline: none;
            justify-content: space-between;
            max-width: 1066px;
            margin: 0 auto;
            width: 100%;


            p {
              color: var(--heading-text);
              font-size: 18px;
              font-style: normal;
              line-height: 120%;
              font-family: 'hmedium';
              gap: 8px;
              display: flex;
              align-items: center;
              max-width: 700px;

              span {
                color: var(--btn-green-bg);
                font-size: 18px;
                font-style: normal;
                font-family: 'hbold';
                line-height: 120%;
              }
            }
          }
        }
      }

      .accordion-body {
        padding: 0px 30px 30px 30px;
        max-width: 1066px;
        margin: 0 auto;
        width: 100%;

        .para {
          color: var(--heading-text);
          font-family: "hregular";
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 130%;
          max-width: 700px;
        }

        ol {
          li {
            color: var(--heading-text);
            font-family: "hregular";
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 130%;
            max-width: 700px;
            margin: 10px 0;
          }
        }
        ul {
          margin-left: 25px;
          li {
            color: var(--heading-text);
            font-family: "hregular";
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 130%;
            max-width: 700px;
            list-style-type: disc !important;
            margin: 10px 0;
            
          }
        }
      }
    }
  }

}

.main-faqsbanner {
  height: 400px;
  position: relative;
  display: flex;
  align-items: center;

  .leftshade {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: -1;
  }

  .rightshade {
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: -1;
  }

  .main-content {

    .contentheads {
      .upper-text {
        color: var(--btn-green-bg);
        font-family: "bl";
        font-size: 32px;
        font-style: normal;
        line-height: 140%;
        margin-bottom: 10px;
      }

      .lower-text {
        color: var(--heading-text);
        font-family: "f37";
        font-size: 55px;
        font-style: normal;
        line-height: 110%;
        text-transform: uppercase;

        span {
          color: var(--heading-text);
          font-family: "bl";
          font-size: 55px;
          font-style: normal;
          line-height: 110%;
          text-transform: uppercase;
        }
      }

      .faqspara {
        color: var(--heading-text);
        font-family: 'hregular';
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%;
        max-width: 362px;
        width: 100%;
        margin-top: 25px;
      }
    }
  }
}

@media(max-width:992px) {
  .main-faqsbanner .main-content .contentheads .lower-text {
    font-size: 36px;

    span {
      font-size: 36px;
    }
  }

  .main-faqsbanner .main-content .contentheads .faqspara {
    margin-top: 20px;
  }

  .main-faqsbanner .leftshadembl {
    display: block !important;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: -1;
  }

  .main-faqsbanner .rightshadembl {
    display: block !important;
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: -1;
  }

  .main-faqsbanner .leftshade {
    display: none !important;
  }

  .main-faqsbanner .rightshade {
    display: none !important;
  }
}


.light-theme .mainfaqs .innerfaq .accordion .accordion-item .accordion-header .accordion-button::after {
  background: url("../../../src/assets/arrow-black.svg");
}