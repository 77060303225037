.process-projects {
    position: relative;
    z-index: 99;

    .bottom-cards {

        .slick-slide {
            &:last-child {
                .single-card {
                    border-right: 1px solid var(--border2) !important;

                }
            }
           
        }

        position: relative;

        .cardshadow-light {
            position: absolute;
            top: 0;
            right: -2px;
            z-index: 1;
            pointer-events: none;
            height: 100%;
        }

        .cardshadow-lightleft {
            position: absolute;
            top: 0;
            left: -2px;
            z-index: 1;
            pointer-events: none;
            transform: rotate(180deg);
            height: 100%;
        }




        .single-card {
            border-top: 1px solid var(--border2);
            border-left: 1px solid var(--border2);
            background: var(--token-card-bg);
            backdrop-filter: blur(20px);
            padding: 25px;
            transition: 0.3s linear;

            &:hover {
                background: rgba(10, 28, 34, 0.97);
                backdrop-filter: blur(20px);
            }


            .main-token-img {
                margin-bottom: 15px;
                border-radius: 1000px;
                border: 1px solid #142125;
                background: #081519;
                width: 70px;
                height: 70px;
                border-radius: 50%;
                overflow: hidden;
                img{
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }

            .token-info {
                padding-bottom: 20px;
                border-bottom: 1px solid var(--border2);

                h6 {
                    color: var(--heading-text);
                    font-size: 20px;
                    font-style: normal;
                    font-family: "hmedium";
                    line-height: 120%;
                    margin-bottom: 5px;
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    gap: 8px;
                    word-wrap: break-word;
                    overflow-wrap: break-word;
                    word-break: break-word;
                    white-space: normal;

                    span {
                        border-radius: 50px;
                        border: 1px solid var(--btn-green-bg);
                        padding: 6px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        gap: 4px;
                        color: var(--btn-green-bg);
                        font-size: 12px;
                        font-style: normal;
                        font-family: "hregular";
                        line-height: 100%;

                        svg {
                            path {
                                fill: var(--btn-green-bg);
                            }
                        }
                    }
                }

                .sub-title {
                    color: var(--V6-Dark-Theme-Secondary-Text-Color, #939FA3);
                    font-size: 12px;
                    font-style: normal;
                    font-family: "hregular";
                    line-height: 120%;
                    margin-bottom: 15px;
                }

                .para {
                    color: var(--V6-Dark-Theme-Secondary-Text-Color, #939FA3);
                    font-size: 14px;
                    font-style: normal;
                    font-family: "hlight";
                    line-height: 120%;
                    min-height: 35px;
                    word-wrap: break-word; 
                    overflow-wrap: break-word; 
                    word-break: break-word; 
                    white-space: normal;
                    min-height: 50px;
                }
            }

            .tokenprice-info {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 20px 0;
                border-bottom: 1px solid var(--border2);

                .text {
                    p {
                        color: var(--V6-Dark-Theme-Secondary-Text-Color, #939FA3);
                        font-size: 12px;
                        font-style: normal;
                        font-family: "hregular";
                        line-height: 120%;
                        margin-bottom: 10px;
                    }

                    h6 {
                        color: var(--heading-text);
                        font-size: 14px;
                        font-style: normal;
                        font-family: "hmedium";
                        line-height: 120%;
                    }
                }

            }

            .round-info {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 20px 0;

                h6 {
                    &:first-child {
                        color: var(--heading-text);
                        font-size: 14px;
                        font-style: normal;
                        font-family: "hregular";
                        line-height: 120%;
                    }

                    &:last-child {
                        color: var(--V6-Dark-Theme-Green-Color, #30F1B6);
                        font-size: 14px;
                        font-style: normal;
                        font-family: "hmedium";
                        line-height: 120%;

                        &.ended {
                            color: var(--btn-green-bg);
                        }

                        &.upcoming {
                            color: #F18030;
                        }
                    }
                }
            }

            .btn-buy {
                border-radius: 6px;
                border: 1px solid var(--btn-buy-border);
                background: var(--btn-buy-bg);
                padding: 18px 22px;
                color: var(--btn-buy-text);
                font-size: 14px;
                font-style: normal;
                font-family: "hregular";
                line-height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                transition: 0.3s linear;

                &:hover {
                    background: var(--btn-buy-bg-hover);
                    border: 1px solid transparent;
                }

                &:active {
                    background: var(--btn-buy-bg-hover);
                    border: 1px solid var(--btn-green-bg);
                }
            }


        }
    }
    .slick-next {
        right: 0;
        top: -50px;
    }

    .slick-prev {
        right: 50px;
        top: -50px;
        left: unset !important;
    }

    .slick-prev,
    .slick-next {
        z-index: 9999 !important;
    }
}





.slick-prev,
.slick-next {
    // display: none;

    &::before {
        display: none;
    }

    width: auto;
    height: auto;
}

.slick-prev {
    left: -60px;
}

.slick-next {
    right: -60px;
}

@media (max-width:1024px) {

    .slick-prev,
    .slick-next {
        display: none;
    }
}


@media (max-width:600px) {
    .process-projects {
        margin-top: -80px;
    }
    .process-projects .bottom-cards .cardshadow-light{
        display: none !important;
    }
    .process-projects .bottom-cards .cardshadow-lightleft{
        display: none !important;
    }
}

.light-theme .process-projects .bottom-cards .single-card:hover {
    background-color: transparent;
}

.description {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    /* Restrict to 3 lines */
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    max-height: calc(1.5em * 2);
    /* Adjust for line height */
    line-height: 1.5em;
    /* Line height */
}

.light-theme .process-projects .bottom-cards .single-card {
    background: var(--token-card-bg);
}


@media (max-width:390px){
    .process-projects.mineproject-cards .bottom-cards .single-card .token-info .para{
        font-size: 12px !important;
    }
    .process-projects.mineproject-cards .bottom-cards .single-card .token-info .para{
        min-height: 60px;
    }
}