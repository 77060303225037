.main-bannernodesminers {
  padding-bottom: 200px;

  .parent {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    gap: 75px;

    .left-side {
      max-width: 599px;
      width: 100%;
    }

    .right-side {
      flex: 1;

      .wrapper-img {
        border-radius: 10px;
        box-shadow: 0px 0px 20px 0px rgba(3, 22, 16, 0.21);
        overflow: hidden;
        width: 100%;
        height: 100%;

        video {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 10px;
          // pointer-events: none;
        }
      }
    }
  }

  .main-content {
    h5 {
      color: var(--btn-green-bg);
      font-family: "f37";
      font-size: 35px;
      font-style: normal;
      font-weight: 500;
      line-height: 110%;
      text-transform: capitalize;
      margin-bottom: 10px;
    }

    p {
      font-size: 20px;
    }

    h4 {
      font-size: 60px;
    }

    .twice-btns {
      a {
        min-width: 150px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}

@media (max-width: 600px) {
  .main-bannernodesminers {
    min-height: 95vh;
    z-index: unset !important;
  }

  .main-bannernodesminers .main-content h5 {
    font-size: 28px;
  }

  // .main-bannernodesminers .main-content h4{
  //     font-size: 32px;
  // }

  .main-bannernodesminers .parent {
    flex-direction: column;
    gap: 25px;
  }

  .main-bannernodesminers .main-content .twice-btns {
    grid-template-columns: 1fr;
  }

  .main-bannernodesminers .main-content h4 {
    font-size: 34px;
  }

  .main-bannernodesminers .main-content p {
    font-size: 16px;
  }

  .main-bannernodesminers {
    padding-bottom: 130px;
  }
}

.timer-set-miners {
  border-radius: 4px;
  border: 1px solid var(--border2);
  background: var(--background3);
  padding: 18px;
  max-width: 245px;
  width: 100%;

  h6 {
    width: 100%;
    color: var(--btn-green-bg);
    font-family: "f37";
    font-size: 16px;
    font-style: normal;
    line-height: 100%;
    text-transform: capitalize;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 12px;

    span {
      color: var(--heading-text);
      font-size: 16px;
      font-style: normal;
      font-family: "hregular";
      line-height: 100%;
      text-transform: capitalize;
    }
  }
}

@media (max-width:600px){
  .timer-set-miners{
    max-width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}


.whentimersrunclasss{
    a{
      min-width: 250px !important;
    }
}