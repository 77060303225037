.insurance-section {
    padding-top: 50px;
    padding-bottom: 100px;

    .insuranceinner {
        max-width: 760px;
        margin: 0 auto;
        width: 100%;

        .toppara {
            color: #000;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 140%;
            letter-spacing: -0.16px;
            margin-bottom: 30px;
        }

        .insurancehead {
            color: #000;
            font-family: "Space Grotesk";
            font-size: 18px;
            font-style: normal;
            font-weight: 700;
            line-height: 140%;
            letter-spacing: -0.18px;
            margin-bottom: 5px;
        }

        .marginsetttt{
            margin-bottom: 10px !important;
        }

        ul {
            margin-bottom: 30px;
            list-style-type: disc;
            list-style: outside;
            padding-left: 25px;

            li {
                color: #000;
                font-family: "Space Grotesk";
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 140%;
                letter-spacing: -0.16px;
                margin-bottom: 7px;
            }
        }

        ol {
            margin-bottom: 30px;
            list-style-position: outside;
            padding-left: 25px;
            margin-left: 0px;

            li {
                color: #000;
                font-family: "Space Grotesk";
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 140%;
                letter-spacing: -0.16px;
                margin-bottom: 10px;
            }
        }

        .midpara {
            color: #000;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 140%;
            letter-spacing: -0.16px;
            margin-bottom: 40px;

            .midparabold {
                font-weight: 700;
            }
        }

        .lastpara {
            color: #000;
            font-family: "Space Grotesk";
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 140%;
            letter-spacing: -0.16px;
        }
    }
}

@media(max-width:600px){
    .wrapper-apply .main-bannernew .insuranceheads{
        padding-top: 150px;
        padding-bottom: 30px;
    }
    .main-bannernew .main-contentss .insuranceheads span{
        font-size: 25px;
        svg{
            width: 28px;
        }
    }
    .main-bannernew .main-contentss .insuranceheads h3{
        font-size: 43px;
    }
}