.discover-section {
    background: var(--body-bg);
    padding: 60px 0;
    position: relative;
    z-index: 98;

    .discover-bg {
        position: absolute;
        bottom: -80%;
        right: 0;
        object-fit: cover;
        z-index: -1;
        pointer-events: none;
    }

    .parent {
        .main-content {
            max-width: 636px;
            width: 100%;

            h4 {
                color: var(--heading-text);
                font-size: 70px;
                font-style: normal;
                line-height: 100%;
                text-transform: capitalize;
            }

            p {
                color: var(--V6-Dark-Theme-Secondary-Text-Color, #939FA3);
                font-size: 16px;
                font-style: normal;
                font-family: "hregular";
                line-height: 140%;
                margin: 40px 0;
                max-width: 482px;
            }

            .btn-green {
                padding: 18px 22px;

            }
        }
    }
}

@media (max-width:600px) {
    .discover-section {
        padding-bottom: 352px;
    }

    .discover-section .parent .main-content h4 {
        font-size: 34px;
    }

    .discover-section .parent .main-content .btn-green {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .discover-section .discover-bg {
        bottom: -5%;

        @supports (-webkit-touch-callout: none) {
            bottom: -15%;
        }
    }

}