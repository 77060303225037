.wrapper-apply {
  .faqsheads {
    h3 {
      span {
        color: #fff;
        font-size: 40px;
        font-style: normal;
        font-weight: 500;
        line-height: 110%;
        text-transform: uppercase;
      }
    }
  }

  .main-bannernew {
    min-height: auto !important;

    .faqsheads {
      padding-top: 180px;
      padding-bottom: 220px;
    }

    .insuranceheads {
      padding-top: 240px;
      padding-bottom: 54px;
    }
  }

  .main-careerbanner {
    height: 400px;
    position: relative;
    display: flex;
    align-items: flex-end;
    padding-bottom: 45px;

    .leftshade {
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: -1;
      pointer-events: none;
      width: 100%;
    }

    .rightshade {
      position: absolute;
      bottom: 0;
      right: 0;
      z-index: -1;
      pointer-events: none;
    }

    .main-content {
      .contentheads {
        .upper-text {
          color: var(--btn-green-bg);
          font-family: "bl";
          font-size: 32px;
          font-style: normal;
          line-height: 140%;
          margin-bottom: 10px;
        }

        .lower-text {
          color: var(--heading-text);
          font-family: "f37";
          font-size: 55px;
          font-style: normal;
          line-height: 110%;
          text-transform: uppercase;

          span {
            color: var(--heading-text);
            font-family: "bl";
            font-size: 55px;
            font-style: normal;
            line-height: 110%;
            text-transform: uppercase;
          }
        }

        .faqspara {
          color: var(--heading-text);
          font-family: "hregular";
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 120%;
          max-width: 362px;
          width: 100%;
          margin-top: 25px;
        }
      }
    }
  }
}

.apply-section {
  position: relative;
  z-index: 99;
  background: var(--background6);

  .location-div {
    max-width: 200px;
    width: 100%;
    margin-bottom: 30px;

    h6 {
      color: var(--btn-green-bg) !important;
      font-size: 16px !important;
      font-style: normal;
      font-family: "hmedium";
      line-height: 100%;
      margin: 0 !important;
    }
  }

  .parent {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    border-top: 1px solid var(--border2);

    .left-side {
      max-width: 900px;
      width: 100%;
      padding: 30px 108px 0px 0px;
      border-right: 1px solid var(--border2);
      backdrop-filter: blur(20px);
      padding-bottom: 85px;

      .main-content {
        h4 {
          color: var(--heading-text);
          font-family: "f37";
          font-size: 24px;
          font-style: normal;
          line-height: 120%;
          text-transform: capitalize;

          span {
            color: var(--heading-text);
            font-family: "bl";
            font-size: 24px;
            font-style: normal;
            line-height: 120%;
            text-transform: capitalize;
          }
        }

        .para {
          color: var(--V6-Dark-Theme-Secondary-Text-Color);
          font-family: "hregular";
          font-size: 14px;
          font-style: normal;
          line-height: 120%;
          margin: 15px 0 50px;
        }

        .paranew {
          color: var(--V6-Dark-Theme-Secondary-Text-Color);
          font-family: "hregular";
          font-size: 18px;
          font-style: normal;
          line-height: 130%;
          margin-bottom: 25px;
        }

        .newheading {
          color: var(--heading-text);
          font-family: "f37";
          font-size: 26px;
          font-style: normal;
          font-weight: 500;
          line-height: 100%;
          text-transform: capitalize;
          margin-top: 40px;
          margin-bottom: 25px;
        }

        h6 {
          color: var(--heading-text);
          font-family: "f37";
          font-size: 18px;
          font-style: normal;
          font-weight: 500;
          line-height: 100%;
          text-transform: capitalize;
          margin-bottom: 10px;
        }

        ul {
          h6 {
            font-size: 18px !important;
          }

          li {
            color: var(--text-an);
            font-family: "hregular";
            font-size: 18px;
            font-style: normal;
            line-height: 130%;
            margin: 5px 0;
            list-style-type: disc;
            margin-left: 30px;
            margin-bottom: 15px;

            span {
              font-weight: 700;
            }
          }
        }
      }
    }

    .paddingless {
      padding-right: 60px !important;
    }

    .right-side {
      flex: 1;
      position: sticky;
      top: 15px;
      padding: 30px 0px 85px 60px;
      // border-left: 1px solid var(--border2);

      .signup-nav {
        padding-bottom: 0;
        border-radius: 4px;
        background: var(--background7);
        gap: 5px;
        border: none;
        display: inline-flex;
        border: none;
        margin-bottom: 20px;
        max-width: 353px;
        width: 100%;
        justify-content: space-between;

        .nav-item {
          flex: 1;
          .nav-link {
            padding: 0;
            border-radius: 4px;
            padding: 8px 20px;
            color: var(--Secondary-Text-Color, #77868b);
            font-family: "f37" !important;
            font-size: 14px;
            font-style: normal;
            line-height: 100%;
            border: none;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
          }
        }

        .nav-item.show .nav-link,
        .nav-link.active {
          border-radius: 4px;
          background: var(--input-bg1);
          color: var(--btn-green-bg);
        }
      }

      .apply-box {
        backdrop-filter: blur(20px);

        .main-head {
          color: var(--heading-text);
          font-family: "f37";
          font-size: 26px;
          font-style: normal;
          line-height: 120%;
          text-transform: capitalize;
          margin-bottom: 25px;

          span {
            color: var(--heading-text);
            font-family: "bl";
            font-size: 26px;
            font-style: normal;
            line-height: 120%;
            text-transform: capitalize;
          }
        }

        .apply-btn {
          width: 100%;

          label {
            border-radius: 6px;
            border: 1px dashed var(--dashed-border);
            background: var(--input-bg);
            padding: 20px 16px;
            color: var(--heading-text);
            font-family: "hregular";
            font-size: 14px;
            font-style: normal;
            line-height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 10px;
            width: 100%;
            cursor: pointer;
          }
        }

        .submit-btn {
          padding: 18px 25px;
          display: block;
          width: 100%;
          border: none;
        }
      }
    }
  }

  .bottom-content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 18px;
    width: 100%;

    .option-field {
      width: 100%;

      input {
        border-radius: 4px;
        background: var(--input-bg);
        border: none;
        height: 54px;
        padding: 20px 18px;
        color: var(--heading-text);
        font-family: "hregular";
        font-size: 14px;
        font-style: normal;
        line-height: normal;
        width: 100%;

        &::placeholder {
          color: var(--placeholder-text);
          font-family: "hregular";
          font-size: 14px;
          font-style: normal;
          line-height: normal;
        }
      }

      .dropdown {
        .dropdown-toggle {
          border-radius: 10px;
          border: 1px solid #eee;
          background: #fff;
          padding: 16px 12px;
          color: #ababab;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 100%;
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          transition: 0.3s linear;

          &::after {
            display: none;
          }

          &[aria-expanded="true"] {
            color: #000;

            svg {
              transform: rotate(180deg);
            }
          }
        }

        .dropdown-menu {
          width: 100%;
          border-radius: 10px;
          border: 1px solid #eee;
          background: #fff;
          padding: 0;
          max-height: 300px;
          overflow-y: auto;

          &::-webkit-scrollbar {
            display: none;
          }

          a {
            padding: 16px 12px;
            color: #000;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 100%;
          }
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  .apply-section .parent .left-side {
    padding: 30px 0px;
  }

  .apply-section .parent {
    flex-direction: column;
    margin-bottom: 60px;
  }

  .apply-section {
    padding: 0;
  }

  .apply-section .parent .right-side .apply-box .main-head {
    font-size: 26px;
  }

  .apply-section .parent .right-side .apply-box {
    padding: 30px 0px;
    border: none;
    border-top: 1px solid var(--border2);
    backdrop-filter: blur(20px);
  }

  .apply-section .parent .right-side {
    flex: auto;
    width: 100%;
    position: static;
  }

  .apply-section .parent .left-side .main-content h4 {
    font-size: 26px;
  }

  .apply-section .parent .left-side .main-content .para {
    font-size: 14px;
  }

  .apply-section .parent .left-side .main-content h6 {
    font-size: 18px;
  }

  .apply-section .parent .left-side .main-content ul li {
    font-size: 16px;
  }

  .wrapper-apply .main-bannernew .faqsheads {
    padding-bottom: 180px;
  }
}

.modal-header .btn-close {
  box-shadow: none !important;
}

.set-after-upload {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .inside-set {
    display: flex;
    align-items: center;
    gap: 10px;
    color: var(--heading-text);
  }
}

.apply-box .self-serve {
  h5 {
    color: var(--heading-text);
    font-size: 26px;
    font-style: normal;
    line-height: 120%;
    text-transform: capitalize;
    margin-bottom: 10px;
  }

  p {
    color: var(--V6-Dark-Theme-Secondary-Text-Color, #939fa3);
    font-size: 18px;
    font-style: normal;
    font-family: "hregular";
    line-height: 130%;
    margin-bottom: 40px;
  }

  .btn-green {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 18px 22px;
  }
}

@media (max-width: 600px) {
  .apply-section .parent .right-side {
    padding: 0 !important;
    border-left: 1px solid transparent;
  }
  .apply-section .parent .left-side {
    border-right: none !important;
  }
  .apply-section .parent .paddingless {
    padding-right: 15px !important;
  }
  .apply-section .parent .right-side .signup-nav .nav-item .nav-link {
    padding: 8px 10px;
  }
}
